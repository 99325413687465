import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InterruptScreen from '../ui-componets/interrupt-screen';
import { getCode } from '../modules/join.ducks';
import LoaderEpoints from '../ui-componets/loader';
import QS from "query-string";
import { getTokens } from '../modules/join.ducks';
import { useTranslation } from 'react-i18next';

interface IProps {
  join: any
  global: any,
  getTokens: any
}

function SeamlessLogin(props: IProps) {
  const { t } = useTranslation();
  // get sso object from localStorage
  let ssoData = props.global.SSO_USER;
  let isSsoUser = ssoData?.enabled;
  let companyShortName = ssoData?.companyShortName;
  const [fetchingCode, updateFetchingCode] = useState(false);
  const [pageLoading, updatePageLoading] = useState(true);
  useEffect(() => {
    let urlQuery = QS.parse(window.location.search);
    if (urlQuery?.code && !isSsoUser) {
        props.getTokens({ code: urlQuery?.code, redirectURL: urlQuery?.redirectURL });
    } else if(urlQuery?.status === 'loginError') {
        updatePageLoading(false);
    } else {
        window.location.href = `${window.location.origin}/account`;
    }
  }, [])

  const redirecrToShop = async () => {
    if(props?.join?.loginDetails?.onlyDashboard) {
        window.location.href = ''
    }
    
    if(isSsoUser) {
        window.location.href = `${props.global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}`;
    } else {
      updateFetchingCode(true)
        try {
            let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
      
            if (userCode && typeof userCode === 'object') {
                if ('authenticationCode' in userCode) {
                    window.location.href = `${props.global.SHOP_URL}/?code=${userCode.authenticationCode}`;
                } else {
                  updateFetchingCode(false)
                    window.location.href = '';
                }
            } else {
              updateFetchingCode(false)
                window.location.href = '';
            }
        } catch (error) {
          updateFetchingCode(false)
            window.location.href = '';
        }
    }
}

  return (
    <>
      {fetchingCode || pageLoading ? 
        <div><LoaderEpoints/></div> :
        <div className="intr-screen">
            <InterruptScreen 
            title={t("Login Failed")}
            description={t("Unfortunately, you are failed to authenticate.")}
            handleBack={redirecrToShop}/>
        </div>
      }
    </>
  );
}

const mapStateToProps = (state: any) => ({
  join: state.join,
  global: state.global
});
export default connect(mapStateToProps, {getTokens})(SeamlessLogin);

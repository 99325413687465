import React, { CSSProperties } from 'react';

interface IProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  style?: CSSProperties;
}

const VerticalLineIcon = ({
  width = '2',
  height = '14',
  fill = '#D1D0D0',
  className,
  style
}: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 2 14"
    className={className}
    style={style}
  >
    <path fill={fill} fillRule="evenodd" d="M0 0h1.5v14H0z" />
  </svg>
);

VerticalLineIcon.defaultProps = {
  width: '2',
  height: '14',
  fill: '#D1D0D0',
  className: ''
};

export default VerticalLineIcon;

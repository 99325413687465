import React, { useState } from 'react';
import LogoIconEachPerson from '../icons/each-person-small-logo';
import { LogoSmall } from '../icons';
import './styles.css';
import { store } from '../../redux/store';
import { getCode } from '../../modules/join.ducks';
import LoaderEpoints from '../loader';
import { useTranslation } from 'react-i18next';

interface accountsHeaderProps {
  relativePath?: string;
  handleLinkClick?: any;
  simpleHeaderAccounts?: boolean;
  isGbUser?: any;
  showBlock?: any;
  token?: string;
  refreshToken?:string;
  shopUser?: boolean;
  onlyDashboardUser?: boolean;
}

function AccountsHeader(props: accountsHeaderProps) {
  const { t } = useTranslation();
  const [dropDownClassName, setDropDownClassName] = useState(
    'accounts-main-drp-blocking'
  );
  const [fetchingCode, updateFetchingCode] = useState(false);
  const onClick = (e: any, tag: any) => {
    e.preventDefault();
    setDropDownClassName('accounts-main-drp-blocking');
    props.handleLinkClick(tag);
  };
  
  const redirecrToShop = async () => {
    const { global } = store.getState();
    // get sso object from localStorage
    let ssoData = global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;
    if(isSsoUser) {
      window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}`;
    } else {
      updateFetchingCode(true)
      try {
        let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
  
        if (userCode && typeof userCode === 'object') {
            if ('authenticationCode' in userCode) {
                window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}`;
            } else {
              updateFetchingCode(false)
                window.location.href = '';
            }
        } else {
          updateFetchingCode(false)
            window.location.href = '';
        }
      } catch (error) {
        updateFetchingCode(false)
          window.location.href = '';
      }
    }
  };

  let _isIOS =  /(iPhone|iPod|iPad)/i.test(navigator.platform);
  
  return (
    <>
      {fetchingCode ?
        <div><LoaderEpoints/></div> :
        <div className="accountsHeader-binder">
          {!props.simpleHeaderAccounts ? (
            <div className="accounts-header-sub-binder">
              <div className="accounts-logos">
                {/* if the user is onlyDashboard user, then hide epoints logo */}
                {
                  props.onlyDashboardUser || <div className="accounts-epoints-logo">
                    <a
                      className="cursor-pointer"
                      onClick={e => onClick(e, 'epoints')}
                    >
                      <LogoSmall />
                    </a>
                  </div>
                }
                {
                  props.shopUser || <div className="accounts-eachperson-logo">
                      <a
                          className="cursor-pointer"
                          onClick={e => onClick(e, 'each-person')}
                      >
                        <LogoIconEachPerson />
                      </a>
                    </div>
                }
              </div>
              {
                  props.shopUser || <a>
                    <div
                        className="accounts-drop-down cursor-pointer"
                        onMouseOver={() => setDropDownClassName('accounts-main-drp')}
                    >
                      <div className="accounts-admin-icon">
                        <img src="https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/accounts-ui/admins.svg" />
                      </div>
                      <div className="accounts-admin-icon-drop">
                        <img src="/static/triangle-acc.svg" />
                      </div>

                      {/* drop down implemeteation */}
                      <div className={dropDownClassName}>
                        <div className="accounts-sub-drp">
                          <div className="ac-drp-ul-binder">
                            <div className="ac-drp-tags">
                              {
                                  props.shopUser || <p className="m-0 font-styleRegular">
                                    <a onClick={e => onClick(e, 'your-account')}>
                                      {t('Your Account')}
                                    </a>
                                  </p>
                              }
                              <p className="m-0 font-styleRegular">
                                <a onClick={e => onClick(e, 'your-details')}>
                                  {t('Your Details')}
                                </a>
                              </p>
                              <p className="m-0 font-styleRegular">
                                <a onClick={e => onClick(e, 'your-activity')}>
                                  {t('Your Activity')}
                                </a>
                              </p>
                              <p className="m-0 font-styleRegular">
                                <a onClick={e => onClick(e, 'your-orders')}>
                                  {t('Your Orders')}
                                </a>
                              </p>
                              {props.isGbUser ? (
                                  <p className="m-0 font-styleRegular">
                                    <a onClick={e => onClick(e, 'your-address')}>
                                      {t('Your Addresses')}
                                    </a>
                                  </p>
                              ) : null}
                              <p className="m-0 font-styleRegular">
                                <a onClick={e => onClick(e, 'marketing-preferences')}>
                                  {t('Marketing Preferences')}
                                </a>
                              </p>
                              {
                                  props.shopUser || <p className="m-0 font-styleRegular">
                                    <a onClick={e => onClick(e, 'membership-details')}>
                                      {t('Membership Details')}
                                    </a>
                                  </p>
                              }
                              <p className="m-0 font-styleRegular">
                                <a onClick={e => onClick(e, 'payment-details')}>
                                  {t('Payment Details')}
                                </a>
                              </p>
                              {props.showBlock && !_isIOS ? (
                                  props.shopUser || <p className="m-0 font-styleRegular">
                                    <a onClick={e => onClick(e, 'donate')}>{t('Donate')}</a>
                                  </p>
                              ) : null}
                              {
                                  props.shopUser || <p className="m-0 font-styleRegular">
                                    <a onClick={e => onClick(e, 'work-profile')}>
                                      {t('Work Profile')}
                                    </a>
                                  </p>
                              }
                              <p className="m-0 font-styleRegular">
                                <a onClick={e => onClick(e, 'logout')}>{t('Logout')}</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
              }

            </div>
          ) : (
            <div className="simple-ac-header">
              <div className="simple-ac-head-subinder">
                <div className="simple-ac-head-subinder-main">
                  <a onClick={redirecrToShop} className="cursor-pointer">
                    <img src="/static/eponits-logos-simple.svg" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
}

// Set default props
AccountsHeader.defaultProps = {
  relativePath: ''
};

export default AccountsHeader;
import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 26
   */
  width?: string;
  /**
   * Svg's height
   * @default 21
   */
  height?: string;
  /**
   * Svg's fill
   * @default '#4A4A4A'
   */
  fill: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
  /**
   * Svg's style
   * @default ''
   */
  style?: CSSProperties;
}

const TwitterIcon = ({ width, height, fill, className, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    style={style}
  >
    <defs>
      <path id="prefix__a" d="M0 0L28 0 28 23 0 23z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-1)">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        fill={fill}
        d="M23.425 5.735c-.535.705-.801 1.605-.755 2.534.2 3.864-.862 6.858-3.159 8.904-2.683 2.389-7.01 3.326-11.872 2.572-.88-.136-1.79-.442-2.543-.748 1.526-.518 2.704-.982 4.607-1.87l2.657-1.244-2.933-.186c-1.406-.09-2.575-.762-3.295-1.854.383-.023.75-.084 1.117-.185l2.798-.77-2.822-.683c-1.37-.331-2.15-1.143-2.565-1.765-.272-.409-.45-.827-.557-1.213.284.075.613.129 1.145.18l2.611.256L5.79 8.068C4.3 6.918 3.702 5.192 4.14 3.532c4.652 4.77 10.114 4.411 10.664 4.537-.122-1.162-.125-1.164-.157-1.275-.704-2.46.84-3.71 1.535-4.133 1.453-.883 3.76-1.016 5.359.44.345.314.812.437 1.249.33.392-.097.714-.199 1.029-.31l-.656 1.852H24l-.575.762M27 2.805c-1.636.074-1.6.068-1.786.083l.97-2.73s-3.024 1.099-3.79 1.295C20.38-.333 17.389-.412 15.249.886c-1.752 1.063-2.692 2.887-2.378 5.02-3.409-.467-6.282-2.061-8.55-4.75l-.717-.85-.535.972c-.678 1.226-.903 2.63-.634 3.951.11.542.298 1.059.557 1.543l-.615-.236-.074 1.015c-.073 1.03.274 2.233.931 3.218.185.277.423.58.725.882l-.318-.049.388 1.163c.51 1.528 1.57 2.71 2.943 3.366-1.372.574-2.481.942-4.304 1.533L1 18.205l1.54.832c.588.317 2.664 1.376 4.714 1.693 4.558.706 9.69.13 13.146-2.94 2.91-2.586 3.865-6.265 3.666-10.094-.03-.58.131-1.134.453-1.558.646-.85 2.477-3.327 2.481-3.334"
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

TwitterIcon.defaultProps = {
  width: '26',
  height: '21',
  fill: '#4A4A4A',
  className: ''
};

export default TwitterIcon;

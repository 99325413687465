import React from 'react';
import Button from '../button';
import './styles.css';
import Moment from 'react-moment';

interface Iprops {
  relativePath?: string;
  printableData?: any;
}

function AccountsEcardsPrint(props: Iprops) {
  const { printableData } = props;

  return (
    <div className="account-ecards-print">
      <div className="account--print-ecards-sub-binder">
        <div className="flexable-image-for-print account-ecards-print-desktop">
          <div className="image-for-print">
            <img src={printableData.templateUrl} />
            <div className="ecard-ReasonText">
              <div className="ecardText">
                <p>{printableData.reasonId}</p>
              </div>
            </div>
          </div>
          <div className="printable-text-box">
            <div className="printable-text">
              <p className="m-0 font-styleregular text-blackPrimary">
                {printableData.message}
              </p>
            </div>
          </div>
        </div>

        {/* Mobile-different-cards */}
        <div className="print-mb account-ecards-print-mobile">
          <div className="mobile-ecr">
            <div className="dynamicv-mobileicon-ecr">
              <div className="time-ecr-mobile">
                <div className="flexing-points-ecr-mb">
                  <div className="time-ecr-parent">
                    <div className="ecr-img-span">
                      <p className="font-styleRegular m-0 text-black-acount">
                        <Moment fromNow>{printableData.createdAt}</Moment>
                      </p>
                    </div>
                    <div className="ecr-img-message">
                      <p className="font-styleBold m-0 color-greening rsn-id">
                        {printableData.reasonId}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ecr-img-message ecr-mg">
                  <p className="font-styleRegular m-0 ecr-p text-black-acount">
                    From{' '}
                    <span className="font-styleBold ecr-p text-black-acount">
                      {printableData?.from?.email}
                    </span>
                  </p>
                </div>
                <div className="ac-ecr-img-mobile">
                  <img src={printableData.templateUrl} />
                </div>
                <div className="footer-print-mb">
                  <p className="m-0 font-styleregular text-blackPrimary">
                    {printableData.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Set default props
AccountsEcardsPrint.defaultProps = {
  relativePath: ''
};

export default AccountsEcardsPrint;

import {CSSTransition, TransitionGroup} from "react-transition-group";
import React, {Suspense, useState} from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./components/Home";
import Account from "./components/Account"
import Activity from "./components/Activity";
import Membership from "./components/Membership";
import Details from './components/Details';
import Orders from "./components/Orders";
import Payment from "./components/Payments";
import Preferences from  "./components/Preferences";
import Addreses from "./components/Addreses";
import Profile from "./components/Profile";
import EcardsPrint from "./components/Ecards/Ecards-print"
import RedeemableRewards from "./components/Ecards/Redeemable-Rewards"
import Gdpr from "./components/Gdpr"
import Interrupt from './components/Interrupt-screen';
import Join from "./components/Join";
import SaveFromSalary from "./components/SaveFromSalary"
import BackSoon from "./components/Will-be-back";
import ChangeEmail from "./components/changeEmail";
import SsoLogin from "./components/sso";
import Loader from "./ui-componets/loader";
import { store } from "./redux/store";
import { getCode } from "./modules/join.ducks";
import SeamlessLogin from "./components/Seamless-login";
import LogoutHandler from "./components/LogoutHandler";

// const Join = React.lazy(() => import("./components/Join"));




const Routes: React.FC = (props: any) => {
    const {location, breadcrumbs, carousel, global} = props;
    const breadcrumbSections = breadcrumbs ? breadcrumbs.sections.filter((b: any) => b) : []
    const onURLChange = (url: string) => window.location.href = url
    const [fetchingCode, updateFetchingCode] = useState(false);

    const toShop = async () => {
        updateFetchingCode(true)
        try {
            let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
    
            let {global} = store.getState();
    
            if (userCode && typeof userCode === 'object') {
                if ('authenticationCode' in userCode) {
                    if (props?.dieselCardUser){
                        window.location.href = `${global.SHOP_URL}/?code=${userCode?.authenticationCode}&redirect_to=${global.SHOP_URL}/diesel-card/`;
                    
                    } else {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode?.authenticationCode}`;
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } else {
                updateFetchingCode(false)
                window.location.href = '';
            }
        } catch (error) {
            updateFetchingCode(false)
            window.location.href = '';
        } 
        
        return (<Loader/>)
    }
    return (
        <>
            <div className="all-container">
            {/* <TransitionGroup> */}
                {/* <CSSTransition
                    key={location.key}
                    timeout={3000}
                    classNames="item"
                > */}
                    <Suspense fallback={''}>
                        <Switch>
                        <Route
                                exact={true}
                                key={'/'}
                                path={'/'}
                                render={() => <Home {...props} />}
                            />
                             <Route
                                exact={true}
                                key={'account'}
                                path={'/account'}
                                render={() => props.shopUser ? toShop() : <Account {...props} />}
                            />
                            <Route
                                exact={true}
                                key={'activity'}
                                path={'/activity'}
                                render={() => <Activity {...props} />}
                            />
                            <Route
                                exact={true}
                                key={'membership'}
                                path={'/membership'}
                                render={() => props.shopUser ? toShop() : <Membership {...props} />}
                            />
                            <Route
                                exact={true}
                                key={'preferences'}
                                path={'/preferences'}
                                render={() => <Preferences {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'details'}
                                path={'/details'}
                                render={() => <Details {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'orders'}
                                path={'/orders'}
                                render={() => <Orders {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'payments'}
                                path={'/payments'}
                                render={() => <Payment {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'addresses'}
                                path={'/addresses'}
                                render={() => <Addreses {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'noaccess'}
                                path={'/NoAccess'}
                                render={() => <Interrupt {...props}/>}
                            />                      
                            <Route
                                exact={true}
                                key={'profile'}
                                path={'/profile'}
                                render={() => props.shopUser ? toShop() : <Profile {...props}/>}
                            />
                           <Route
                                key={'join'}
                                path={'/join'}
                                render={(props) => <Join {...props}/>}
                            />
                            {/* removed the SFS route as this is migrated to each person app */}
                            {/* <Route
                                key={'saveFromSalary'}
                                path={'/save-from-salary'}
                                render={(props) => <SaveFromSalary {...props}/>}
                            /> */}
                            <Route
                                exact={true}
                                key={'holdingPage'}
                                path={'/holdingpage'}
                                render={() => <BackSoon {...props}/>}
                            />

                            {/* start - used route when GDPR not accepted */}
                            <Route
                                exact={true}
                                key={'Gdpr'}
                                path={'/Gdpr'}
                                render={() => <Gdpr {...props}/>}
                            />
                            {/* end - used route when GDPR not accepted */}

                             <Route
                                exact={true}
                                key={'ecardsPrint'}
                                path={'/my-account/ecards/ecard'}
                                render={() => <EcardsPrint {...props}/>}
                            />
                             <Route
                                exact={true}
                                key={'redeemableRewards'}
                                path={'/my-account/ecards/getreward'}
                                render={() => <RedeemableRewards {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'changeEmail'}
                                path={'/change-email-request'}
                                render={() => <ChangeEmail {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'sso'}
                                path={'/sso'}
                                render={() => <SsoLogin {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'seamlessLogin'}
                                path={'/seamless-login'}
                                render={() => <SeamlessLogin {...props}/>}
                            />
                            <Route
                                exact={true}
                                key={'logout'}
                                path={'/logoutHandler'}
                                render={() => <LogoutHandler {...props}/>}
                            />
                        </Switch>
                    </Suspense>
                {/* </CSSTransition> */}
            {/* </TransitionGroup> */}
            </div>
        </>
    )
}

export default Routes

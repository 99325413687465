import React, { useEffect, useState, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import Button from '../ui-componets/button';
import { Radio } from 'semantic-ui-react';
import {updateJoinStep, gdprBusinessUpdate, getCode  } from '../modules/join.ducks'
import Loader from '../ui-componets/loader';
import { ToastContainer } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';


interface IProps {
  updateJoinStep: any
  gdprBusinessUpdate: any
  ui: any
  gdprStatus:any
  history:any
  loginDetails:any
  global: any
}

function Gdpr(props: IProps) {
  const { t } = useTranslation();
  const [isLoader, setIsLoader] = useState(true);
  const initalState = {
    tncAccepted: false,
    marketingPrefEmail: false,
    marketingPrefSMS: false,
    privacyAccepted: true,
    userInteractedForEmailSubscription: false
  }
  const [toggleData, setToggleData] = useState(initalState);
  const { gdprBusinessUpdate, ui, gdprStatus, loginDetails, global } = props;

  const onToggleChange = (_0: SyntheticEvent, data: any) => {
    /**
     * Ticket: https://iat-technology.atlassian.net/browse/WS-709
     * here this page is seen only when the user logs in for the first time and has gdprAccepted=false.
     * So while joining GDPR, userInteractedForEmailSubscription is used in the Eachperson in order to show the gdpr sticky view
     * to determine if the user has subscribed for email or not.
     * 
     * it is set to true if the user has set marketingPrefEmail to true
     * it is left as false if the user has not set marketingPrefEmail to true
     * so that the user can be prompted in the each person pages for setting yes or no
     */
    if(data.name === 'marketingPrefEmail' && data.checked) {
      toggleData.userInteractedForEmailSubscription = true
    } else {
      toggleData.userInteractedForEmailSubscription = false
    }
    setToggleData({ ...toggleData, [data.name]: data.checked });
  };

  const handleSave = () => {
    setIsLoader(true);
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    gdprBusinessUpdate(toggleData);
  }

  // get sso object from localStorage
  let ssoData = props.global.SSO_USER;
  let isSsoUser = ssoData?.enabled;
  let companyShortName = ssoData?.companyShortName;

  const redirectingDomainSpecificAccountTerms = async () => {
    setTimeout(() => {
      setIsLoader(false);
    }, 30000);
    if(isSsoUser) {
      window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}&intended_url=${global.SHOP_URL}/terms-conditions/`;
    } else {
        setIsLoader(true)
        try {
            let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
      
            if (userCode && typeof userCode === 'object') {
                if ('authenticationCode' in userCode) {
                    window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}&redirect_to=${global.SHOP_URL}/terms-conditions/`;
                } else {
                    setIsLoader(false)
                    window.location.href = '';
                }
            } else {
                setIsLoader(false)
                window.location.href = '';
            }
        } catch (error) {
            setIsLoader(false);
            window.location.href = '';
        }
    }
  }

  const redirectingDomainSpecificAccountPrivacy = async () => {
    setTimeout(() => {
      setIsLoader(false);
    }, 30000);

    if(isSsoUser) {
      window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}&intended_url=${global.SHOP_URL}/privacy-policy/`;
    } else {
      setIsLoader(true)
      try {
          let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
    
          if (userCode && typeof userCode === 'object') {
              if ('authenticationCode' in userCode) {
                  window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}&redirect_to=${global.SHOP_URL}/privacy-policy/`;
              } else {
                  setIsLoader(false)
                  window.location.href = '';
              }
          } else {
              setIsLoader(false)
              window.location.href = '';
          }
      } catch (error) {
          setIsLoader(false);
          window.location.href = '';
      }
    }
  }

  const redirectToAccount = () => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    window.location.href = `${window.location.origin}/account?protected=true`;
  }

    useEffect(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setIsLoader(false);
      }, 2000);
  }, []);

  useEffect(() => {
    setIsLoader(true);
    if(loginDetails && loginDetails.isGDPRAccepted){
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
      redirectToAccount();
    }
  
}, [loginDetails]);


  return (
    <div>
    { isLoader?
  <div>
      <Loader/>
  </div>:
    <div className="busines-gdpr join-gdpr ">
      <ToastContainer />
      <div className="join-details-sub-binder">
        {/* box */}
        <div className="join-box">
          <div className="join-sub-box">
            <div className="join-box-header">
              <div className="join-box-sub-head">
                <p className="text-black-primaryone font-styleBold size-big-join text-center">{t('Set Permissions')}</p>
              </div>
            </div>
            <div className="join-box-body">
              <div className="join-box-body-sub">
                <div className="terms-conditions-max">
                  <div className="trs-rows">
                    <p className="font-styleBold text-black-primaryone size-small-join pb-change">{t('Terms & Conditions')}</p>
                    <div className="text-gdpr">
                      <div className="text-of-condition">
                        <p className="font-styleRegular text-black-primaryone size-small-join star">*</p>
                        <p className="font-styleRegular text-black-primaryone size-small-join">
                          <Trans i18nKey="gdprTnC">
                            I confirm that I have read and agree to the
                            <a className="cursor-pointer" onClick={redirectingDomainSpecificAccountTerms}><span className="color-green-primary font-styleBold"> Terms & Conditions</span></a>
                          </Trans>
                        </p>
                      </div>
                      <div className="terms-gdpr">
                        <Radio
                          toggle
                          name="tncAccepted"
                          checked={toggleData.tncAccepted}
                          onChange={(e, data) => onToggleChange(e, data)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="trs-rows">
                    <p className="font-styleBold text-black-primaryone size-small-join pb-change">{t('Communication preferences')}</p>
                    <div className="text-gdpr">
                      <div className="text-of-condition tof">
                        <p className="font-styleRegular text-black-primaryone size-small-join">{t(`We'd like to keep in touch & occasionally send you information about special offers and new ways to earn Epoints and rewards.`)}
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="terms-conditions-greay-box">
                  <div className="terms-conditions-greay-box-max">
                    <div className="trs-rows">
                      <div className="text-gdpr">
                        <div className="text-of-condition">
                          <p className="font-styleRegular text-black-primaryone size-small-join">{t('I agree to receive promotional messages from Epoints by email.')}
                        </p>
                        </div>
                        <div className="terms-gdpr">
                          <Radio
                            toggle
                            name="marketingPrefEmail"
                            checked={toggleData.marketingPrefEmail}
                            onChange={(e, data) => onToggleChange(e, data)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="trs-rows">
                      <div className="text-gdpr">
                        <div className="text-of-condition">
                          <p className="font-styleRegular text-black-primaryone size-small-join">{t('I agree to receive promotional messages from Epoints by SMS.')}
                        </p>
                        </div>
                        <div className="terms-gdpr">
                          <Radio
                            toggle
                            name="marketingPrefSMS"
                            checked={toggleData.marketingPrefSMS}
                            onChange={(e, data) => onToggleChange(e, data)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="terms-conditions-max">
                  <div className="gdpr-btn-submit">
                    <p className="font-fourteens font-styleRegular text-black-primaryone">
                      <Trans i18nKey="gdprPrivacy">
                        Please read our <a className="cursor-pointer" onClick={redirectingDomainSpecificAccountPrivacy}><span className="color-green-primary font-styleBold">Privacy Policy</span></a>
                      </Trans> 
                    </p>
                    <div className="gdpr-sbmt-btn">
                      <div className="join-btn text-center">
                     { gdprStatus === false && <div className="err-case">
                       <div className="addres-errors-msgs">
                        <div className="adres-err">
                          <p>{t('Something went wrong.Please try again')}</p>
                        </div>
                      </div>
                        </div>}
                        <Button
                          width={247}
                          
                          label={t("Save")}
                          onClick={handleSave}
                          type="primary"
                          disabled={!toggleData.tncAccepted}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
}
</div>
  );
}

const mapStateToProps = (state: any) => ({
  global: state.global,
  ui: state.ui,
  gdprStatus: state.join.gdprBusinessSuccess,
  loginDetails : state.join.loginDetails
});
export default connect(mapStateToProps, {updateJoinStep, gdprBusinessUpdate})(Gdpr);

import React, { useState, useEffect }  from 'react';
import {connect} from 'react-redux';
import AccountsMembership from '../ui-componets/accounts-membership';
import { Modal } from 'semantic-ui-react';
import { FiX } from 'react-icons/fi';
import BreadCrumbs from "../ui-componets/breadcrumbs";
import { getBillingData, fetchInvoice, getProfileData} from '../modules/details.ducks';
import { Logo} from '../ui-componets/icons';
import { getTokens } from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import { useTranslation } from 'react-i18next';

interface IProps  {
    getTokens: any
    getBillingData: any
    billingDetails : any
    fetchInvoice:any
    history: any
    invoiceData: any
    getProfileData : any
    subscriptionDetails: any
    tokenDetails: any
    global : any
    isProfileFetching: any
    shopUser?: boolean
}
    
function Membership(props:IProps) {
  const { t } = useTranslation();

  // get sso object from localStorage
  let ssoData = props.global.SSO_USER;
  let isSsoUser = ssoData?.enabled;
  let companyShortName = ssoData?.companyShortName;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Query params in Membership", QS.parse(window.location.search));
    // setTimeout(() => {
    if (QS.parse(window.location.search).code && !isSsoUser) {
      // since fetching token in this component can be overridden by other functions and give unexpected result
      props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/membership`);
    } else if (QS.parse(window.location.search).code && isSsoUser) {
      console.log("QS.parse(window.location.search).code && isSsoUser");
      props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/membership`, companyShortName: companyShortName });
    } else if (!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
      window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/membership`
    } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
      console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
      window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/membership?companyShortName=${companyShortName}`
    }
  // }, 2000);
  }, []);

    const [isModalActive, setIsModalActive] = useState(false);
    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Membership', className: 'child-breadcrumbs font-styleLight', content: t('Membership Details'), link: false },
    ]
    const { getBillingData,billingDetails,fetchInvoice, invoiceData, getProfileData, subscriptionDetails, isProfileFetching } = props;

    const handleGetInvoice = (id:any) => {
        console.log("modal openen");
        fetchInvoice(id);
        setIsModalActive(true);
    }

    useEffect(() => {
      getProfileData();
        getBillingData();
    }, [props.tokenDetails]);

      const onCloseModal = () => {
        setIsModalActive(false);
      };


    return (
      <div>
        { isProfileFetching || isProfileFetching === undefined ?
      <div>
          <Loader/>
      </div>:
        <div className="members-ship-paremt">
            <div className="set-pref membership-child">
             <div className="page-heads-title-for-all-accounts">
                    <div className="page-heads-title-for-all-accounts-img">
                        <img src="/static/membership-icon.svg" />
                    </div>
                    <div className="page-heads-title-for-all-accounts-content">
                        <p className="m-0 font-styleBold">{t('Membership Details')}</p>
                    </div>
                    {sections.length > 0 && !props.shopUser ? <BreadCrumbs
                        sections={sections}
                        handleClick={(url: string) => props.history.push(url)}
                        backAction={() => props.history.goBack()} />
                        : null}
                </div>
                {/* modal implentation */}
                <div className="type-of-membership-binder-ac">
               
                <div>
                {subscriptionDetails && subscriptionDetails.membershipType !== "MEMBERSHIP - Free Employee Account" && subscriptionDetails.membershipType === "No Tier" &&
                    <div className="child-of-type">
                        <div className="child-of-type-flex">
                            <p className="text-center font-styelRegular"> {t('You have no membership tier, contact support if you want to upgrade')}</p>
                        </div>
                    </div>
                  }


                  {subscriptionDetails && subscriptionDetails.membershipType !== "MEMBERSHIP - Free Employee Account" && subscriptionDetails.membershipType !== "No Tier" &&
                    <div className="child-of-type">
                        <div className="child-of-type-flex">
                            <p className="text-center font-styelRegular">{subscriptionDetails.membershipType}</p>
                        </div>
                        <div className="child-of-type-flex">
                            <p>{t('Renews')} {subscriptionDetails.expireAt}</p>
                        </div>
                    </div>
                  }

                    {subscriptionDetails && subscriptionDetails.membershipType === "MEMBERSHIP - Free Employee Account" &&
                        <div className="child-of-type">
                          <div className="child-of-type-flex">
                                <p className="text-center font-styelRegular">{t('Your membership is currently being paid for by your employer')}</p>
                            </div> 
                        </div>
                      }
                 </div>
              </div>
{console.log("billingDetails", billingDetails.length)}
         {  billingDetails && billingDetails.length !== 0 && subscriptionDetails && subscriptionDetails.membershipType !== "MEMBERSHIP - Free Employee Account" && 
         <div className="AccountsMembership-layouts">
                <AccountsMembership 
                getInvoice={(id:any)=>{ handleGetInvoice(id)}}
                billingData={billingDetails}
                />
              
              {isModalActive && (
              <div className="modal-for-invs">
                <Modal
                  open={isModalActive}
                  closeOnDimmerClick={true}
                  closeOnDocumentClick={true}
                  className="modal-accounts-invoice"
                >
                  <div className="accounts-profile-all-content-binder">
                    <div className="accounts-profile-all-content-sub-binder">
                      <div className="first-name-modal">
                        <div className="modal-head-con">
                          <div className="model-text">
                            <div className="m-0 font-styleBold text-center model-logos">
                              <Logo width="98px" height="22px" />
                            </div>
                          </div>
                          <div className="modal-cross">
                            <FiX
                              onClick={onCloseModal}
                              data-testid="close-modal"
                              className="crossing-icon-modal"
                            />
                          </div>
                        </div>
                        <div className="hr-border"></div>

                        <div className="invoice">
                          <div className="invoice-sub-binder">
                            <div className="invoice-address">
                              <p className="m-0 font-styleBold font-twenty color-accounts-black-bold">
                                {t('Address')}
                              </p>
                              <div className="invoic-address">
                                <p className="m-0 font-styleRegular color-accounts-black">
                                  8A Denmark Street, Wokingham, Berkshire RG40
                                  2BB, United Kingdom{' '}
                                </p>
                              </div>
                              <p className="m-0 font-styleBold font-twenty color-accounts-black-bold custom-inv">
                                {t('Customer')}
                              </p>
                              <div className="invoic-address">
                                <p className="m-0 font-styleRegular color-accounts-black">
                                  {invoiceData.fullName}
                                </p>
                                <p className="m-0 font-styleRegular color-accounts-black">
                                  {invoiceData.email}
                                </p>
                              </div>
                            </div>

                            <div className="invoice-flexing">
                              <div className="invopice-flex-sub-binder">
                                <div className="invoic-head-text">
                                  <div className="inv-flex-items-left">
                                    <p className="m-0 font-styleBold font-twenty color-accounts-black-bold">
                                      {t('Invoice')}
                                    </p>
                                  </div>
                                  <div className="inv-flex-items-right">
                                    <p className="m-0 font-styleRegular font-twenty color-accounts-black">
                                      {invoiceData.invoiceNumber}
                                    </p>
                                  </div>
                                </div>
                                <div className="hr-border"></div>
                                <div className="invoice-body-text">
                                  <div className="inv-flex-items-left">
                                    <p className="m-0 font-styleRegular font-twenty color-accounts-black">
                                      {t('Date')}
                                    </p>
                                  </div>
                                  <div className="inv-flex-items-right">
                                    <p className="m-0 font-styleBold font-twenty color-accounts-black">
                                      {invoiceData.date}
                                    </p>
                                  </div>
                                </div>

                                <div className="invoice-body-text">
                                  <div className="inv-flex-items-left">
                                    <p className="m-0 font-styleRegular font-twenty color-accounts-black">
                                      {t('Description')}
                                    </p>
                                  </div>
                                  <div className="inv-flex-items-right">
                                    <p className="m-0 font-styleBold font-twenty color-accounts-black">
                                      {invoiceData.description}
                                    </p>
                                  </div>
                                </div>

                                <div className="invoice-body-text">
                                  <div className="inv-flex-items-left">
                                    <p className="m-0 font-styleRegular font-twenty color-accounts-black">
                                      {t('Service Period')}
                                    </p>
                                  </div>
                                  <div className="inv-flex-items-right">
                                    <p className="m-0 font-styleBold font-twenty color-accounts-black">
                                      {invoiceData.servicePeriod}
                                    </p>
                                  </div>
                                </div>

                                <div className="invoice-body-text">
                                  <div className="inv-flex-items-left">
                                    <p className="m-0 font-styleRegular font-twenty color-accounts-black">
                                      {t('Annual membership fee')}
                                    </p>
                                  </div>
                                  <div className="inv-flex-items-right">
                                    <p className="m-0 font-styleBold font-twenty color-accounts-black">
                                      {invoiceData.amount}
                                    </p>
                                  </div>
                                </div>

                                <div className="hr-border"></div>
                                <div className="invoice-body-text">
                                  <div className="inv-flex-items-left">
                                    <p className="m-0 font-styleBold font-twenty color-accounts-black-two-six">
                                      {t('Total Paid')}
                                    </p>
                                  </div>
                                  <div className="inv-flex-items-right">
                                    <p className="m-0 font-styleBold font-twenty color-accounts-black-two-six">
                                      {invoiceData.totalPaid}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            )}
            </div>}
        

            </div>
        </div>
}
</div>
    );
}
const mapStateToProps = (state: any) => ({
    billingDetails : state.details.billingData,
    invoiceData : state.details.invoiceData,
    subscriptionDetails: state.details.profileData.userSubscriptionDetails,
    tokenDetails: state.join.tokens,
    global: state.global,
    isProfileFetching: state.details.isProfileFetching
});

export default connect(mapStateToProps, {getBillingData, getProfileData, fetchInvoice, getTokens})(Membership);

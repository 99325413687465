import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getWorkProfileData } from '../modules/details.ducks';
import BreadCrumbs from "../ui-componets/breadcrumbs";
import { getTokens } from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import Moment from 'react-moment';
import Back from "../ui-componets/Back";
import { useTranslation } from 'react-i18next';



interface IProps {
    getTokens: any
    history: any
    tokenDetails: any
    global: any
    getWorkProfileData: any
    loginDetails: any
    wrokProfile: any
    isWorkProfileFetching: any
    shopUser?: boolean
    dieselCardUser?: boolean
}
function Profile(props: IProps) {
    const { t } = useTranslation();

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Query params in Profile page", QS.parse(window.location.search));
        // setTimeout(() => {
        if (QS.parse(window.location.search).code && !isSsoUser) {
            // since fetching token in this component can be overridden by other functions and give unexpected result
            props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/profile`);
        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/profile`, companyShortName: companyShortName });
        } else if (!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/profile`
        } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/profile?companyShortName=${companyShortName}`
        }
    // }, 2000);
    }, []);

    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Work', className: 'child-breadcrumbs font-styleLight', content: t('Work Profile'), link: false },
    ]
    const { getWorkProfileData, loginDetails, wrokProfile, isWorkProfileFetching } = props;

    useEffect(() => {
        if(loginDetails && loginDetails.isBusinessUser === true){
            getWorkProfileData();
        }
    }, [props.tokenDetails]);


    return (
        <div>
            {isWorkProfileFetching || isWorkProfileFetching === undefined ?
                <div>
                    <Loader />
                </div> :
                <div className="work-profile-page">
                    {
                        props.shopUser && <div className="dropdown-for-activity dropdown-for-order pt-0 pl-5">
                            <Back
                            refreshToken={props.tokenDetails.refresh_token}
                            dieselCardUser={props.dieselCardUser}/>
                        </div>
                    }
                    <div className="wpf">
                        <div className="sdp-binder">
                            <div className="sdp-head">
                                <div className="sdp-sub-head">
                                    <img src="/static/work-profile.svg" />
                                    <p className="m-0 font-styleBold black-for-accounts">{t('Work Profile')}</p>
                                    <div className="breads">
                                        {sections.length > 0 && !props.shopUser ? <BreadCrumbs
                                            sections={sections}
                                            handleClick={(url: string) => props.history.push(url)}
                                            backAction={() => props.history.goBack()} />
                                            : null}
                                    </div>
                                </div>
                            </div>
                            {/* dynamic data binder */}
                            {loginDetails && loginDetails.isBusinessUser == false ?
                                <div className="wpf-static-img-binder">
                                    <div className="wpf-static-img-sub-binder">
                                        <div className="flex-for-static-wpf">
                                            <div className="left-content-wpf-static">
                                                <div className="wpf-stc-left-con">
                                                    <div className="bold-wpf-head">
                                                        <p className="m-0 font-styleBold black-for-ac fonting-thrity">
                                                            {t('Looking to send an ecard?')}
                                                        </p>
                                                    </div>
                                                    <div className="regulr-wpf-head">
                                                        <p className="m-0 font-styleLight black-for-ac fonting-forten">
                                                            {t('Members registered by their employer to use Epoints in the workplace can access and send ecards from this area. If you are one of these members please ensure you are signed in.')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="wpf-stc-left-con-bottom">
                                                    <div className="bold-wpf-head">
                                                        <p className="m-0 font-styleBold black-for-ac fonting-thrity">
                                                            {t('How can Epoints be used in the workplace?')}
                                                        </p>
                                                    </div>
                                                    <div className="regulr-wpf-head">
                                                        <p className="m-0 font-styleLight black-for-ac fonting-forten">
                                                            {t('With the Each Person recognition and reward platform, we help businesses connect with their employees by enabling top-down and peer-to-peer recognition. And the best part of all is that it is free to use!')}
                                                        </p>
                                                    </div>
                                                    <div className="wpf-linked-text">
                                                        <a className="cursor-pointer" href="https://www.eachperson.com/" target={window.navigator.userAgent.indexOf('gonative') > -1 ? "_self" : "_blank"}><p className="m-0 font-styleRegular color-greeny">{t('Learn more about Each Person here')}</p></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-content-wpf-static">
                                                <div className="rgt-wpf-static">
                                                    <img src="/static/wpf-man.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className="dynamic-data-binder">
                                    <div className="company-logo-url-head-wpf">
                                        <div className="company-logo-url-head-wpf-sub-binder">
                                            <img src={wrokProfile.companyLogoUrl} />
                                        </div>
                                    </div>
                                    <div className="wpf-dynamica-values">
                                        <div className="wpf-dynamica-values-sub">
                                            <div className="dynamic-wpf-values-binder">
                                                <div className="dyn-wpf-values">
                                                    <p className="m-0 black-for-ac font-styleBold">{t('Name Used by Company')}</p>
                                                    <p className="m-0 black-for-ac font-styleRegular">{wrokProfile.name}</p>
                                                </div>
                                                <div className="dyn-wpf-values">
                                                    <p className="m-0 black-for-ac font-styleBold">{t('Email Used by Company')}</p>
                                                    <p className="m-0 black-for-ac font-styleRegular">{wrokProfile.email}</p>
                                                </div>
                                                <div className="hr-inside"></div>
                                            </div>

                                            <div className="dynamic-wpf-values-binder">
                                                <div className="dyn-wpf-values">
                                                    <p className="m-0 black-for-ac font-styleBold">{t('Company')}</p>
                                                    <p className="m-0 black-for-ac font-styleRegular">{wrokProfile.companyName}</p>
                                                </div>
                                                <div className="dyn-wpf-values">
                                                    <p className="m-0 black-for-ac font-styleBold">{t('Department')}</p>
                                                    <p className="m-0 black-for-ac font-styleRegular">{wrokProfile.departmentName}</p>
                                                </div>
                                                <div className="dyn-wpf-values">
                                                    <p className="m-0 black-for-ac font-styleBold">{t('Manager')}</p>
                                                    <p className="m-0 black-for-ac font-styleRegular">{wrokProfile.mangerName}</p>
                                                </div>
                                                <div className="hr-inside"></div>
                                            </div>

                                            <div className="dynamic-wpf-values-binder">
                                                <div className="dyn-wpf-values">
                                                    <p className="m-0 black-for-ac font-styleBold">{t('Employee Number')}</p>
                                                    {wrokProfile.employeeNumber ?
                                                        <p className="m-0 black-for-ac font-styleRegular">{wrokProfile.employeeNumber}</p> :
                                                        <p className="m-0 black-for-ac font-styleRegular">N/A</p>
                                                    }
                                                </div>
                                                <div className="dyn-wpf-values">
                                                    <p className="m-0 black-for-ac font-styleBold">{t('Company Start Date')}</p>
                                                    {wrokProfile.companyStartDate ?
                                                        <p className="m-0 black-for-ac font-styleRegular"> <Moment format="DD/MM/YYYY">{wrokProfile.companyStartDate}</Moment></p> :
                                                        <p className="m-0 black-for-ac font-styleRegular">N/A</p>
                                                    }
                                                </div>
                                                <div className="hr-inside"></div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    tokenDetails: state.join.tokens,
    global: state.global,
    loginDetails: state.join.loginDetails,
    wrokProfile: state.details.workProfileData,
    isWorkProfileFetching: state.details.isWorkProfileFetching
});

export default connect(mapStateToProps, { getWorkProfileData, getTokens })(Profile);

import 'react-app-polyfill/ie11';
import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/object';
import 'core-js/es/set';
import 'reflect-metadata';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {persistor, store} from './redux/store';
import {BrowserRouter as Router} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import App from "./App";

import UILoader from './UILoader';
import i18n, { fetchAndSetLanguage } from './i18n';

const Root = () => {
    useEffect(() => {
        const state = store.getState();
        const token = state.join.tokens?.access_token // Adjust this path to match where your token is stored in your state
        const EP_ADMIN = state.global.EP_ADMIN_URL;
        if (token) {
          fetchAndSetLanguage(token, EP_ADMIN); // Fetch and set language using the token
        }
    }, []);

    // Define a fallback component
    const FallbackComponent = () => <div>Loading...</div>;

    return (
        <Suspense fallback={<FallbackComponent />}>
            <App />
        </Suspense>
    );
  };

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
            <Root/>
                {/* <UILoader/> */}
            </Router>
        </PersistGate>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// tslint:disable-next-line:no-console
console.log('React Accounts :: ENV VARS', `${JSON.stringify(process.env, null, 2)}`);
import React from 'react';
import LogoIconEachPerson from '../icons/each-person-small-logo';
import { LogoSmall, Logo } from '../icons';
import './styles.css';
import { Loader } from 'semantic-ui-react';

function LoaderEpoints() {
  return (
    <div className="loader">
      <div className="spin-logo">
        <LogoSmall />
      </div>
    </div>
  );
}

// Set default props
LoaderEpoints.defaultProps = {
  relativePath: ''
};

export default LoaderEpoints;



export const convertEpointsToPounds = (epoints: number): string => {
  const pounds = Math.floor((epoints / 200) * 100)/100;
  return pounds.toFixed(2)
};

/**
 * the API gives Euro to pound conversion rate
 * example EUR to GBP is 0.887885 for now.
 * 
 * so in order to get currency value in EUR we need to do 1/0.887885 to get the value 
 */
export const currencyConversion = (balance:number, exchangeRate:number): string => {
  const currencyValue = Math.floor(((balance / 200) / exchangeRate) * 100)/100 ;
  return currencyValue.toFixed(2);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

// Define the props interface
interface AccountsFooterProps {
  relativePath?: string;
  handleLinkClick?: (tag: string) => void;
}

// Define the functional component
const AccountsFooter: React.FC<AccountsFooterProps> = (props) => {
  const { t } = useTranslation();

  const onClick = (e: React.MouseEvent, tag: string) => {
    e.preventDefault();
    if (props.handleLinkClick) {
      props.handleLinkClick(tag);
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="accounts-footer-binder">
      <div className="ac-sub-footer">
        <div className="ac-bg-binder">
          <div className="ac-left-content">
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold">
                <a href="#">{t('Copyright')}</a>
              </p>
            </div>
            <div className="accouts-p-fonts circle">
              <p></p>
            </div>
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold">
                <a href="#">{currentYear}</a>
              </p>
            </div>
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold dots">.</p>
            </div>
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold">
                <a
                  href="#"
                  onClick={(e) => onClick(e, 'epoints')}
                  className="cursor-pointer text-green"
                >
                  {t('Epoints')}
                </a>
              </p>
            </div>
          </div>

          <div className="ac-right-content">
            <div className="ac-margin">
              <p className="m-0 font-styleRegular">
                <a href="#" onClick={(e) => onClick(e, 'about')}>
                  {t('About')}
                </a>
              </p>
            </div>
            <div className="ac-margin">
              <p className="m-0 font-styleRegular">
                <a href="#" onClick={(e) => onClick(e, 'contact-us')}>
                  {t('Contact Us')}
                </a>
              </p>
            </div>
            <div className="ac-margin">
              <p className="m-0 font-styleRegular">
                <a href="#" onClick={(e) => onClick(e, 'faq')}>
                  {t('FAQ')}
                </a>
              </p>
            </div>
            <div className="ac-margin">
              <p className="m-0 font-styleRegular">
                <a href="#" onClick={(e) => onClick(e, 'terms')}>
                  {t('Terms & Conditions')}
                </a>
              </p>
            </div>
            <div className="ac-margin">
              <p className="m-0 font-styleRegular">
                <a href="#" onClick={(e) => onClick(e, 'privacy')}>
                  {t('Privacy Policy')}
                </a>
              </p>
            </div>
            <div className="ac-margin">
              <p className="m-0 font-styleRegular">
                <a href="#" onClick={(e) => onClick(e, 'cookie-policy')}>
                  {t('Cookies')}
                </a>
              </p>
            </div>
          </div>

          {/* for mobile */}
          <div className="ac-left-contents">
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold">
                <a className="cursor-pointer">{t('Copyright')}</a>
              </p>
            </div>
            <div className="accouts-p-fonts circle">
              <p></p>
            </div>
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold">
                <a className="cursor-pointer">{currentYear}</a>
              </p>
            </div>
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold dots">.</p>
            </div>
            <div className="accouts-p-fonts">
              <p className="m-0 font-styleBold">
                <a
                  className="cursor-pointer text-green"
                  onClick={(e) => onClick(e, 'epoints')}
                >
                  {t('Epoints')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Set default props
AccountsFooter.defaultProps = {
  relativePath: ''
};

export default AccountsFooter;
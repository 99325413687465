import React, {useState} from "react";
import { store } from "../../redux/store";
import { getCode } from "../../modules/join.ducks";
import LoaderEpoints from "../loader";

interface backProps {
    refreshToken: string;
    dieselCardUser?: boolean;
  }

function Back(props: backProps){
    const [fetchingCode, updateFetchingCode] = useState(false);
    const shopUrlForShopOnlyUser = async (event: any) => {
        event?.preventDefault()
        updateFetchingCode(true)
        try {
            let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
    
            let {global} = store.getState();
    
            if (userCode && typeof userCode === 'object') {
                if ('authenticationCode' in userCode) {
                    if (props?.dieselCardUser){
                        window.location.href = `${global.SHOP_URL}/?code=${userCode?.authenticationCode}&redirect_to=${global.SHOP_URL}/diesel-card/`;
                    
                    } else {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode?.authenticationCode}`;
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } else {
                updateFetchingCode(false)
                window.location.href = '';
            }
        } catch (error) {
            updateFetchingCode(false)
            window.location.href = '';
        }  
    }

    return(
        <>
            {fetchingCode ?
                <div><LoaderEpoints/></div> :
                <div className="back-customise p-0">
                    <p className="m-0 font-styleBold">
                        <a href="#" onClick={shopUrlForShopOnlyUser} style={{cursor: "pointer"}}>Back</a>
                    </p>
                </div>
            }
        </>
    )
}

export default Back

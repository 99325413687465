import React, { CSSProperties } from 'react';
import Button from '../button';
import { useTranslation } from 'react-i18next';

interface RemoveAddressDiologProps {
  cancelDialog: () => void;
  onConfirmRemove: () => void;
  style?: CSSProperties;
}

export default function(props: RemoveAddressDiologProps) {
  const { t } = useTranslation();
  const { cancelDialog, onConfirmRemove, style } = props;
  return (
    <div
      automation-id="address-remove-confirm-pop-up"
      data-testid="remove-address-dialog"
      style={style}
      className="remove-address-dialog"
    >
      <div className="remove-address-dialog-text">
        {t('Are you sure you want to remove this address?')}
      </div>
      <div className="flex justify-around">
        <Button
          extraClassNames="remove-address-button remove-address-no"
          dataTestId="remove-address-no"
          label={t("No")}
          type="secondary"
          onClick={cancelDialog}
        />

        <Button
          extraClassNames="remove-address-button remove-address-yes"
          dataTestId="remove-address-yes"
          label={t("Yes")}
          onClick={onConfirmRemove}
        />
      </div>
      <div
        style={{
          transform: 'rotate(45deg)',
          bottom: '-15px',
          left: '79%'
        }}
        className="remove-address-div"
      />
    </div>
  );
}

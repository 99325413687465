import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 20
   */
  width?: string;
  /**
   * Svg's height
   * @default 24
   */
  height?: string;
  /**
   * Svg's fill
   * @default '#4A4A4A'
   */
  fill?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
  /**
   * Svg's style
   * @default ''
   */
  style?: CSSProperties;
}

const PinterestIcon = ({ width, height, fill, className, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    style={style}
  >
    <defs>
      <path id="prefix__a" d="M0 0L19.199 0 19.199 24 0 24z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        fill={fill}
        d="M10.179 0C3.412 0-.001 4.695-.001 8.61c0 2.371.929 4.48 2.916 5.267.084.033.167.05.244.05.223 0 .403-.136.47-.396.068-.24.222-.85.293-1.107.094-.346.059-.465-.205-.767-.573-.654-.941-1.502-.941-2.7 0-3.486 2.696-6.603 7.014-6.603 3.825 0 5.926 2.261 5.926 5.282 0 3.973-1.817 7.33-4.515 7.33-1.492 0-2.606-1.195-2.248-2.657.428-1.746 1.259-3.63 1.259-4.89 0-1.129-.628-2.068-1.922-2.068-1.525 0-2.75 1.523-2.75 3.567 0 1.3.455 2.182.455 2.182s-1.555 6.39-1.83 7.51c-.545 2.23-.082 4.962-.043 5.238.013.098.097.152.184.152.058 0 .116-.024.154-.074.142-.175 1.955-2.344 2.573-4.51.176-.614 1-3.788 1-3.788.497.913 1.943 1.716 3.483 1.716 4.577 0 7.684-4.036 7.684-9.444-.001-4.09-3.58-7.9-9.021-7.9"
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

PinterestIcon.defaultProps = {
  width: '20',
  height: '24',
  fill: '#4A4A4A',
  className: ''
};

export default PinterestIcon;

import {createAction} from 'redux-actions'
// Import your worker
// @ts-ignore
// import worker from 'workerize-loader!../lib/fetch.worker'; // eslint-disable-line import/no-webpack-loader-syntax
const API_REQUEST = createAction('[app] API Request');

export const apiRequest = (method: any, url: any, body: any, onSuccess: any, onError: any, extra?: any) =>
    API_REQUEST({body, meta: {method, url, onSuccess, onError, extra}});

const sendBody = (method: string, body: any, extra?: any) => {
    if (method === 'PUT' || method === 'POST') {
        const reqObj = {
            body: JSON.stringify(body),
            headers: new Headers({}),
        }

        if (extra && extra.headers) {
            for (const key in extra.headers) {
                reqObj.headers.append(key, extra.headers[key])
            }
        }

        return reqObj
    }
};

const myHeaders = (method:any,extra:any) => {
    const reqHeaders = {
        headers: new Headers({'content-type': 'application/json'})
    }
    if (extra && extra.headers) {
        for (const key in extra.headers) {
            reqHeaders.headers.append(key, extra.headers[key])
        }
    }
    return reqHeaders
};

// this middleware care only for API calls
const api = (store: any) => (next: any) => (action: any) => {
    if (action.type === API_REQUEST().type) {
        const {body, meta} = action.payload;
        const {method, url, onSuccess, onError, extra} = meta;
        // const urlIsJSON = RegExp('(?:json)$').test(url);

        // if (urlIsJSON) {
            fetch(url, {method,  ...myHeaders(method,extra) ,...sendBody(method, body, extra), credentials: "include", mode: "cors"})
                .then(response => {
                //   if (response.status === 401 || response.status === 400 ){
                //     console.error("API :: UnAuthorized")
                //     return store.dispatch({err: undefined, key: "api", type: onError, payload: {}})
                //   }
                  if (!response.ok) {
                    return store.dispatch({err: undefined, key: "api", type: onError, payload: {}})
                }

                  return response.json()
                })
                .then((data) => {
                    if(data){
                    if (data.status === 401 || data.status === 400 ){
                        console.error("API :: UnAuthorized")
                        return store.dispatch({err: undefined, key: "api", type: onError, payload: {}})
                      }
                    if (extra && extra.request) {
                        extra.request.data = data;
                        store.dispatch({err: undefined, key: "api", type: onSuccess, payload: {...data, extra}})
                    } else {
                        store.dispatch({err: undefined, key: "api", type: onSuccess, payload: data})
                    }
                }})
                .catch(error => {
                    console.error("API :: Error", error);
                    return store.dispatch({err: undefined, key: "api", type: onError, payload: error})
                })
    }
    return next(action)
};

export const APIMdl = [api];

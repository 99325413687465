import React from 'react';
import Truncate from 'react-truncate';
import './styles.css';

interface Iprops {
  relativePath?: string;
  data: any;
}

function AccountsGetReward(props: Iprops) {
  const { data } = props;
  return (
    <div className="account-ecards-get-reward">
      {data &&
        data.products &&
        data.productStatus &&
        data.products.map((items: any) => (
          <div>
            {data &&
              data.productStatus.map((product: any) => (
                <div>
                  {items.productID === product.productID && (
                    <div className="items-image-text-binder">
                      <div className="items-image-wraper">
                        <div className="item-img">
                          <img src={product.imageUrl} alt="" />
                        </div>
                      </div>
                      <div className="items-text-wraper">
                        <div className="Item-title">
                          <p
                            className="Item-paragraph font-styleBold"
                            data-testid="cart-item-title"
                          >
                            <div>
                              <p>{product.productTitle}</p>
                              <Truncate
                                lines={2}
                                ellipsis={<span>&nbsp;...</span>}
                              >
                                <p>{product.productDescription}</p>
                              </Truncate>
                            </div>
                          </p>
                        </div>
                        <div className="quantity-wraper">
                          <div
                            data-testid="cart-item-quantity"
                            className="font-styleRegular cart-item-quantity-text"
                          >
                            Qty: {items.quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
    </div>
  );
}

// Set default props
AccountsGetReward.defaultProps = {
  relativePath: ''
};

export default AccountsGetReward;

import React from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 25
   */
  width?: string;
  /**
   * Svg's height
   * @default 25
   */
  height?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
}

const InfoIcon = ({ width, height, className }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M10.5-.75c6.213 0 11.25 5.037 11.25 11.25S16.713 21.75 10.5 21.75-.75 16.713-.75 10.5 4.287-.75 10.5-.75z"
        transform="translate(2 2)"
      />
      <g fill="#333">
        <path
          d="M5.571 11.342c-.185.041-.318.174-.504.215-.209.077-.425.136-.645.175-.275.041-.46-.133-.549-.349-.044-.215-.088-.39-.044-.605.055-.393.132-.783.23-1.169.23-1.169.46-2.371.69-3.54.044-.299.141-.647.088-.954 0-.39-.274-.563-.69-.563H.831c-.14-.007-.28.007-.415.041-.202.025-.367.163-.416.348 0 .133.088.299.318.39.196.05.398.079.602.083.46.041.601.215.601.647v.215C1.335 7.312 1.15 8.35.92 9.386.83 9.99.734 10.595.646 11.15c-.142.862.787 1.683 1.476 1.766.429.048.86.076 1.291.083.92 0 1.707-.39 2.353-.995.088-.083.23-.257.23-.39.035-.223-.15-.398-.425-.273zM3.687 3.25c.908-.002 1.644-.72 1.646-1.605C5.333.762 4.593 0 3.687 0 2.781 0 2.042.721 2 1.605c.042.932.781 1.645 1.687 1.645z"
          transform="translate(2 2) translate(7 4)"
        />
      </g>
    </g>
  </svg>
);

InfoIcon.defaultProps = {
  width: '25',
  height: '25',
  className: ''
};

export default InfoIcon;

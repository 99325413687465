import React, { useEffect }  from 'react';
import { connect } from 'react-redux';
import { Logo} from '../ui-componets/icons';
import { useTranslation } from 'react-i18next';


interface IProps {
}
function BackSoon(props: IProps) {
    const { t } = useTranslation();
        
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      return (
        <div className="work-profile-page">
            <div className="wpf">
                <div className="sdp-binder">
                    <div className="wpf-static-img-binder">
                        <div className="wpf-static-img-sub-binder">
                            <div className="flex-for-static-wpf">
                                <div className="left-content-wpf-static">
                                    <div className="wpf-stc-left-con">
                                        <div className="epoints-logo-holding">
                                        <Logo width="108px" height="30px" />
                                        </div>
                                        <div className="bold-wpf-head">
                                            <p className="m-0 font-styleBold black-for-ac fonting-thrity">
                                            {t(`We’ll Be Back!`)}
                                        </p>
                                        </div>
                                        <div className="regulr-wpf-head">
                                            <p className="m-0 font-styleLight black-for-ac fonting-nineteen">
                                                {t(`This is a holding page. I'm here whilst improvements to the website are happening. If I'm still here tomorrow there's a problem but expect I'll be gone pretty soon!`)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-content-wpf-static">
                                   <div className="rgt-wpf-static">
                                     <img src="/static/back-soon-img.png" />
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {})(BackSoon);
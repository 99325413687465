import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EpointsPrice from "../ui-componets/epoints-price";
import { getTokens, updateGdpr, getCode } from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import { getEmployeeEligibilityStatus, getEmployeeInfo } from '../modules/salary.ducks';
import { currencyConversion } from '../lib/product-calc'
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface IProps {
    getTokens: any
    getBalance:any
    history : any
    balance : any
    isBalanceFetching: any
    loginDetails: any
    tokenDetails: any
    global: any
    updateGdpr: any
    getEmployeeEligibilityStatus: any
    getEmployeeInfo: any
    employeeStatus: any
    employeInfo: any
    countryInfo: any
    shopUser?: boolean
}
function numberWithCommas(x: number | string) {
    if (x) {
        return x
            .toString()
            .split('.')[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
        return '0';
    }
  }
  
function Account(props:IProps) {
    const [fetchingCode, updateFetchingCode] = useState(false);
    let countryCode = props?.loginDetails?.userCountryCode || props?.loginDetails?.countryCode || 'GB';
    
    useEffect(() => {
        window.scrollTo(0, 0);
        props.getBalance();        

        {/* removed the SFS methods as this is migrated to each person app */ }
        // props.getEmployeeEligibilityStatus();
        // props.getEmployeeInfo();
    }, [props.tokenDetails]);


    useEffect(() => {
        props.updateGdpr(false);
    }, []);

    const onURLChange = (url: string) => {
        props.history.push(url);
    }

    const onStripClick = (url: string) => {
        if (props.employeInfo && !props.employeInfo.saveFromSalaryConfigure) {
            props.history.push('/save-from-salary/information');
        } else {
            props.history.push('/save-from-salary/statement');
        }
    }

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;

    const redirecrToDonate = async() => {
        if(isSsoUser) {
            window.location.href = `${props.global.EP_ADMIN_URL}/sso?companyShortName=${companyShortName}&redirectUrl=hr/donate`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
        
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${props.global.EP_ADMIN_URL}/seamless-redirection?code=${userCode?.authenticationCode}&state=/hr/donate`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false)
                window.location.href = '';
            }
        }
    }


    // if code exists and token doesnot exist call congnito
    useEffect(() => {
        console.log("Query params in Account", QS.parse(window.location.search));
        console.log("isSsoUser", isSsoUser);
        // setTimeout(() => {

        // console.log("timeout returning", isSsoUser);
        if (QS.parse(window.location.search).code && !isSsoUser) {
            console.log("QS.parse(window.location.search).code && !isSsoUser");
            // since fetching token in this component can be overridden by other functions and give unexpected result
            props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/account`);

        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            // localStorage.removeItem('ssoUser');
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/account`, companyShortName: companyShortName });

        } else if (!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("props.tokenDetails && !props.tokenDetails.access_token");
            // localStorage.removeItem('ssoUser');
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/account`

        } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/account?companyShortName=${companyShortName}`
            // props.history.push(`/${QS.parse(window.location.search).redirectUrl}?companyShortName=${companyShortName}`);
        }
        //   }, 2000);

    }, []);

    useEffect(() => {
        if (props.loginDetails && props.loginDetails.isBusinessUser === false && props.loginDetails.isGDPRAccepted === false) {
            props.history.push('/join/gdpr');
        } else if (props.loginDetails && props.loginDetails.isBusinessUser === true && props.loginDetails.isGDPRAccepted === false) {
            props.history.push('/Gdpr');
        }
        // else if(props.loginDetails && props.loginDetails.subscriptionStatus ===  "SUBSCRIPTION_PENDING" && props.loginDetails.isGDPRAccepted === true){
        //     props.history.push('/join/pricing');
        // }
    }, [props.loginDetails])

    const {balance, isBalanceFetching, countryInfo} = props
    let _isIOS =  /(iPhone|iPod|iPad)/i.test(navigator.platform);

    const { t } = useTranslation();


    return (
        <div>
            {isBalanceFetching || isBalanceFetching === undefined || fetchingCode || !(props.loginDetails && countryCode) ?
                <div>
                    <Loader />
                </div> :
                <div className="accounts-landing-setup-page">
                    <div className="accounts-lp-sub-binder">
                        <div className="ac-lp-body">
                            <div className="ac-lp-head">
                                <div className="ac-lp-sub-head">
                                    <p className="m-0 font-styleBold">{t('Your Account')}</p>
                                </div>
                            </div>
                            {/* epoints pricing */}
                            <div className="accounts-epoints-balance">
                                <div className="accounts-epoints-balance-sub-binder">
                                    <div className="top-text">
                                        <p className="m-0 font-styleRegular">{t('Your Balance')}:</p>
                                    </div>
                                    <div className="ac-epoints">
                                        <EpointsPrice
                                            size="medium"
                                            epoints={balance && balance.confirmedPoints ? balance.confirmedPoints : 0}
                                            isEpointsShow={false}
                                            price={ balance && balance.confirmedPoints ? parseFloat(currencyConversion(balance ? balance.confirmedPoints : 0, countryInfo && countryInfo.exchangeRate ? countryInfo.exchangeRate : 1)): 0}
                                            currency={countryInfo && countryInfo.currency}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* grid for all links */}
                            <div className="all-links-accounts-binder">
                                <div className="all-links-accounts-binder-sub">
                                    <div className="ac-link-card-binder"  onClick={() => onURLChange('/details')}>
                                        <div className="flexing-set">
                                        <div className="ac-link-image">
                                            <img src="/static/yourdetails.svg" />
                                        </div>
                                        <div className="ac-link-text">
                                            <p className="m-0 font-styleRegular">{t('Your Details')}</p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="ac-link-card-binder" onClick={() => onURLChange('/activity')}>
                                    <div className="flexing-set">
                                        <div className="ac-link-image">
                                        <img src="/static/your-activity.svg" />
                                        </div>
                                        <div className="ac-link-text">
                                            <p className="m-0 font-styleRegular">{t('Your Activity')}</p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="ac-link-card-binder removed-margins" onClick={() => onURLChange('/orders')}>
                                    <div className="flexing-set">
                                        <div className="ac-link-image">
                                            <img src="/static/your-orders.svg" />
                                        </div>
                                        <div className="ac-link-text">
                                            <p className="m-0 font-styleRegular">{t("Your Orders")}</p>
                                        </div>
                                        </div>
                                    </div>
                                    {props.loginDetails && countryCode && countryCode === 'GB' ?
                                    <div className="ac-link-card-binder" onClick={() => onURLChange('/addresses')}>
                                    <div className="flexing-set you-address-edit">
                                        <div className="ac-link-image">
                                            <img src="/static/youraddress.svg" />
                                        </div>
                                        <div className="ac-link-text">
                                            <p className="m-0 font-styleRegular">{t("Your Addresses")}</p>
                                        </div>
                                        </div>
                                    </div>: null}

                                    <div className="ac-link-card-binder" onClick={() => onURLChange('/preferences')}>
                                    <div className="flexing-set mp-positions">
                                        <div className="ac-link-image">
                                            <img src="/static/marketing-prefer.svg" />
                                        </div>
                                        <div className="ac-link-text">
                                            <p className="m-0 font-styleRegular">{t('Marketing Preferences')}</p>
                                        </div>
                                        </div>
                                    </div>

                                    {
                                        props.shopUser ||
                                        <div className="ac-link-card-binder removed-margins md-changes" onClick={() => onURLChange('/membership')}>
                                            <div className="flexing-set">
                                                <div className="ac-link-image">
                                                    <img src="/static/membership-details.svg" />
                                                </div>
                                                <div className="ac-link-text">
                                                    <p className="m-0 font-styleRegular">{t('Membership Details')}</p>
                                                </div>
                                            </div>
                                        </div>

                                    }
                                    <div className={cx('ac-link-card-binder', {
                                            'removed-ms': props.loginDetails && countryCode && countryCode !== 'GB'
                                            })} onClick={() => onURLChange('/payments')}>
                                    <div className="flexing-set payment-de">
                                        <div className="ac-link-image">
                                            <img src="/static/payment-deatils.svg" />
                                        </div>
                                        <div className="ac-link-text">
                                            <p className="m-0 font-styleRegular">{t('Payment Details')}</p>
                                        </div>
                                        </div>
                                    </div>
                            { ((props.loginDetails && props.loginDetails.isBusinessUser === true) 
                            ||  (props.loginDetails.tokens && !props.loginDetails.tokens.access_token))
                            && !_isIOS?
                                props.shopUser ||
                                    <div className="ac-link-card-binder" onClick={redirecrToDonate}>
                                    <div className="flexing-set">
                                        <div className="ac-link-image">
                                            <img src="/static/donate.svg" />
                                        </div>
                                        <div className="ac-link-text donate-text">
                                            <p className="m-0 font-styleRegular">{t('Donate')}</p>
                                        </div>
                                        </div>
                                    </div>: null}
                                    {
                                        props.shopUser ||
                                        <div className="ac-link-card-binder removed-margins" onClick={() => onURLChange('/profile')}>
                                            <div className="flexing-set">
                                                <div className="ac-link-image">
                                                    <img src="/static/work-profile.svg" />
                                                </div>
                                                <div className="ac-link-text">
                                                    <p className="m-0 font-styleRegular">{t('Work Profile')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* {props.employeeStatus && props.employeeStatus.active ?
                                            <div className="salary-strips cursor-pointer">
                                                <SaveSalaryStrip handleLinkClick={onStripClick}/>
                                            </div>: null
                                } */}
                            </div>
                            
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    balance: state.ui.balance,
    global: state.global,
    loginDetails: state.join.loginDetails,
    tokenDetails: state.join.tokens,
    isBalanceFetching: state.ui.isBalanceFetching,
    employeeStatus: state.salary.employeeStatus,
    employeInfo: state.salary.employeeInfo,
    countryInfo: state.ui.countryDetails

});

export default connect(mapStateToProps, { getTokens, updateGdpr, getEmployeeEligibilityStatus, getEmployeeInfo })(Account);
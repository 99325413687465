import { Action, ReducerClass } from 'reducer-class';
import { createAction } from 'redux-actions';
import { apiRequest } from './api.ducks';
import cookie from 'cookie';
import { store } from "../redux/store";
import {getExchangeToken} from './join.ducks';

const GET_ADDRESS_LIST = createAction('[ADDRESS] GET Adress List');
const ADD_ADDRESS = createAction('[ADDRESS] ADD Address');
const REMOVE_ADDRESS = createAction('[ADDRESS] Remove Address');
const CONTACT_US = createAction('[CONTACT] Contact Us Request');





const ADDRESS_FETCH_SUCCESS = createAction('[ADDRESS] Fetch Address Success');
const ADD_ADDRESS_SUCCESS = createAction('[ADDRESS] Add Address Success');
const REMOVE_ADDRESS_SUCCESS = createAction('[ADDRESS] Remove Address');
const CONTACT_US_SUCCESS = createAction('[CONTACT] Contact Us Request Success');
const UPDATE_CONTACT_SUCCESS = createAction('[CONTACT] Update Contact Us Request Success')
const RESET_CONTACT_SUCCESS = createAction('[CONTACT] Reset Contact Us Success')




const ADDRESS_FETCH_ERROR = createAction('[ADDRESS] Fetch Address Error');
const ADD_ADDRESS_ERROR = createAction('[ADDRESS] Add Address Success');
const REMOVE_ADDRESS_ERROR = createAction('[ADDRESS] Remove Address');
const CONTACT_US_ERROR = createAction('[CONTACT] Contact Us Request Error');




const UPDATE_ADDRESS_LIST = createAction('[ADDRESS] Update Address List');
const UPDATE_CONTACT_ERROR = createAction('[CONTACT] Update Contact Us Request Error');
const UPDATE_ADDRESS_FETCH_STATUS = createAction('[ADDRESS] Update Activity Fetch Status');

export const getAddressList = () => GET_ADDRESS_LIST();
export const addAddress = (data: any) => ADD_ADDRESS(data);
export const removeAddress = (data: any) => REMOVE_ADDRESS(data);
export const contactUsRequest = (data: any) => CONTACT_US(data);

export const resetContactSuccess = (bool: boolean) => RESET_CONTACT_SUCCESS(bool);


const updateAddressList = (data: any) => UPDATE_ADDRESS_LIST(data);
const updateContactErr = (data: any) => UPDATE_CONTACT_ERROR(data);
const updateContactSuccess = (data: any) => UPDATE_CONTACT_SUCCESS(data);
const updateAddressError = (data: any) => ADDRESS_FETCH_ERROR(data)
const updateAddressFetchStatus = (data: any) => UPDATE_ADDRESS_FETCH_STATUS(data);


const getUuid = () => {
    let UUID: string | undefined
    const getCookie = document.cookie;
    const userCookie = cookie.parse(getCookie).user;
    if (userCookie && JSON.parse(userCookie).id) {
        UUID = JSON.parse(userCookie).id;
    }

    return UUID
};

const reorderAddress = (addresses: any) => {
    const preferred = addresses.find((a: any) => a.preferredDeliveryAddress === true)
    if (preferred) {
        const restAddress = addresses.filter((a: any) => a.preferredDeliveryAddress !== true)
        restAddress.splice(0, 0, preferred)
        return restAddress
    }
    return addresses
}

interface IReducerAddressState {
    addressError: boolean
    isAddressFetching: boolean
}

class Address extends ReducerClass<IReducerAddressState> {
    initialState = {
        addresses: [],
        addressError: false,
        contactUsError: false,
        contactUsSuccess: false,
        isAddressFetching: true

    };

    static handleGetAddress = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_ADDRESS_LIST().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(updateAddressFetchStatus(true));
            store.dispatch(apiRequest(
                "GET",
                global.API_PROD_END_POINT + '/rest/users/delivery/addresses/V2?page=0&size=20',
                null,
                ADDRESS_FETCH_SUCCESS().type,
                ADDRESS_FETCH_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }
                }
            ))
            }
        }
    };

    static handleAddAddress = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === ADD_ADDRESS().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "PUT",
                global.API_PROD_END_POINT + '/rest/users/delivery/addresses/V2',
                action.payload,
                ADD_ADDRESS_SUCCESS().type,
                ADD_ADDRESS_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }
                }
            ))
            }
        }
    }

    static handleRemoveAddress = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === REMOVE_ADDRESS().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "POST",
                global.API_PROD_END_POINT + '/rest/users/delivery/address/delete/V2',
                action.payload,
                REMOVE_ADDRESS_SUCCESS().type,
                REMOVE_ADDRESS_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }
                }
            ))
            }
        }
    }


    static handleContactUs = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === CONTACT_US().type) {
            const { global } = store.getState();
            store.dispatch(apiRequest(
                "POST",
                global.API_UI_END + '/requests',
                action.payload,
                CONTACT_US_SUCCESS().type,
                CONTACT_US_ERROR().type
            ))
        }
    }

    static processAddressList = (store: any) => (next: any) => (action: any) => {
        next(action);
        // get sso object from localStorage
        let {global} = store.getState();
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;

        if (action.type === ADDRESS_FETCH_SUCCESS().type) {
            const addressesList = action.payload;
            store.dispatch(updateAddressList(reorderAddress(addressesList)))
            store.dispatch(updateAddressFetchStatus(false));
        }

        if (action.type === ADDRESS_FETCH_ERROR().type) {
            store.dispatch(updateAddressFetchStatus(false));
            store.dispatch(
                updateAddressError({
                    addressError: true
                })
            );
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    };

    static processAddAddress = (story: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === ADD_ADDRESS_SUCCESS().type) {
            store.dispatch(getAddressList());
        }
    }


    static processRemoveAddress = (story: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === REMOVE_ADDRESS_SUCCESS().type) {
            store.dispatch(getAddressList());
        }
        if (action.type === REMOVE_ADDRESS_ERROR().type) {
            console.log("Delete Error", action.payload);
            // store.dispatch(getAddressList());
        }
    }

    static processContactUs = (story: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === CONTACT_US_SUCCESS().type) {
            store.dispatch(updateContactSuccess(true));
        }
        if (action.type === CONTACT_US_ERROR().type) {
            store.dispatch(updateContactErr(true));
            setTimeout(() => {
                store.dispatch(updateContactErr(false));
            }, 3000);
        }
    }

    @Action(UPDATE_ADDRESS_FETCH_STATUS())
    updateAddressFetchStatus(state: IReducerAddressState, action: any) {
        return {
            ...state,
            isAddressFetching: action.payload,
        };
    }
    
    @Action(UPDATE_ADDRESS_LIST())
    updateAddressList(state: IReducerAddressState, action: any) {
        return {
            ...state,
            addresses: action.payload
        }
    }

    @Action(UPDATE_CONTACT_ERROR())
    updateContactErr(state: IReducerAddressState, action: any) {
        return {
            ...state,
            contactUsError: action.payload
        }
    }

    @Action(UPDATE_CONTACT_SUCCESS())
    updateContactSuccess(state: IReducerAddressState, action: any) {
        return {
            ...state,
            contactUsSuccess: action.payload
        }
    }

    @Action(RESET_CONTACT_SUCCESS())
    resetContactSuccess(state: IReducerAddressState, action: any) {
        return {
            ...state,
            contactUsSuccess: action.payload
        };
    }
}

export default Address.create();

export const AddressMdl = [Address.handleGetAddress, Address.handleAddAddress, Address.handleContactUs, Address.handleRemoveAddress, Address.processAddressList, Address.processAddAddress, Address.processRemoveAddress, Address.processContactUs];

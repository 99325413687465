import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's height
   * @default 36
   */
  height?: string;

  /**
   * Svg's width
   * @default 36
   */
  width?: string;

  /**
   * Svg's fill
   * @default "#2D2D2D"
   */
  fill?: string;

  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;

  /**
   * Svg's style
   * @default ''
   */
  style?: CSSProperties;
}

const CelebrateJobIcon = ({
  width,
  height,
  className,
  fill,
  style
}: IProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
  >
    <text
      fill={fill}
      fillRule="evenodd"
      fontFamily="GoodKarma-Upright, GoodKarma-Regular"
      className="font-goodkarmaUpright font-goodkarmaRegular"
      fontSize="21"
      fontWeight="500"
      letterSpacing=".2"
      transform="translate(0 -2)"
    >
      <tspan x="0" y="20">
        Celebrate a job well done
      </tspan>{' '}
      <tspan
        x="1.64"
        y="38"
        font-family="OpenSans-Semibold, Open Sans"
        font-size="11"
        letter-spacing=".1"
      >
        with epoints at work!
      </tspan>
    </text>
  </svg>
);

CelebrateJobIcon.defaultProps = {
  height: '39',
  width: '156',
  fill: '#2D2D2D',
  className: ''
};

export default CelebrateJobIcon;

import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   */
  width?: number;
  /**
   * Svg's height
   */
  height?: number;
  /**
   * Svg's fill
   */
  fill?: string;
  /**
   * Svg's classNames
   */
  className?: string;
  /**
   * Svg's style
   */
  style?: CSSProperties;
}

const CloseError = ({ width, height, fill, className, style }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
      style={style}
    >
      <g fill="none" fillRule="evenodd">
        <rect width={width} height={height} fill={fill} rx="32.5" />
        <text
          fill="#FFF"
          fontFamily="ArialRoundedMTBold, Arial Rounded MT Bold"
          fontSize="45"
          letterSpacing="-.6"
        >
          <tspan x="21.578" y="45">
            x
          </tspan>
        </text>
      </g>
    </svg>
  );
};

CloseError.defaultProps = {
  width: '67',
  height: '65',
  fill: '#B40000',
  className: ''
};

export default CloseError;

import React, { useState } from 'react';
import { Accordion } from 'semantic-ui-react';

import './styles.css';
import { useTranslation } from 'react-i18next';

interface IProps {
  billingData: any;
  getInvoice: (id: any) => void;
}

function AccountsMemberShip(props: IProps) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const { billingData, getInvoice } = props;

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <div className="accordion-for-membership">
      <div className="accordian-header-ac">
        <div className="accord-sub-head">
          <div className="flex-ac-date">
            <p className="m-0 font-styleLight font-tweleve">{t('Date')}</p>
          </div>
          <div className="flex-ac-date">
            <p className="m-0 font-styelLight font-tweleve">{t('Invoice')}</p>
          </div>
        </div>
      </div>
      {billingData &&
        billingData.map((row: any) => (
          <Accordion styled>
            <Accordion.Title
              active={activeIndex === row.id}
              index={row.id}
              onClick={handleClick}
            >
              <div className="ac-mem-drp-text">
                <div className="ac-mem-drp-sub-binder">
                  <div>
                    <p className="m-0 right-dp-ac-img">
                      <img
                        className="dropdown icon"
                        src="./static/greater.svg"
                      />
                      <span className="m-0 font-styleRegular">
                        {row.invoiceDate}
                      </span>
                    </p>
                  </div>
                  <div>
                    <a onClick={() => getInvoice(row.id)}>
                      <p className="m-0 font-styleRegular color-greening fonr-forteen">
                        {t('View invoice')}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </Accordion.Title>

            <Accordion.Content active={activeIndex === row.id}>
              <div className="member-ac-content">
                <div className="member-ac-content-sub">
                  <div className="cont-box-ac">
                    <p>{t('One Off Joining Fee')}</p>
                  </div>
                  <div className="cont-box-ac">
                    <p>£{row.addonAmount}</p>
                  </div>
                </div>
                <div>
                  <p className="m-0 starighting-line"></p>
                </div>
                <div className="member-ac-content-sub">
                  <div className="cont-box-ac">
                    <p>{t('Annual Fee')} {row.planPeriod}</p>
                  </div>
                  <div className="cont-box-ac">
                    <p>£{row.planAmount}</p>
                  </div>
                </div>
              </div>
            </Accordion.Content>
          </Accordion>
        ))}
    </div>
  );
}

// Set default props
AccountsMemberShip.defaultProps = {
  relativePath: ''
};

export default AccountsMemberShip;

import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's height
   *
   * @type {string}
   * @default 67px
   */
  height?: string;

  /**
   * Svg's width
   * @default 66px
   */
  width?: string;

  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;

  /**
   * Svg's style
   * @default ''
   */
  style?: CSSProperties;
  relativePath?: string;
}

const LogoIconEachPerson = (props: IProps) => (
  <img src={`${props.relativePath}/static/each-person-logo.svg`} />
);

LogoIconEachPerson.defaultProps = {
  width: '66px',
  className: '',
  relativePath: ''
};

export default LogoIconEachPerson;

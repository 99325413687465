import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Stepper from "../ui-componets/stepper"
import JoinDetails from "./Join/JoinDetails";
import JoinGdpr from "./Join/JoinGdpr";
import JoinPricing from './Join/JoinPricing';
import JoinSuccess from './Join/JoinSuccess';
import { withTranslation } from 'react-i18next';

interface JoinProps {
    history:any
    currentStep: any
    t: (key: string) => string;
}
class Join extends Component<JoinProps> {
    render() {
        const { t } = this.props; // Destructure t from props
        const joinSteps = [t('Join'), t('Payment'), t('Confirm')];

        return (
            <div className="join-all-container">
                <div className="banner-join">
                    <div className="sbanner-join-sub">
                        <img src="/static/join-banner.png" />
                    </div>
                    <div className="sbanner-join-sub-mobile">
                        <img src="/static/mobile-join-banner.png" />
                    </div>
                </div>
                {/* stepper */}
                <div className="stepper-join">
                    <Stepper steps={joinSteps} current={this.props.currentStep} />
                </div>
                <div className="body-of-join">
                <Suspense fallback={<div>{t('Loading...')}</div>}>
                    <Switch>
                    <Route
                            exact={true}
                            key={'/'}
                            path={'/join'}
                            render={(props) => <JoinDetails {...props} />}
                        />
                        <Route
                            exact={true}
                            key={'join-details'}
                            path={'/join/details'}
                            render={(props) => <JoinDetails {...props} />}
                        />
                        <Route
                            exact={true}
                            key={'join-gdpr'}
                            path={'/join/gdpr'}
                            render={(props) => <JoinGdpr {...props} />}
                        />
                        <Route
                            exact={true}
                            key={'join-pricing'}
                            path={'/join/pricing'}
                            render={(props) => <JoinPricing {...props} />}
                        />
                        <Route
                            exact={true}
                            key={'join-success'}
                            path={'/join/success'}
                            render={(props) => <JoinSuccess {...props} />}
                        />
                    </Switch>
                    </Suspense>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => ({
    currentStep: state.join.currentStep,
});
export default connect(mapStateToProps, {})(withTranslation()(Join));

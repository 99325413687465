import cx from 'classnames';
import React from 'react';
import { GoPrimitiveDot } from 'react-icons/go';

// load epoints small logo
import { LogoSmall as Logo } from '../icons';
import './styles.css';

// TODO: Set up Internationalization for currency and text

interface IProps {
  /**
   * Size of the product card. There's currently 3 sizes: 'default', 'product-card' and 'header'
   *
   * @default 'small'
   **/
  size?:
    | 'small'
    | 'large'
    | 'medium'
    | 'default'
    | 'product-card'
    | 'header'
    | 'negative';

  /**
   * Display small font-size or not
   */
  small?: boolean;

  /**
   * The epoints amount
   */
  epoints: string | number;

  /**
   * The equivalent epoints amount in pounds
   */
  price: number;

  /**
   * Sets Width and Height on the Dot icon
   *
   * @default 5
   */
  dotSize?: number;

  className?: string;
  isEpointsShow?: boolean;
  labelClassName?: string;
  eachPersonRewards?: boolean;
  currency?: any;
}

function numberWithCommas(x: number | string) {
  if (x) {
    return x
      .toString()
      .split('.')[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0';
  }
}

function EpointsPrice(props: IProps) {
  const { epoints, price, size, className, labelClassName } = props;
  let { dotSize } = props;
  if (size === 'large') dotSize = 13;
  else if (size === 'small') dotSize = 8;
  else if (size === 'medium') dotSize = 10;
  else if (size === 'negative') dotSize = 8;

  return (
    <div
      className={cx(
        `epoints-price`,
        size === 'small'
          ? 'items-start justify-start'
          : 'items-center justify-center',
        `epoints-price-${size}`,
        'background-changes-inner',
        className
      )}
    >
      <div className={`epoints-price-inner inner-price inner-price-${size}`}>
        <span
          className={cx(
            'epoints-price-text epoints-price-price',
            `epoints-price-text-${size}`,
            props.small ? '' : '',
            labelClassName ? labelClassName : 'font-styleRegular'
          )}
          automation-id="epoints-price-price"
        >
          {(price || price >= 0) && (
            <span>{props.currency}{new Intl.NumberFormat().format(price)}</span>
          )}
        </span>
        {props.isEpointsShow && (
          <>
            <span className="epoints-price-dot">
              <GoPrimitiveDot
                size={dotSize}
                className={`epoints-price-dot-svg epoints-price-dot-${size}`}
              />
            </span>
            <div className="epoints-price-epoints-container">
              <Logo
                fillColor={size === 'negative' ? '#b40000' : '#00B451'}
                textColor={size === 'negative' ? '#fff' : '#1C1C1C'}
                className={`epoints-price-logo logo-ep epoints-price-logo-${size}`}
              />
              <span
                className={cx(
                  'epoints-price-text epoints-price-epoints',
                  `epoints-price-epoints-${size}`,
                  `epoints-price-text-${size}`,
                  props.small ? 'text-lg' : '',
                  'text-epoints-yellow font-styleBold'
                )}
                data-testid="epoints-price-points"
              >
                {numberWithCommas(epoints)}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

// Set default props
EpointsPrice.defaultProps = {
  size: 'small',
  dotSize: 5,
  className: '',
  isEpointsShow: true
};

export default EpointsPrice;

import React from 'react';

interface IProps {
  /**
   * Svg's height
   * @default 23
   */
  height?: string;

  /**
   * Svg's width
   * @default 160
   */
  width?: string;

  /**
   * Svg's fill
   * @default "#2D2D2D"
   */
  fill?: string;

  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
}

const VisitManagerIcon = ({ width, height, className, fill }: IProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox={`0 0 ${width} ${height}`}
  >
    <text
      fill={fill}
      fillRule="evenodd"
      fontFamily="GoodKarma-Upright, GoodKarma-Regular"
      className="font-goodkarmaUpright font-goodkarmaRegular"
      fontSize="21"
      fontWeight="500"
      letterSpacing=".2"
      transform="translate(0 -4)"
    >
      <tspan x="0" y="20">
        Visit Recognition Manager
      </tspan>{' '}
      <tspan
        x="1.64"
        y="40"
        font-family="OpenSans-Semibold, Open Sans"
        font-size="11"
        letter-spacing=".1"
      >
        to celebrate a job well done!
      </tspan>
    </text>
  </svg>
);

VisitManagerIcon.defaultProps = {
  height: '41',
  width: '160',
  fill: '#2D2D2D',
  className: ''
};

export default VisitManagerIcon;

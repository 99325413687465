import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoaderEpoints from '../ui-componets/loader';


interface IProps {
    global: any
}

function LogoutHandler(props: IProps) {
  useEffect(() => {
    let global = props.global;
    let loginUrl = `${global.EP_ADMIN_URL}/login`;
    let customLogout = localStorage.getItem('customLogoutUrl');
    if(customLogout) {
        localStorage.removeItem('customLogoutUrl');
        window.location.href = customLogout;
    } else {
        window.location.href = loginUrl;
    }  
  }, []);

  return (
    <>
      <div><LoaderEpoints/></div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  global: state.global
});
export default connect(mapStateToProps)(LogoutHandler);

import { Action, ReducerClass } from 'reducer-class';
import { createAction } from 'redux-actions';
import { apiRequest } from './api.ducks';
import QS from "query-string";
import { store } from "../redux/store";
import { toast } from 'react-toastify';
import axios from 'axios';
declare var document: any;


const JOIN_INIT = createAction('[JOIN] Join Init Request');
const PUT_JOIN_GDPR = createAction('[JOIN] Put Join Gdpr Update');
const SUBMIT_SUBSCRIPRION  = createAction('[JOIN] Submit Subscription Details');
const PUT_BUSINESS_GDPR = createAction('[JOIN] Put Businerss Gdpr Update');
const GET_CARD_STREAM_FORM = createAction('[JOIN] GET Card Stream Form');
const GET_TOKENS = createAction('[LOGIN] Get Tokens');
const GET_EXCHANGE_TOKEN = createAction('[LOGIN] Get Exchange Tokens');
const CLEAR_TOKENS = createAction('[TOKEN] Cart Tokens');
const GET_LOG_OUT = createAction('[LOGOUT] Logout Clear Sessions');
const GET_EMAIL_CHANGE_LOG_OUT = createAction('[EMAIL_LOGOUT] Email Logout Clear Sessions');
const GET_SEFLF_SERVICE_INFO = createAction('[TOKEN] Get Self Service Info');




const JOIN_INIT_SUCCESS = createAction('[JOIN] Join Init Request Success');
const GET_EXCHANGE_TOKEN_SUCCESS = createAction('[LOGIN] Get Exchange Success');
const SUBMIT_SUBSCRIPRION_SUCCESS = createAction('[JOIN] Post Submit Subscription Details');
const GET_TOKENS_SUCCESS = createAction('[LOGIN] Get Tokens Success');
const GET_LOGOUT_SUCCESS = createAction('[LOGOUT] Get Logout Success');
const GET_EMAIL_CHANGE_LOGOUT_SUCCESS = createAction('[EMAIL_LOGOUT] Email Change Logout Success');
const GET_SEFLF_SERVICE_INFO_SUCCESS = createAction('[TOKEN] Get Self Service Info Success');
const GET_LOGIN_SUCCESS = createAction('[LOGIN] Get Login Success');
const PUT_JOIN_GDPR_SUCCESS = createAction('[JOIN] Put Join Gdpr Update Success');
const PUT_BUSINESS_GDPR_SUCCESS = createAction('[JOIN] Put Business Gdpr Update Success');
const CARD_STREAM_FORM_SUCCESS = createAction('[JOIN] Card Stream Form Success');
const UPDATE_JOIN_ERR_MSG = createAction('[JOIN] Update Join Error Message');



const UPDATE_JOIN_INIT_SUCCESS = createAction('[JOIN] Update Join Init Request Success');
const UPDATE_EXCHANGE_TOKENS = createAction('[TOKENS] Update Exchange TOKENS');
const UPDATE_REFRESH_TOKEN = createAction('[TOKENS] Update Refresh TOKENS');
const UPDATE_JOIN_STEP = createAction('[JOIN] Update Join Step');
const UPDATE_DONATION_VALUE = createAction('[DONATION] Update Donation Value');
const UPDATE_JOIN_SUCESS_MESSAGE = createAction('[JOIN] Update Join Success Message')

const UPDATE_GDPR = createAction('[JOIN] Update Gdpr Success');
const UPDATE_BUSINESS_GDPR = createAction('[JOIN] Update Business Gdpr Success');
const UPDATE_CARD_STREAM_FORM = createAction('[JOIN] Update Card Stream Form');
const UPDATE_JOIN_INIT_ERROR = createAction('[JOIN] Update Join Init Request Error');
const UPDATE_TOKENS = createAction('[LOGIN] Update TOKENS');
const UPDATE_SUBMIT_SUBSCRIPRION = createAction('[JOIN] Update Submition Request');
const UPDATE_LOGIN_DETAILS = createAction('[LOGIN] Update LOGIN DETAILS');
const UPDATE_SEFLF_SERVICE_INFO = createAction('[TOKEN] Update Self Service Info');




const JOIN_INIT_ERROR = createAction('[JOIN] Join Init Request Error');
const GET_EXCHANGE_TOKEN_ERROR = createAction('[LOGIN] Get Exchange Error');
const GET_TOKENS_ERROR = createAction('[LOGIN] Get Tokens Error');
const GET_LOGIN_ERROR = createAction('[LOGIN] Get Login Error');
const SUBMIT_SUBSCRIPRION_ERROR = createAction('[JOIN] Post Submit Subscription Details Error');
const PUT_GDPR_ERROR = createAction('[JOIN] Put Gdpr Update Error');
const PUT_BUSINESS_GDPR_ERROR = createAction('[JOIN] Put Gdpr BUSINESS Update Error');
const CARD_STREAM_FORM_ERROR = createAction('[JOIN] Card Stream Form Error');
const GET_EMAIL_CHANGE_LOGOUT_ERROR = createAction('[EMAIL_LOGOUT] Get Email Logout Error');
const GET_LOGOUT_ERROR = createAction('[LOGOUT] Get Logout Error');
const GET_SEFLF_SERVICE_INFO_ERROR = createAction('[TOKEN] Get Self Service Info Error');




export const joinInitRequest = (data: any) => JOIN_INIT(data);
export const updateJoinStep = (data: any) => UPDATE_JOIN_STEP(data);
export const updateJoinSuccessMessage  = (data: any) => UPDATE_JOIN_SUCESS_MESSAGE(data);
export const updateDonationValue = (data: any) => UPDATE_DONATION_VALUE(data);
export const updateJoinErrMsg = (data: any) => UPDATE_JOIN_ERR_MSG(data);
export const getTokens = (data:any) => GET_TOKENS(data);
export const getCode = async () => {
    const { global, join } = store.getState();
    let bodyFormData = {
        refreshToken: join.refreshToken
    }        
        
    return new Promise((resolve, reject) => {
        axios({
            withCredentials: true,
            method: 'post',
            url: `${global.EP_ADMIN_URL}/api/user-authentication/exchange-code`,
            data: bodyFormData,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response:any) {
            resolve(response.data)
        })
        .catch(function (error:any) {
            reject(error)
        });
    });
}
export const getSelfServiceInfo = () => GET_SEFLF_SERVICE_INFO();
export const getExchangeToken = (data:any) => GET_EXCHANGE_TOKEN(data);
export const submitSubscription = (data:any) => SUBMIT_SUBSCRIPRION(data);
export const clearTokens = () =>  CLEAR_TOKENS();
export const logOut = () =>  GET_LOG_OUT();
export const credentialChangeLogout = () =>  GET_EMAIL_CHANGE_LOG_OUT();
export const gdprJoinUpdate = (formData:any) => PUT_JOIN_GDPR(formData);
export const gdprBusinessUpdate = (data:any) => PUT_BUSINESS_GDPR(data);
export const getCardStreamForm = (data: any) => GET_CARD_STREAM_FORM(data);
export const updateCardStreamForm = (data: any) => UPDATE_CARD_STREAM_FORM(data);
export const updateGdpr = (data:any) => UPDATE_GDPR(data);


// export const updateCardStreamForm = (data: any) => UPDATE_CARD_STREAM_FORM(data);




const updateBusinessGdpr = (data:any) => UPDATE_BUSINESS_GDPR(data);
const updateJoinInitErr = (data: any) => UPDATE_JOIN_INIT_ERROR(data);
const updateJoinInitSuccess = (data: any) => UPDATE_JOIN_INIT_SUCCESS(data);
const updateTokens= (data: any) => UPDATE_TOKENS(data);
const updateExchangeTokens= (data: any) => UPDATE_EXCHANGE_TOKENS(data);
const updateRefreshToken = (data:any) => UPDATE_REFRESH_TOKEN(data);
const updateSubmitionRequest = (data: any) => UPDATE_SUBMIT_SUBSCRIPRION(data);
const updateLoginDetails= (data: any) => UPDATE_LOGIN_DETAILS(data);
const updateSelfServiceInfo = (data: any) => UPDATE_SEFLF_SERVICE_INFO(data);

// toast error message
const errNotify = (msg: any) => toast.error(msg, {
    position: "top-right",
    closeButton: false,
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "taost-bg-red",
    // icon: ({}) =>  <img src="/static/toast-tick.svg"/>
});


interface IReducerJoinState {
     currentStep: number
     donateValue: number
     submitionRequest : boolean
     refreshToken : string
     selfServiceInfo : {}
     tokens: any
}

class Join extends ReducerClass<IReducerJoinState> {
     initialState = {
          joinError: false,
          joinSuccesss: false,
          currentStep: 0,
          formData: {},
          donateValue: 0,
          tokens : {},
          submitionRequest: false,
          refreshToken : '',
          selfServiceInfo: {}
     }

     static handleJoinInit = (store: any) => (next: any) => (action: any) => {
          next(action);
          if (action.type === JOIN_INIT().type) {
               const { global } = store.getState();
               const formData = action.payload
               store.dispatch(apiRequest(
                    "GET",
                    global.API_UI_END + `/rest/join/init/${formData.email}/V2?firstName=${formData.firstName}&lastName=${formData.lastName}`,
                    null,
                    JOIN_INIT_SUCCESS().type,
                    JOIN_INIT_ERROR().type,
                ))
          }
     }

     static submitSubscription = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === SUBMIT_SUBSCRIPRION().type) {
             const { global, join } = store.getState();
             const formData = {plan : 'membership_yearly'}
             if(join.tokens &&  join.tokens.access_token) {
             store.dispatch(apiRequest(
                  "POST",
                  global.API_UI_END + `/rest/user/${join.loginDetails.email}/subscription_registration?plan=membership_yearly`,
                  formData,
                  SUBMIT_SUBSCRIPRION_SUCCESS().type,
                  SUBMIT_SUBSCRIPRION_ERROR().type,
                  {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json'
                    }}
              ))
            }
        }
   }



     static gdprJoinUpdate = (store: any) => (next: any) => (action: any) => {
          next(action);
          if (action.type === PUT_JOIN_GDPR().type) {
              const { global, join } = store.getState();
              if(join.tokens &&  join.tokens.access_token) {
              store.dispatch(apiRequest(
                  "PUT",
                  global.API_PROD_END_POINT + '/rest/user/gdpr/update/V2',
                  action.payload,
                  PUT_JOIN_GDPR_SUCCESS().type,
                  PUT_GDPR_ERROR().type,
                  
                  {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json'
                    }}
              ))
            }
          }
      }


      static gdprBusinessUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === PUT_BUSINESS_GDPR().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "PUT",
                global.API_PROD_END_POINT + '/rest/user/gdpr/update/V2',
                action.payload,
                PUT_BUSINESS_GDPR_SUCCESS().type,
                PUT_BUSINESS_GDPR_ERROR().type,
                
                {
                  headers: {
                      'Authorization': join.tokens.access_token,
                      'Content-Type': 'application/json'
                  }}
            ))
            }
        }
    }

      static getTokens = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_TOKENS().type) {
        console.log("getTokens inside if...", action.payload);
            const {global} = store.getState();
            const redirectUrl = action.payload.companyShortName ? `${window.location.origin}${action.payload.redirectURL}?companyShortName=${action.payload.companyShortName}` : '';
            const cognitoUrl = action.payload.companyShortName ? `${global.API_UI_END}/rest/authenticate-token?code=${action.payload.code}&redirect=${redirectUrl}&companyShortName=${action.payload.companyShortName}` : `${global.EP_ADMIN_URL}/api/user-authentication/get-token?code=${action.payload.code}`
            store.dispatch(apiRequest(
                'GET',
                cognitoUrl,
                null,
                GET_TOKENS_SUCCESS().type,
                GET_TOKENS_ERROR().type,
            ));
        }
    }   

    static getSelfServiceInfo = (store: any) => (next: any) => (action: any) => {

        next(action);
        if (action.type === GET_SEFLF_SERVICE_INFO().type) {
            const { global, join } = store.getState();
            if (join.tokens && join.refreshToken) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/user/self-service-check`,
                    null,
                    GET_SEFLF_SERVICE_INFO_SUCCESS().type,
                    GET_SEFLF_SERVICE_INFO_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json'
                        }}
                ));
        }
        }
    }

    static getExchangeToken = (store: any) => (next: any) => (action: any) => {

        next(action);
        if (action.type === GET_EXCHANGE_TOKEN().type) {
            const { global, join } = store.getState();
            if(action.payload.companyShortName) {
                const cognitoUrl = `/rest/exchange-token?redirect=${window.location.origin}&companyShortName=${action.payload.companyShortName}`
                if (join.tokens && join.refreshToken) {
                    console.log("Excchange token call inside")
                    store.dispatch(apiRequest(
                        'GET',
                        global.API_UI_END + cognitoUrl,
                        null,
                        GET_EXCHANGE_TOKEN_SUCCESS().type,
                        GET_EXCHANGE_TOKEN_ERROR().type,
                        {
                            headers: {
                                'token': join.refreshToken,
                                'Content-Type': 'application/json',
                            }
                        }
                    ));
                }
            } else {
                const apiUrl = `/api/user-authentication/exchange-token`
                if (join.tokens && join.refreshToken) {
                    const data = {
                        refreshToken: join.refreshToken
                    }
                    console.log("Excchange token call inside")
                    store.dispatch(apiRequest(
                        'POST',
                        global.EP_ADMIN_URL + apiUrl,
                        data,
                        GET_EXCHANGE_TOKEN_SUCCESS().type,
                        GET_EXCHANGE_TOKEN_ERROR().type,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    ));
                }
            }
            
        }
    }

    static logOut = (store: any) => (next: any) => (action: any) => {

        next(action);
        if (action.type === GET_LOG_OUT().type) {
            console.log("Logging out...");
            const {global, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/global-logout`,
                    null,
                    GET_LOGOUT_SUCCESS().type,
                    GET_LOGOUT_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token
                    }}
                ));
            } else {
                let loginUrl = join?.loginDetails?.shopOnly ? `${global.SHOP_URL}/login/` : `${global.COGNITO_URL}${window.location.origin}/account`;
                localStorage.removeItem('ssoUser');
                localStorage.removeItem('ssoecardId'); 
                store.dispatch(clearTokens());
                window.location.href = loginUrl;
            }
        }
    }

    static credentialChangeLogout = (store: any) => (next: any) => (action: any) => {

        next(action);
        if (action.type === GET_EMAIL_CHANGE_LOG_OUT().type) {
            console.log("Logging out...");
            const {global, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/global-logout`,
                    null,
                    GET_EMAIL_CHANGE_LOGOUT_SUCCESS().type,
                    GET_EMAIL_CHANGE_LOGOUT_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token
                    }}
                ));
            } else {
                let loginUrl = join?.loginDetails?.shopOnly ? `${global.SHOP_URL}/login/` : `${global.EP_ADMIN_URL}/login`;
                localStorage.removeItem('ssoUser');
                localStorage.removeItem('ssoecardId');
                store.dispatch(clearTokens());
                window.location.href = loginUrl;
            }
        }
    }

      

      static handleGetPaymentAddressForm = (store: any) => (next: any) => (action: any) => {
          next(action);
          if (action.type === GET_CARD_STREAM_FORM().type) {
              const { global, join } = store.getState();
              if(join.tokens &&  join.tokens.access_token) {
              store.dispatch(apiRequest(
                  "GET",
                  global.API_UI_END + '/rest/payment/process/payment/V2?amount=' + Math.ceil(action.payload * 100) + '&page=joinaccounts&plan=membership_yearly&cycleAmount=' + Math.ceil(action.payload * 100) +'&type=card&redirectUrl=' + window.location.origin,
                  null,
                  CARD_STREAM_FORM_SUCCESS().type,
                  CARD_STREAM_FORM_ERROR().type,
                  {
                    headers: {
                        'Authorization': join.tokens.access_token
                    }}
              ))
            }
          }
      };

    static processJoinInit = (story: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === JOIN_INIT_SUCCESS().type) {
            store.dispatch(updateJoinInitSuccess(true));
            // store.dispatch(updateJoinInitErr(false));

        }
        if (action.type === JOIN_INIT_ERROR().type) {
            store.dispatch(updateJoinInitErr(true));
            // store.dispatch(updateJoinInitSuccess(false));
        }
    }

    static processLogout = (store: any) => (next: any) => (action: any) => {
        next(action);
        const {global, join} = store.getState();
        if (action.type === GET_LOGOUT_SUCCESS().type) {
            let ssoLogoutUrl = global.SSO_LOGOUT_URL;
            let ssoData = global.SSO_USER;
            let isSsoUser = ssoData?.enabled;
            let loginUrl = join?.loginDetails?.shopOnly ? `${global.SHOP_URL}/login/` : `${global.COGNITO_URL}${window.location.origin}/account`;
            localStorage.removeItem('ssoUser');
            localStorage.removeItem('ssoecardId');
            store.dispatch(clearTokens());

            if(action.payload?.logOutUrl) {
                localStorage.setItem('customLogoutUrl', action.payload?.logOutUrl);
            }
            
            if(isSsoUser) {
                window.location.href = ssoLogoutUrl;
            } else {
                let customLogout = localStorage.getItem('customLogoutUrl');
                if(customLogout) {
                    localStorage.removeItem('customLogoutUrl');
                    window.location.href = customLogout;
                } else {
                    window.location.href = loginUrl;
                }  
            }
        }
    }

    static processCredentialChangeLogout = (store: any) => (next: any) => (action: any) => {
        next(action);
        const {global, join} = store.getState();
        if (action.type === GET_EMAIL_CHANGE_LOGOUT_SUCCESS().type) {
            let ssoLogoutUrl = global.SSO_LOGOUT_URL;
            let ssoData = global.SSO_USER;
            let isSsoUser = ssoData?.enabled;
            let loginUrl = join?.loginDetails?.shopOnly ? `${global.SHOP_URL}/login/` : `${global.EP_ADMIN_URL}/login`;
            localStorage.removeItem('ssoUser');
            localStorage.removeItem('ssoecardId');
            store.dispatch(clearTokens());
            
            if(action.payload?.logOutUrl) {
                localStorage.setItem('customLogoutUrl', action.payload?.logOutUrl);
            }
            
            if(isSsoUser) {
                window.location.href = ssoLogoutUrl;
            } else {
                let customLogout = localStorage.getItem('customLogoutUrl');
                if(customLogout) {
                    localStorage.removeItem('customLogoutUrl');
                    window.location.href = customLogout;
                } else {
                    window.location.href = loginUrl;
                }  
            }
        }
    }

    

     static processTokens = (store: any) => (next: any) => (action: any) => {
     next(action);
     if (action.type === GET_TOKENS_SUCCESS().type) {
     const {global} = store.getState();
         const tokens = action.payload;
         store.dispatch(updateTokens(tokens));
         store.dispatch(updateRefreshToken(tokens.refresh_token));
         const url = global.API_UI_END +'/rest/login/loginSuccess/V2';
         store.dispatch(apiRequest(
             'GET',
             global.API_UI_END + '/rest/login/loginSuccess/V2',
             null,
             GET_LOGIN_SUCCESS().type,
             GET_LOGIN_ERROR().type,
             {
                 headers: {
                     'Authorization': tokens.access_token,
                     'Content-Type': 'application/json',
                 }}
         ));
     }
     if (action.type === GET_TOKENS_ERROR().type) {
        const { global } = store.getState();
        const urlQuery = QS.parse(window.location.search);

        // fetching code failure so send status as loginError
        if(urlQuery?.code && window.location.pathname === '/seamless-login') {
            window.location.href = `${window.location.origin}${window.location.pathname}?status=loginError`;
        }        
    }
 }
      static processJoinGdprUpdate = (store: any) => (next: any) => (action: any) => {
          next(action);
          if (action.type === PUT_JOIN_GDPR_SUCCESS().type) {
               store.dispatch(updateGdpr(true));
               const { global, join } = store.getState();
               store.dispatch(apiRequest(
                  'GET',
                  global.API_UI_END + '/rest/login/loginSuccess/V2',
                  null,
                  GET_LOGIN_SUCCESS().type,
                  GET_LOGIN_ERROR().type,
                  {
                      headers: {
                          'Authorization': join.tokens.access_token,
                          'Content-Type': 'application/json',
                      }
                  }
              ));
          }
          if (action.type === PUT_GDPR_ERROR().type) {
            errNotify('Something went wrong. Please try again!')

            store.dispatch(updateGdpr(false));

            // get sso object from localStorage
            let {global} = store.getState();
            let ssoData = global.SSO_USER;
            let isSsoUser = ssoData?.enabled;
            let companyShortName = ssoData?.companyShortName;
            
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
      }

      static processSelfSeerviceUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_SEFLF_SERVICE_INFO_SUCCESS().type) {
             store.dispatch(updateSelfServiceInfo(action.payload));
        }
        if (action.type === GET_SEFLF_SERVICE_INFO_ERROR().type) {
          console.log("self service failed", action.payload)
     }
    }

      static processBusinessGdprUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === PUT_BUSINESS_GDPR_SUCCESS().type) {
            const { global, join } = store.getState();
             store.dispatch(updateBusinessGdpr(true));
             store.dispatch(apiRequest(
                'GET',
                global.API_UI_END + '/rest/login/loginSuccess/V2',
                null,
                GET_LOGIN_SUCCESS().type,
                GET_LOGIN_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }
                }
            ));
        }
        if (action.type === PUT_BUSINESS_GDPR_ERROR().type) {
            errNotify('Something went wrong. Please try again!')

            store.dispatch(updateBusinessGdpr(false));

            // get sso object from localStorage
            let {global} = store.getState();
            let ssoData = global.SSO_USER;
            let isSsoUser = ssoData?.enabled;
            let companyShortName = ssoData?.companyShortName;

            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
        
    }

    static processExchangeTokens = (store: any) => (next: any) => (action: any) => {
        next(action);
        const { global, join } = store.getState();
        if (action.type === GET_EXCHANGE_TOKEN_SUCCESS().type) {
            const newToken = action.payload;
            store.dispatch(updateExchangeTokens(newToken));
        }
        if (action.type === GET_EXCHANGE_TOKEN_ERROR().type) {
            let loginUrl = join?.loginDetails?.shopOnly ? `${global.SHOP_URL}/login/` : `${global.COGNITO_URL}${window.location.origin}/account`;
            setTimeout(() => {            
                localStorage.removeItem('ssoUser');
                localStorage.removeItem('ssoecardId'); 
                store.dispatch(clearTokens());

                const urlQuery = QS.parse(window.location.search);

                // fetching code failure so send status as loginError
                if(!urlQuery?.code) {
                    console.log('no code in the url so do normal redirection')
                    window.location.href = loginUrl;
                }                
            }, 2000);
        }
    }
      
    static processSubscriptionSubmit = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === SUBMIT_SUBSCRIPRION_SUCCESS().type) {
            store.dispatch(updateSubmitionRequest(true));
            const formData = action.payload;
            document.getElementById('submitForm').submit();
        }
    };

      static processPaymentForm = (store: any) => (next: any) => (action: any) => {
          next(action);
          if (action.type === CARD_STREAM_FORM_SUCCESS().type) {
              const formData = action.payload;
              store.dispatch(updateCardStreamForm(formData))
          }
      };

      static processLoginUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_LOGIN_SUCCESS().type) {
            store.dispatch(updateLoginDetails(action.payload));

            const urlQuery = QS.parse(window.location.search);

            // fetching code failure so send status as loginError
            if(urlQuery?.code && window.location.pathname === '/seamless-login') {
                console.log('login success')
                window.location.href = `${window.location.origin}${urlQuery?.redirectURL ? urlQuery?.redirectURL : '/account'}`;
            }
        }
    }


      @Action(UPDATE_GDPR())
      updateGdpr(state: IReducerJoinState, action: any) {
          return {
              ...state,
              gdprSuccess: action.payload,
          };
      }

      @Action(UPDATE_SUBMIT_SUBSCRIPRION())
      updateSubmitionRequest(state: IReducerJoinState, action: any) {
          return {
              ...state,
              submitionRequest: true
          };
      }

      @Action(UPDATE_BUSINESS_GDPR())
      updateBusinessGdpr(state: IReducerJoinState, action: any) {
          return {
              ...state,
              gdprBusinessSuccess: action.payload,
          };
      }

      // hardcoded to test this data usage
      @Action(UPDATE_LOGIN_DETAILS())
      updateLoginDetails(state: IReducerJoinState, action: any) {
          return {
              ...state,
              loginDetails: {...action.payload},
          };
      }

     @Action(UPDATE_JOIN_INIT_ERROR())
     updateJoinInitErr(state: IReducerJoinState, action: any) {
          return {
               ...state,
               joinError: action.payload
          }
     }

     @Action(UPDATE_JOIN_INIT_SUCCESS())
     updateJoinInitSuccess(state: IReducerJoinState, action: any) {
          return {
               ...state,
               joinSuccesss: action.payload
          }
     }

     @Action(UPDATE_JOIN_STEP())
     updateJoinStep(state: IReducerJoinState, action: any) {
         return {
             ...state,
             currentStep: action.payload
         }
     }

     @Action(UPDATE_JOIN_SUCESS_MESSAGE())
     updateJoinSuccessMessage(state: IReducerJoinState, action: any) {
         return {
             ...state,
             joinSuccesss: action.payload
         }
     }

     @Action(UPDATE_DONATION_VALUE())
     updateDonationValue(state: IReducerJoinState, action: any) {
         return {
             ...state,
             donateValue: action.payload
         }
     }

     @Action(UPDATE_JOIN_ERR_MSG())
     updateJoinErrMsg(state: IReducerJoinState, action: any) {
         return {
             ...state,
             joinError: action.payload
         }
     }
     
     @Action(UPDATE_EXCHANGE_TOKENS())
     updateExchangeTokens(state: IReducerJoinState, action: any) {
         return {
             ...state,
             tokens: action.payload,
         };
     }

     @Action(UPDATE_TOKENS())
     updateTokens(state: IReducerJoinState, action: any) {
         return {
             ...state,
             tokens: action.payload,
         };
     }
     @Action(UPDATE_REFRESH_TOKEN())
     updateRefreshToken(state: IReducerJoinState, action: any) {
         return {
             ...state,
             refreshToken : action.payload
         };
     }

     @Action(UPDATE_CARD_STREAM_FORM())
     updateCardStreamForm(state: IReducerJoinState, action: any) {
         return {
             ...state,
             formData: action.payload
         }
     }


     @Action(UPDATE_SEFLF_SERVICE_INFO())
     updateSelfServiceInfo(state: IReducerJoinState, action: any) {
         return {
             ...state,
             selfServiceInfo: action.payload
         }
     }

     @Action(CLEAR_TOKENS())
     clearTokens(state: IReducerJoinState, action: any) {
         return {
             ...state,
             tokens: {},
             refreshToken : '',
             loginDetails : {},
             selfServiceInfo : {}
         }
     }
 
}


export default Join.create();

export const JoinMdl = [Join.handleJoinInit,  Join.getTokens, Join.getSelfServiceInfo, Join.getExchangeToken, Join.logOut, Join.credentialChangeLogout, Join.submitSubscription, Join.processJoinInit,Join.processSubscriptionSubmit, Join.handleGetPaymentAddressForm, Join.handleGetPaymentAddressForm, Join.gdprJoinUpdate, Join.gdprBusinessUpdate, Join.processTokens, Join.processLogout, Join.processCredentialChangeLogout, Join.processJoinGdprUpdate, Join.processBusinessGdprUpdate, Join.processPaymentForm, Join.processExchangeTokens, Join.processLoginUpdate, Join.processSelfSeerviceUpdate];
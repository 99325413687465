import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   */
  width?: number;
  /**
   * Svg's height
   */
  height?: number;
  /**
   * Svg's stroke
   */
  stroke?: string;
  /**
   * Svg's fill
   */
  fill?: string;
  /**
   * Svg's classNames
   */
  className?: string;
  /**
   * Svg's style
   */
  style?: CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const ArrowBack = ({
  width,
  height,
  stroke,
  fill,
  className,
  style,
  onMouseEnter,
  onMouseLeave
}: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    style={style}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <g fill="none" fillRule="evenodd">
      <path fill={fill} d="M0 0L13.5 13.5" transform="translate(1 1)" />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2"
        d="M0 0L13.5 13.5"
        transform="translate(1 1)"
      />
      <path fill={fill} d="M0 27L13.5 13.5" transform="translate(1 1)" />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2"
        d="M0 27L13.5 13.5"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

ArrowBack.defaultProps = {
  width: '16',
  height: '29',
  stroke: '#202222',
  fill: '#FFF',
  className: ''
};

export default ArrowBack;

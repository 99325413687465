import React, { useState, useEffect }  from 'react';
import { connect } from 'react-redux';
import BreadCrumbs from "../ui-componets/breadcrumbs";
import { getTokens } from "../modules/join.ducks";
import { getGdpr } from "../modules/ui.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import Back from "../ui-componets/Back";
import { useTranslation } from 'react-i18next';



interface IProps {
    getTokens: any
    history: any
    tokenDetails: any
    global: any
    getGdpr: any
    isMarketingDetailsFetching: any
    shopUser?: boolean
    dieselCardUser?: boolean
}

function Payment(props: IProps) {
  const { t } = useTranslation();
  // get sso object from localStorage
  let ssoData = props.global.SSO_USER;
  let isSsoUser = ssoData?.enabled;
  let companyShortName = ssoData?.companyShortName;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Query params in Payments page", QS.parse(window.location.search));
    // setTimeout(() => {
    if(QS.parse(window.location.search).code && !isSsoUser){
     // since fetching token in this component can be overridden by other functions and give unexpected result
     props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/payments`);
    } else if (QS.parse(window.location.search).code && isSsoUser) {
      console.log("QS.parse(window.location.search).code && isSsoUser");
      props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/payments`, companyShortName: companyShortName });
    }else if(!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token){
      window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/payments`
    }else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
      console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
      window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/payments?companyShortName=${companyShortName}`
  }
// }, 2000);
    }, []);

    const { getGdpr, isMarketingDetailsFetching } = props;
    
    useEffect(() => {
      getGdpr();
  }, [props.tokenDetails]);

    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Payment', className: 'child-breadcrumbs font-styleLight', content: t('Payment Details'), link: false },
    ]

    return (
      <div>
      {  isMarketingDetailsFetching || isMarketingDetailsFetching === undefined ? 
    <div>
        <Loader/>
    </div>:
        <div className="static-donate-payment">
          <div className="sdp-binder sdp-binder-mobile-donate">
              {
                  props.shopUser && <div className="dropdown-for-activity dropdown-for-order pt-0 pl-5">
                      <Back
                      refreshToken={props.tokenDetails.refresh_token}
                      dieselCardUser={props.dieselCardUser}/>
                  </div>
              }
                <div className="sdp-head">

                    <div className="sdp-sub-head">
                          <img src="/static/payment-deatils.svg" />
                          <p className="m-0 font-styleBold black-for-accounts pd-t-black-ac">{t('Payment Details')}</p>
                          <div className="breads">
                            {sections.length > 0 && !props.shopUser? <BreadCrumbs
                            sections={sections}
                            handleClick={(url: string) => props.history.push(url)}
                            backAction={() => props.history.goBack()} />
                        : null}
                        </div>

                        <div className="options-payment-text">
                            <p className="m-0 font-styleRegular black-for-ac">{t('If you require extra Epoints to complete your purchase you can buy more using the below payment options.')}</p>
                        </div>

                          <div className="payment-st-images">
                             <div className="sdp-sub-img">
                                <div className="sdp-img">
                                  <img src="/static/paypal.svg" />
                                </div>  
                                <div className="sdp-img visa-img">
                                  <img src="/static/visa.svg" />
                                </div>  
                                <div className="sdp-img">
                                  <img src="/static/master-card.svg" />
                                </div>    
                             </div>      
                          </div>      

                    </div>
                </div>
            </div>
        </div>
}
</div>
    );
}

const mapStateToProps = (state: any) => ({
  tokenDetails: state.join.tokens,
  global: state.global,
  isMarketingDetailsFetching: state.ui.isMarketingDetailsFetching

});

export default connect(mapStateToProps, {getTokens, getGdpr})(Payment);

import React from 'react';
import cx from 'classnames';
import { IoIosCheckmark } from 'react-icons/io';
import AnchorTagCheck from '../anchor-tag-check';
import './style.css';

interface IProps {
  /**
   * The steps to be displayed
   */
  steps: string[];
  /**
   * The current index for the selected step. Starts from 0
   */
  current: number;
  /**
   * Change colour of Stepper. There's currently 2 types: 'checkout', and 'member'
   *
   * @default 'checkout'
   **/
  type?: 'checkout' | 'member';

  /**
   * The steps to be clickable
   */
  relatedUrl?: string[];
}
interface ILabelProps {
  /**
   * The current index for the selected step. Starts from 0
   */
  current: number;
  /**
   * The text to be displayed under the step
   */
  text: string;
  /**
   * The label's current index
   */
  index: number;

  className?: string;
}
const Label = ({ className, current, text, index }: ILabelProps) => (
  <span
    className={cx('absolute font-differences', className, {
      'font-semibold font-weights-stepper-spans': current === index
    })}
    style={{
      bottom: 'calc(-100% - 16px)',
      right: '50%',
      transform: 'translateX(50%)',
      transition: 'all 0.3s ease-out 0.3s'
    }}
  >
    {text}
  </span>
);

function Stepper(props: IProps) {
  const { type } = props;
  const [first, ...rest] = props.steps;
  const lastStep = props.steps.length;
  let borderColor: string = 'yellow';
  let bgColor: string = 'yellow';

  if (type === 'member') {
    borderColor = 'green';
    bgColor = 'white';
  }
  return (
    <div className={cx('flex flex-row items-center w-full stepper-changess')}>
      <div className={cx('flex flex-col items-center justify-center')}>
        <AnchorTagCheck url={props.relatedUrl && props.relatedUrl[0]}>
          <div
            automation-id="stepper-circle-1"
            className={cx(
              `bg-${bgColor} border-2 border-${borderColor} rounded-full w-0 h-0 relative stepper-circle-padding-changes`
            )}
          >
            {type === 'member' && props.current > 0 && (
              <IoIosCheckmark
                automation-id="stepper-tick-1"
                className={`absolute pin-t pin-b pin-l pin-r text-${borderColor} text-lg m-auto h-6 w-6`}
              />
            )}
            <Label
              className="stepper-child-fixes"
              text={first}
              current={props.current}
              index={0}
            />
          </div>
        </AnchorTagCheck>
      </div>
      {rest.map((step, i) => {
        const isCurrent = props.current === i + 1;
        const isBeforeCurrent = props.current > i + 1;
        return (
          <div className={cx('flex flex-1 items-center justify-end')} key={i}>
            <div
              className={cx('flex-1 border-t-1 relative border-color-changes')}
            >
              <div
                className={cx(`absolute border-t-1 w-full max-w-0`, {
                  'max-w-full': isCurrent || isBeforeCurrent,
                  'border-green-remove': !isCurrent && !isBeforeCurrent,
                  'border-green':
                    type === 'member' && (isCurrent || isBeforeCurrent),
                  'border-green-removed':
                    type === 'checkout' && (isCurrent || isBeforeCurrent)
                })}
                style={{
                  top: -2,
                  left: 0,
                  bottom: 0,
                  transition: 'all 0.3s ease-out'
                }}
              />
            </div>
            <AnchorTagCheck url={props.relatedUrl && props.relatedUrl[i + 1]}>
              <div
                automation-id={`stepper-circle-${i + 2}`}
                className={cx(
                  `border-1 rounded-full w-0 h-0 relative stepper-circle-padding-changes`,
                  {
                    'border-slight-grey': !isCurrent && !isBeforeCurrent,
                    'border-green':
                      type === 'member' && (isCurrent || isBeforeCurrent),
                    'bg-white':
                      type === 'member' && (isCurrent || isBeforeCurrent),
                    'border-yellow':
                      type === 'checkout' && (isCurrent || isBeforeCurrent),
                    'bg-yellow':
                      type === 'checkout' && (isCurrent || isBeforeCurrent)
                  }
                )}
                style={{
                  transition: 'all 0.3s ease-out 0.3s'
                }}
              >
                {type === 'member' && isBeforeCurrent && (
                  <IoIosCheckmark
                    automation-id={`stepper-tick-${i + 2}`}
                    className={`absolute pin-t pin-b pin-l pin-r text-${borderColor} text-lg m-auto h-6 w-6`}
                  />
                )}
                <Label
                  text={step}
                  current={props.current}
                  index={i + 1}
                  className={cx({
                    'stepper-parent-fixes': !isCurrent && !isBeforeCurrent,
                    'stepper-last-children': lastStep === i + 2,
                    'stepper-child-fixes': isBeforeCurrent
                  })}
                />
              </div>
            </AnchorTagCheck>
          </div>
        );
      })}
    </div>
  );
}

// Set default props
Stepper.defaultProps = {
  type: 'checkout'
};

export default Stepper;

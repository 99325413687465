import React, { useState } from 'react';
import { Radio } from 'semantic-ui-react';
import './styles.css';
import { useTranslation } from 'react-i18next';

interface accountsMarketingProps {
  relativePath?: string;
  optForEmail: boolean;
  optForSms: boolean;
  updateGdpr: (email: any, userInteractedForEmailSubscription: any, sms: any) => void;
}

function AccountsMarketingPreferences(props: accountsMarketingProps) {
  const { t } = useTranslation();
  const [isOptForEmail, setOptForEmail] = useState(props.optForEmail);
  const [isOptForSms, setOptForSms] = useState(props.optForSms);

  const onToggleSmsChange = () => {
    setOptForSms(!isOptForSms);
    props.updateGdpr(isOptForEmail, null, !isOptForSms);
  };

  const onToggleEmailChange = () => {
    setOptForEmail(!isOptForEmail);
    props.updateGdpr(!isOptForEmail, true, isOptForSms);
  };

  return (
    <div className="AccountsMarketingPreferences">
      <div className="AccountsMarketingPreferences-binder">
        <div className="toogle-as-text-binder-ac">
          <div className="email-smas-agreed-messages">
            <p className="m-0 font-styleRegular">
              {t('I agree to receive promotional messages from Epoints and Each Person by email.')}
            </p>
          </div>
          <div className="marketing-radio-div">
            <div className="marketing-radio-sub-div">
              <Radio
                toggle
                name="optForEmail"
                checked={isOptForEmail}
                onChange={onToggleEmailChange}
              />
            </div>
          </div>
        </div>

        <div className="toogle-as-text-binder-ac pd-top">
          <div className="email-smas-agreed-messages">
            <p className="m-0 font-styleRegular">
              {t('I agree to receive promotional messages from Epoints and Each Person by SMS.')}
            </p>
          </div>
          <div className="marketing-radio-div">
            <div className="marketing-radio-sub-div">
              <Radio
                toggle
                name="optForSms"
                checked={isOptForSms}
                onChange={onToggleSmsChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Set default props
AccountsMarketingPreferences.defaultProps = {
  relativePath: ''
};

export default AccountsMarketingPreferences;

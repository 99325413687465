import React from 'react';

interface IProps {
  /**
   * Svg's height
   * @default 22
   */
  height?: string;

  /**
   * Svg's width
   * @default 13
   */
  width?: string;

  /**
   * Svg's top Triangle fill
   * @default '#CFCFCF'
   */
  topTrianglefill?: string;

  /**
   * Svg's bottom Triangle fill
   * @default '#4A4A4A'
   */
  bottomTrianglefill?: string;

  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
}

const Triangle = ({
  width,
  height,
  className,
  topTrianglefill,
  bottomTrianglefill
}: IProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 13"
    version="1.1"
    className={className}
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Components-/-Navigation-/-Hamburger-Menu-Expanded-2"
        transform="translate(-96.000000, -288.000000)"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(96.000000, 288.000000)">
          <polygon
            id="Triangle"
            fill={topTrianglefill}
            transform="translate(11.000000, 7.000000) rotate(180.000000) translate(-11.000000, -7.000000) "
            points="11 1 22 13 0 13"
          />
          <polygon
            id="Triangle"
            fill={bottomTrianglefill}
            transform="translate(11.000000, 5.000000) rotate(180.000000) translate(-11.000000, -5.000000) "
            points="11 0 21 10 1 10"
          />
        </g>
      </g>
    </g>
  </svg>
);

Triangle.defaultProps = {
  width: 22,
  height: 13,
  className: '',
  topTrianglefill: '#CFCFCF',
  bottomTrianglefill: '#4A4A4A'
};

export default Triangle;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { credentialChangeLogout } from '../modules/join.ducks';

import Loader from '../ui-componets/loader';


interface IProps {
  credentialChangeLogout:any
}

function ChangeEmail(props: IProps) {
  const {  credentialChangeLogout } = props;

  useEffect(() => {
    credentialChangeLogout();
}, []);

  return (
   
  <div>
      <Loader/>
  </div>

  );
}

const mapStateToProps = (state: any) => ({
  ui: state.ui
});
export default connect(mapStateToProps, {credentialChangeLogout})(ChangeEmail);

import React, { Component, useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import Button from '../../ui-componets/button';
import {updateJoinStep, getCardStreamForm, submitSubscription, updateGdpr} from '../../modules/join.ducks'
import QS from "query-string";
import Loader from '../../ui-componets/loader';

declare var document: any;

interface IProps {
    history: any
    updateJoinStep: any
    getCardStreamForm:any
    formData: any
    location: any
    submitSubscription:any
    isRequestSubmited : any
    updateGdpr: any
}

function JoinPricing(props: IProps) {
    const [ error, setError] = useState(false)
    const {location, updateGdpr} = props
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        updateGdpr(false);
        props.updateJoinStep(1);
        props.getCardStreamForm(24);
        setTimeout(() => {
            setIsLoader(false);
            window.scrollTo(0, 0);
          }, 1000);
    }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
      if ((QS.parse(location.search).status) && (QS.parse(location.search).status === 'error')) {
        setError(true);
    } else {
        setError(false);
    }
    }, []);

    const hanldePayment = () => {
        props.submitSubscription();
        // document.getElementById('frm').submit();
    };


    return (
        <div>
        { isLoader?
      <div>
          <Loader/>
      </div>:
        <div className="join-pricing">
            <div className="join-pricing-sub-binder">
            <div className="join-box">
                    <div className="join-sub-box">
                        <div className="join-box-header">
                            <div className="join-box-sub-head">
                                <p className="text-black-primaryone font-styleBold size-big-join text-center">Total Cost</p>
                            </div>
                        </div>
                        <div className="join-box-body">
                            <div className="join-box-body-sub">
                                <div className="join-pricing-details">
                                    <div className="join-pricing-details-flex">
                                        <div className="text-prcing">
                                            <p className="font-styleRegular text-black-primaryone size-small-join">Annual Cost</p>
                                        </div>
                                        <div className="text-prcing">
                                            <p className="font-styleRegular text-black-primaryone size-small-join">£24.00</p>
                                        </div>
                                    </div>
                                    <div className="join-pricing-details-flex">
                                        <div className="text-prcing">
                                            <p className="font-styleRegular text-black-primaryone size-small-join">One-off Joining Fee</p>
                                        </div>
                                        <div className="text-prcing">
                                            <p className="font-styleRegular text-black-primaryone size-small-join">£100.00</p>
                                        </div>
                                    </div>
                                    <div className="border-underline"></div>
                                    <div className="join-pricing-details-flex">
                                        <div className="text-prcing">
                                            <p className="font-styleBold text-black-primaryone size-small-join">Total</p>
                                        </div>
                                        <div className="text-prcing">
                                            <p className="font-styleBold text-black-primaryone size-small-join">£124.00</p>
                                        </div>
                                    </div>
                                 {error && <div className="addres-errors-msgs max-width-for-payment-err">
                                                <div className="adres-err">
                                                    <p className="text-center">Please, try again</p>
                                                </div>
                                                </div>}
                                    <div className="join-pricing-sumbit">
                                    <div className="join-btn text-center">
                                        <Button
                                        width={247}
                                        
                                        label="Continue to payment"
                                        onClick={() => hanldePayment()}
                                        type="primary"
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form id="submitForm" action="https://gateway.cardstream.com/paymentform/epoints/?responsive=1" method="post">
                    {
                        props.formData ? Object.keys(props.formData).map((item, i) => (

                            <input type="hidden" key={i} name={item} value={props.formData[item]}/>

                        )) : null
                    }

                    </form>
        </div>
            }
            </div>
    );
}
const mapStateToProps = (state: any) => ({
    formData : state.join.formData.mapRequest,
    isRequestSubmited : state.join.submitionRequest
});
  
export default connect(mapStateToProps, {updateJoinStep, getCardStreamForm, submitSubscription, updateGdpr})(JoinPricing);

import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 44
   */
  width: string;
  /**
   * Svg's height
   * @default 12
   */
  height: string;
  /**
   * Svg's fill
   * @default '#4A4A4A'
   */
  fill: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
  /**
   * Svg's style
   * @default ''
   */
  style?: CSSProperties;
}

const BlogIcon = ({ width, height, fill, className, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    className={className}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
  >
    <defs>
      <path
        id="prefix__a"
        d="M0.756 0.202L11.625 0.202 11.625 11.999 0.756 11.999z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M7.203 9.132c0-.381-.146-.682-.439-.904-.294-.223-.767-.335-1.418-.335H2.444v2.51h2.983c.553 0 .988-.101 1.303-.302.315-.201.473-.513.473-.937v-.032zm-.636-4.563c0-.38-.14-.67-.424-.873-.282-.2-.689-.3-1.22-.3H2.443v2.411H4.76c.553 0 .993-.095 1.32-.284.325-.191.488-.498.488-.921v-.033zm2.812 6.274c-.2.392-.488.717-.863.977-.374.261-.824.458-1.349.587-.526.13-1.11.196-1.75.196H0V1.195h5.287c1.171 0 2.09.266 2.757.798.667.534 1.001 1.25 1.001 2.152v.033c0 .325-.04.613-.122.863-.08.25-.19.476-.324.677-.135.201-.292.377-.469.529-.179.153-.37.283-.574.392.66.249 1.177.589 1.556 1.017.378.43.568 1.025.568 1.786v.032c0 .521-.1.977-.3 1.369zM10.95 12.603L10.95 1.196 13.46 1.196 13.46 10.337 19.148 10.337 19.148 12.603zM29.22 6.899c0-.488-.085-.95-.253-1.385-.169-.434-.408-.815-.718-1.14-.309-.327-.676-.584-1.099-.775-.424-.19-.886-.285-1.385-.285-.51 0-.976.093-1.394.277-.418.185-.777.44-1.076.766-.299.326-.532.703-.7 1.133-.168.43-.253.888-.253 1.377v.032c0 .489.085.95.253 1.386.168.434.407.814.717 1.14.31.327.674.584 1.092.775.42.189.882.285 1.393.285.5 0 .962-.093 1.386-.277.424-.185.784-.44 1.083-.766.3-.326.532-.703.701-1.133.168-.43.252-.888.252-1.377v-.033zm2.166 2.29c-.304.71-.725 1.336-1.262 1.874-.538.538-1.179.962-1.924 1.272-.744.309-1.555.463-2.435.463-.88 0-1.69-.154-2.429-.463-.739-.31-1.377-.73-1.915-1.255-.537-.528-.956-1.147-1.255-1.86-.299-.71-.448-1.473-.448-2.288v-.033c0-.814.152-1.577.457-2.29.304-.71.725-1.336 1.263-1.874.538-.537 1.178-.961 1.923-1.271.744-.31 1.556-.463 2.436-.463.88 0 1.69.154 2.43.463.737.31 1.375.729 1.913 1.255.538.528.956 1.147 1.255 1.858.3.712.448 1.474.448 2.29v.032c0 .815-.152 1.578-.457 2.29z"
        transform="translate(0 -1)"
      />
      <g transform="translate(0 -1) translate(32 .798)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          fill={fill}
          d="M9.532 11.47c-.811.354-1.736.53-2.78.53-.89 0-1.701-.147-2.435-.44-.733-.294-1.364-.7-1.89-1.223-.528-.52-.938-1.14-1.231-1.857-.294-.718-.44-1.5-.44-2.347V6.1c0-.815.149-1.577.448-2.289.299-.71.714-1.337 1.247-1.875.532-.537 1.162-.96 1.89-1.27C5.069.355 5.867.2 6.736.2c.51 0 .976.036 1.394.106.418.07.806.171 1.165.302.359.13.696.293 1.01.488.315.196.62.42.913.668L9.637 3.672c-.217-.184-.434-.348-.652-.489-.217-.14-.442-.26-.676-.359-.233-.097-.486-.173-.757-.227-.272-.055-.571-.082-.897-.082-.456 0-.883.096-1.28.286-.396.19-.743.446-1.042.768-.3.321-.532.697-.7 1.127-.17.43-.253.89-.253 1.38v.033c0 .522.084 1.004.253 1.445.168.44.406.825.716 1.151.31.327.673.58 1.092.76.418.18.883.27 1.394.27.933 0 1.72-.23 2.362-.69v-1.64H6.672V5.236h4.954v4.954c-.587.5-1.285.925-2.094 1.28"
          mask="url(#prefix__b)"
        />
      </g>
    </g>
  </svg>
);

BlogIcon.defaultProps = {
  width: '44',
  height: '12',
  stroke: '#FF726F',
  fill: '#4A4A4A',
  className: ''
};

export default BlogIcon;

import React from 'react';
import { Breadcrumb as Bc } from 'semantic-ui-react';
import './styles.css';

export interface IProps {
  /**
   *  Callback function for Back button
   */
  backAction: () => void;
  /**
   *  Callback function for click on any Link
   */
  handleClick: (url: string) => void;
  /**
   *  Shorthand array of props for Breadcrumb.Section.
   */
  sections: any[];
  /**
   *  Additional classes.
   */
  className?: string;
  removeLine?: any;
}

function Breadcrumb(props: IProps) {
  let breadCrumbSections: any = props.sections;
  const { className } = props;
  breadCrumbSections = breadCrumbSections.map((s: any) => {
    if (s.link && s.href) s.link = undefined;
    s.onClick = (e: any) => {
      e.preventDefault();
      props.handleClick(s.href);
    };
    return s;
  });
  breadCrumbSections.splice(0, 0, {
    content: `< Back ${!props.removeLine ? `|` : ``} `,
    key: 'back',
    link: true,
    onClick: props.backAction,
    className: 'go-back'
  });
  return (
    <>
      <Bc
        icon="right angle"
        sections={breadCrumbSections}
        className={`font-styleRegular ${className ? className : ''}`}
      />
    </>
  );
}

export default Breadcrumb;

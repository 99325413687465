import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's height
   * @default 24
   */
  height?: string;
  /**
   * Svg's width
   * @default 24
   */
  width?: string;
  /**
   * Svg's fill
   * @default '#4A4A4A'
   */
  fill?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
  /**
   * Svg's style
   * @default ''
   */
  style?: CSSProperties;
}

const InstagramIcon = ({ width, height, fill, className, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    style={style}
  >
    <defs>
      <path id="prefix__a" d="M0 0L24 0 24 24 0 24z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M12.164 15.59c-2.067 0-3.75-1.685-3.75-3.75 0-2.069 1.683-3.75 3.75-3.75 2.066 0 3.75 1.681 3.75 3.75 0 2.065-1.684 3.75-3.75 3.75m0-9.75c-3.313 0-6 2.686-6 6 0 3.312 2.687 6 6 6s6-2.688 6-6c0-3.314-2.687-6-6-6M18.685 4.126c.658 0 1.191.533 1.191 1.191 0 .66-.533 1.192-1.19 1.192-.659 0-1.192-.533-1.192-1.192 0-.658.533-1.19 1.191-1.19"
      />
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        fill={fill}
        d="M9.937 22.594H3.515c-1.163 0-2.109-.946-2.109-2.109V3.516c0-1.163.946-2.109 2.11-2.109h16.968c1.163 0 2.11.946 2.11 2.11v16.968c0 1.163-.947 2.11-2.11 2.11h-4.922M14.156 24h6.328C22.422 24 24 22.423 24 20.485V3.516C24 1.577 22.422 0 20.484 0H3.515C1.577 0 0 1.577 0 3.516v16.97C0 22.422 1.577 24 3.515 24h7.828"
        mask="url(#prefix__b)"
      />
      <path
        fill={fill}
        d="M10.036 22.087H3.922c-1.108 0-2.01-.9-2.01-2.009V3.921c0-1.107.902-2.009 2.01-2.009h16.157c1.107 0 2.009.902 2.009 2.01v16.156c0 1.108-.902 2.01-2.01 2.01h-4.686m-1.338 1.338h6.025c1.846 0 3.348-1.502 3.348-3.348V3.921c0-1.846-1.502-3.348-3.348-3.348H3.922C2.076.573.574 2.075.574 3.921v16.157c0 1.846 1.502 3.348 3.348 3.348h7.453"
      />
    </g>
  </svg>
);

InstagramIcon.defaultProps = {
  width: '24',
  height: '24',
  fill: '#4A4A4A',
  className: ''
};

export default InstagramIcon;

import React, { useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import Button from '../../ui-componets/button';
import {updateJoinStep, getCode } from '../../modules/join.ducks'
import Loader from '../../ui-componets/loader';
import { store } from '../../redux/store';
import { useTranslation } from 'react-i18next';


interface IProps {
    history: any
    updateJoinStep: any
    loginDetails: any
    tokenDetails: any
}

function JoinSuccess(props: IProps) {
    const { t } = useTranslation();
    const [isLoader, setIsLoader] = useState(true);
    const [fetchingCode, updateFetchingCode] = useState(false);
    const{loginDetails} = props
    useEffect(() => {
        props.updateJoinStep(3);
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsLoader(false);
            window.scrollTo(0, 0);
          }, 1000);
    }, []);
    
    const redirectingDomainSpecificShop = async() => {
        const { global } = store.getState();
        // get sso object from localStorage
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
          
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false)
                window.location.href = '';
            }
        }
        
    }
    return (
        <div>
        { isLoader || fetchingCode ?
      <div>
          <Loader/>
      </div>:
        <div className="join-success">
        <div className="join-success-sub-binder">
        <div className="join-box">
                <div className="join-sub-box">
                    <div className="join-box-header">
                        <div className="join-box-sub-head">
                            <p className="text-black-primaryone font-styleBold size-big-join text-center">Confirmation</p>
                        </div>
                    </div>
                    <div className="join-box-body">
                        <div className="join-box-body-sub">
                            <div className="join-success-details">
                                <div className="join-success-imge">
                                <img src="/static/success-joins.svg" />
                                </div>
                                <div className="bold-text-sc">
                                    <p className="text-black-primaryone text-center font-styleBold size-big-join">{t('Success')}</p>
                                    <p className="font-styleRegular text-center text-black-primaryone size-small-join pb-name">{t('Welcome')} {props.loginDetails.firstName}!</p>
                                </div>
                                    <div className="grey-small-box">
                                        <p className="font-styleBold text-center text-black-primaryone size-small-join">{t('Please check your inbox for a confirmation email.')}</p>
                                    </div>
                                <div className="join-pricing-sumbit">
                                <div className="join-btn text-center">
                                    <Button
                                    width={247}
                                    
                                    label={t("Start shopping!")}
                                    onClick={redirectingDomainSpecificShop}
                                    type="primary"
                                    disabled={false}
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    </div>
    );
}
const mapStateToProps = (state: any) => ({
    history: '',
    loginDetails : state.join.loginDetails,
    tokenDetails: state.join.tokens,
});
export default connect(mapStateToProps, {updateJoinStep})(JoinSuccess);

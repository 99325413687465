import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProfileData } from '../../modules/details.ducks';
import AccountsEcardPrintable from '../../ui-componets/accounts-ecard-printable';
import { getPrintableCard, getProductStatus, getConfigData, updateEcardId } from '../../modules/details.ducks';
import { getAddressList} from "../../modules/addresses.ducks";
import Button from '../../ui-componets/button';
import EpointsPrice from "../../ui-componets/epoints-price";
import QS from "query-string";
import Moment from 'react-moment';
import { getTokens } from "../../modules/join.ducks";
import Loader from '../../ui-componets/loader';
import { useTranslation } from 'react-i18next';


interface IProps {
    history: any
    printableId: any;
    getPrintableCard: any
    printableData: any
    getProfileData: any
    getAddressList: any
    addressList: any
    getProductStatus: any
    ecardStatus: any
    ecardRedirectId: any
    getConfigData: any
    configResponse: any
    updateEcardId: any
    getTokens: any
    tokenDetails:any
    global:any
    isEcardFetching: any
}

function EcardsPrint(props: IProps) {
    const { getPrintableCard, printableData, getProfileData, getAddressList, addressList, getProductStatus, ecardStatus, ecardRedirectId, getConfigData, configResponse, updateEcardId, isEcardFetching } = props;
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
        getProfileData();
        getAddressList();
        getConfigData();
    }, []);

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;

    useEffect(() => {
        console.log("Query params in Ecard print", QS.parse(window.location.search));
        console.log("use effect", companyShortName);
        // setTimeout(() => {
        if (QS.parse(window.location.search).code && !isSsoUser) {
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: '/my-account/ecards/ecard' });
        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/my-account/ecards/ecard`, companyShortName: companyShortName });
        } else if (!isSsoUser &&props.tokenDetails && !props.tokenDetails.access_token) {
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/my-account/ecards/ecard&state=${QS.parse(window.location.search).id}`
        }
        else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token", companyShortName);
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/my-account/ecards/ecard?companyShortName=${companyShortName}`
        }
    // }, 2000);
    }, []);

    useEffect(() => {
        handleId();
    }, [props.tokenDetails]);

    const handleId = () =>{
        let ssoEcardId = localStorage.getItem('ssoecardId');
        console.log("SSO USER and ECARDF ID", isSsoUser, ssoEcardId);
        const ecardId = QS.parse(window.location.search).id
        if(QS.parse(window.location.search).id){
            getPrintableCard(ecardId);
            getProductStatus(ecardId);
        } else if(QS.parse(window.location.search).state){
            let id= QS.parse(window.location.search).state
            getPrintableCard(id);
            getProductStatus(id);
        } else if(isSsoUser && ssoEcardId){
            console.log("SSO ecard ID get print card");
            getPrintableCard(ssoEcardId);
            getProductStatus(ssoEcardId);
        }
    }

    const handleClaimReward = () =>{
        let ssoEcardId = localStorage.getItem('ssoecardId');
        let ecardId ;
        if(QS.parse(window.location.search).id){
            ecardId = QS.parse(window.location.search).id;
        } else if(QS.parse(window.location.search).state){
            ecardId = QS.parse(window.location.search).state
        } else if(isSsoUser && ssoEcardId){
            ecardId = ssoEcardId;
        }
        console.log("claiming ecard id", ecardId);
        // const ecardId = QS.parse(window.location.search).id ? QS.parse(window.location.search).id : QS.parse(window.location.search).state

        switch (ecardStatus.action) {
            case 'REDEEMABLE':
                props.history.push(`/my-account/ecards/getreward/?id=${ecardId}`);
                break;
            case 'ORDER_FULFILLED':
                props.history.push(`/my-account/ecards/getreward/?id=${ecardId}`);
                break;
            case 'POINTS_EXCHANGED':
                props.history.push(`/my-account/ecards/getreward/?id=${ecardId}`);
                break;
            case 'EXCHANGE_FOR_POINTS':
                props.history.push(`/my-account/ecards/getreward/?id=${ecardId}`);
                break;
            default:
        }
    }

    const handlePrint = () => {
        window.print();
    }
    
    return (
        <div>
        { isEcardFetching || isEcardFetching === undefined ?
      <div>
          <Loader/>
      </div>:
        <div id="test" className="ecards-prints">
            <div className="ecards-print-binder">
                {printableData &&
                    <div className="prinatable-data-biunder">
                        <div className="flex-total-print">
                            <div className="printable-head">
                                <div className="flexing-print">
                                    <p className="time-print font-styleRegular m-0">
                                    <Moment fromNow>{printableData.createdAt}</Moment>
                                        </p>
                                </div>
                                <div className="flexing-print-from">
                                    <p className="textprint font-styleBold m-0 font-16">{t('from')} {printableData?.from?.firstName} {printableData?.from?.lastName}</p>
                                </div>
                            </div>
                           
                            <div className="claiming">
                                <div className="claiming-sub">
                                {ecardStatus && ecardStatus.pointsValue >0 && printableData.awardType === 'product' &&
                                    <Button
                                        label={t("Claim reward")}
                                        
                                        width="141px"
                                        type="primary"
                                        onClick={handleClaimReward}
                                    />
                                    }
                                </div>
                                <div className="claiming-sub">
                                {printableData && printableData.awardType !== 'product' && printableData.pointsValue > 0 &&
                                    <div>
                                    <EpointsPrice
                                        epoints={printableData.pointsValue}
                                        price={0}
                                        size="small"
                                    />
                                    </div>
}
                                </div>
                            </div>
                        </div>
                        <AccountsEcardPrintable printableData={printableData} />
                        <div className="print-btn" >
                            <Button
                                label={t("Print")}
                                
                                width="141px"
                                type="secondary"
                                onClick={handlePrint}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
}
</div>
    );
}

const mapStateToProps = (state: any) => ({
    userPersonalDetails: state.details.profileData.userSubscriptionDetails,
    tokenDetails: state.join.tokens,
    global: state.global,
    loginDetails: state.join.loginDetails,
    printableData: state.details.printEcardData,
    addressList: state.address.addresses,
    ecardStatus: state.details.productDetails,
    isEcardFetching: state.details.isEcardFetching,
    configResponse: state.details.configData
});

export default connect(mapStateToProps, { getProfileData, getTokens, getPrintableCard, getAddressList, getProductStatus, getConfigData, updateEcardId })(EcardsPrint);
import React, { CSSProperties } from 'react';
import cx from 'classnames';

import './styles.css';

export interface Props {
  /**
   * autoWidth
   */
  autoWidth?: boolean;
  /**
   * Type of button
   */
  type?: 'primary' | 'secondary' | 'link';
  /** this dictates what the button will say  */
  label: string;

  /** this dictates what the button will do  */
  onClick: any;

  /**
   * Disables onclick
   *
   * @default false
   **/
  disabled?: boolean;

  /**
   * font uppercase
   *
   * @default false
   **/
  uppercase?: boolean;

  /**
   * Optional button's width
   */
  width?: any;

  /**
   * Optional button's floated 'left' or 'right'
   */
  floated?: string;

  /**
   * automation id
   */
  automationId?: string;

  /**
   * extra classNames to match design
   */
  extraClassNames?: string;
  style?: CSSProperties;
  dataTestId?: string;
}

const noop = (e: any) => {
  e.preventDefault();
  return;
};

const Button = (props: Props) => {
  const {
    autoWidth,
    type,
    label,
    onClick,
    disabled,
    width,
    floated,
    uppercase,
    automationId,
    dataTestId,
    extraClassNames
  } = props;
  let { style } = props;
  if (!style) style = {};
  if (width) style = { ...style, width };
  return (
    <button
      automation-id={automationId}
      className={cx(
        `buttn buttn-text font-styleRegular ${extraClassNames}`,
        { buttn_disabled: disabled },
        { uppercase: uppercase },
        { 'auto-width': autoWidth },
        { 'text-eco-green buttn-link': type === 'link' },
        { 'bg-eco-black text-white': type === 'secondary' },
        { 'floated-right': floated === 'right' },
        { 'bg-eco-green text-black-primary': type === 'primary' }
      )}
      onClick={!disabled ? onClick : noop}
      style={style}
      data-testid={dataTestId}
    >
      {label}
    </button>
  );
};

Button.defaultProps = {
  autoWidth: false,
  uppercase: false,
  type: 'primary',
  floated: 'left',
  disabled: false
};

export default Button;

import React from 'react';

interface IProps {
  /**
   * Svg's height
   * @default 14
   */
  height?: string;
  /**
   * Svg's width
   * @default 18
   */
  width?: string;
  /**
   * Svg's stroke
   * @default '#00D264'
   */
  stroke?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
}

const TickMark = ({ width, height, stroke, className }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 14"
    className={className}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeWidth="3"
    >
      <path d="M2.429 6.375l5.142 5.25M16.018 1.857L7.982 12.143" />
    </g>
  </svg>
);

TickMark.defaultProps = {
  width: '18',
  height: '14',
  stroke: '#00D264',
  className: ''
};

export default TickMark;

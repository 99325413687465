import cx from 'classnames';
import React, {useState} from 'react';
import Button from '../button';
import EpointsPrice from '../epoints-price';
import ToolTip from 'react-tooltip';
import Moment from 'react-moment';
import {convertEpointsToPounds, currencyConversion} from '../../lib/product-calc';
import { store } from "../../redux/store";

import './styles.css';
import { getCode } from '../../modules/join.ducks';
import LoaderEpoints from '../loader';
import { decodeHtmlEntities } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';

interface accountsMarketingProps {
  relativePath?: string;
  orderData: any;
  token?: string;
  refreshToken?: string;
  shopUser?: boolean;
  onlyDashboard?: boolean;
  countryInfo?: any;
}

function AccountsOrders(props: accountsMarketingProps) {
  const { t } = useTranslation();
  let {global} = store.getState();
  const [fetchingCode, updateFetchingCode] = useState(false);
  // get sso object from localStorage
  let ssoData = global.SSO_USER;
  let isSsoUser = ssoData?.enabled;
  let companyShortName = ssoData?.companyShortName;

  const shopUrl = async (event: any) => {
    event.preventDefault()
    if(isSsoUser) {
      window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}`;
    } else {
      updateFetchingCode(true)
      try {
        let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;

        if (userCode && typeof userCode === 'object') {
            if ('authenticationCode' in userCode) {
                window.location.href = `${global.SHOP_URL}/?code=${userCode?.authenticationCode}`;
            } else {
              updateFetchingCode(false)
                window.location.href = '';
            }
        } else {
          updateFetchingCode(false)
            window.location.href = '';
        }
    } catch (error) {
      updateFetchingCode(false)
        window.location.href = '';
    }
    }
  }

  const contactUsUrl = async (event: any) => {
    event.preventDefault()
    if(isSsoUser) {
        window.location.href = `${global.EP_ADMIN_URL}/sso?companyShortName=${companyShortName}&redirectUrl=contact-us`;
    } else {
      updateFetchingCode(true)
      try {
          let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
  
          if (userCode && typeof userCode === 'object') {
              if ('authenticationCode' in userCode) {
                  if (props?.shopUser){
                      window.location.href = `${global.SHOP_URL}/?code=${userCode?.authenticationCode}&redirect_to=${global.SHOP_URL}/contact-us/`;
                  }
                  else if(props?.onlyDashboard) {
                      window.location.href = `${global.EP_ADMIN_URL}/seamless-redirection?code=${userCode?.authenticationCode}&state=/contact-us`;
                  } else {
                      window.location.href = `${global.EP_ADMIN_URL}/seamless-redirection?code=${userCode?.authenticationCode}&state=/contact-us`;
                  }
              } else {
                updateFetchingCode(false)
                  window.location.href = '';
              }
          } else {
            updateFetchingCode(false)
              window.location.href = '';
          }
      } catch (error) {
        updateFetchingCode(false)
          window.location.href = '';
      }
    }  
}
  return (
    <>
      {fetchingCode ?
        <div><LoaderEpoints/></div> :
        <div className="accounts-orders">
          <div className="ac-orders-binder">
            <div className="ac-orders-card-binder">
              <div className="ac-orders-card-sub-binder">
                <div className="orders-card-head">
                  <div className="ac-flex-binder">
                    <div className="ac-order-placed">
                      <p className="m-0 font-styleLight font-tweleve">
                        {t('Order Placed')}
                      </p>
                      <p className="m-0 font-styleRegular font-sixteens">
                        {' '}
                        <Moment format="DD/MM/YYYY">{props.orderData.date}</Moment>
                      </p>
                    </div>

                    <div className="ac-order-totals">
                      <p className="m-0 font-styleLight font-tweleve">{t('Total')}</p>
                      <p className="m-0 font-styleRegular">
                        <EpointsPrice
                          price={
                            parseFloat(currencyConversion(props.orderData.redemptionItemInfo.priceAfterDiscountInEpoints, props.countryInfo && props.countryInfo.exchangeRate ? props.countryInfo.exchangeRate : 1))
                          }
                          epoints={props.orderData.redemptionItemInfo.priceAfterDiscountInEpoints}
                          size={'small'}
                          currency={props.countryInfo && props.countryInfo.currency}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="ac-order-deliver-user">
                    <p className="m-0 font-styleLight font-tweleve texting-right">
                      {t('Delivered To')}
                    </p>
                    {props.orderData.deliveryEmail == null && (
                      <p className="m-0 font-styleRegular color-greening texting-right font-sixteens">
                        {props.orderData.deliveryAddress.name}
                      </p>
                    )}
                    <div
                      className={cx('tooling', {
                        'tooling-for-email': props.orderData.deliveryEmail !== null
                      })}
                    >
                      <ToolTip
                        place="bottom"
                        type="light"
                        effect="solid"
                        multiline={true}
                        delayHide={500}
                        border={true}
                        // scrollHide={true}
                        className="tool-tip w-262px h-16 leading-block rounded-large text-center text-sm bg-eco-green p-2 savings-grid-tooltip"
                      >
                        {props.orderData.deliveryEmail == null ? (
                          <div className="max-orders-width">
                            <p className="m-0 color-black-on font-styleBold head-hilghlight">
                              {props.orderData.deliveryAddress.name}
                            </p>
                            <p className="m-0 color-black-on font-styleRegular head-hilghlight">
                              {props.orderData.deliveryAddress.address1}
                            </p>
                            <p className="m-0 color-black-on font-styleRegular">
                              {props.orderData.deliveryAddress.address2}
                            </p>
                            <p className="m-0 color-black-on font-styleRegular">
                              {props.orderData.deliveryAddress.city
                                ? props.orderData.deliveryAddress.city
                                : ''}
                            </p>
                            <p className="m-0 color-black-on font-styleRegular">
                              {props.orderData.deliveryAddress.county
                                ? props.orderData.deliveryAddress.county
                                : ''}
                            </p>
                            <p className="m-0 color-black-on font-styleRegular">
                              {props.orderData.deliveryAddress.country}
                            </p>
                            <p className="m-0 color-black-on font-styleRegular">
                              {props.orderData.deliveryAddress.postCode}
                            </p>
                            <p className="m-0 color-black-on font-styleRegular pod-thirteens">
                              {props.orderData.deliveryAddress.phoneNo}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="m-0 font-styleRegular text-center font-sixteens color-black-on tootip-word-break">
                              {props.orderData.deliveryEmail}
                            </p>
                          </div>
                        )}
                      </ToolTip>
                    </div>
                  </div>
                </div>
                {/* card body */}

                <div className="ac-order-card-body">
                  <div className="ac-order-card-body-child">
                    <div className="ac-or-cd-inside">
                      <div className="ac-or-cd-left-con">
                        <div className="ac-or-cd-left-con-binder">
                          <div className="ac-or-cd-left-con-binder-flex">
                            <p className="m-0">
                              {decodeHtmlEntities(props.orderData.redemptionItemInfo.title).innerHTML}
                            </p>
                            <div className="only-for-xs">
                              <EpointsPrice
                                price={
                                  parseFloat(currencyConversion(props.orderData.redemptionItemInfo.priceAfterDiscountInEpoints, props.countryInfo && props.countryInfo.exchangeRate ? props.countryInfo.exchangeRate : 1))
                                } 
                                epoints={props.orderData.redemptionItemInfo.priceAfterDiscountInEpoints}
                                size={'small'}
                                currency={props.countryInfo && props.countryInfo.currency}
                              />
                            </div>
                          </div>
                          <div className="ac-or-cd-left-con-binder-flexing">
                            <p className="m-0">{props.orderData.quantity}</p>
                          </div>
                        </div>
                        <div className="ac-or-cd-right">
                          <div className="ac-or-cd-btns">
                            <div className="review-ac-btn">
                              <a
                                href="https://uk.trustpilot.com/review/epoints.com"
                                target={window.navigator.userAgent.indexOf('gonative') > -1 ? "_self" : "_blank"}
                              >
                                <Button
                                  label={t("Leave a review")}
                                  width="178px"
                                  disabled={false}
                                  type="primary"
                                  onClick={() => {}}
                                />
                              </a>
                            </div>
                            <div className="contact-ac-btn">
                              <a
                                href="#"
                                onClick={contactUsUrl}
                              >
                                <Button
                                  label={t("Contact us")}
                                  width="178px"
                                  disabled={false}
                                  type="secondary"
                                  onClick={() => {}}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {props.orderData === 0 && (
                <div className="empty-message">
                  <p className="font-styleRegular">
                    {(`Hi, you have not spent any of your Epoints yet. When you do your
                    order history will be displayed here. Don’t miss out start
                    shopping today`)}
                  </p>
                  <div>
                    <a href="#" onClick={shopUrl}>
                      <Button
                        label={t("Visit our shop")}
                        width="167px"
                        disabled={false}
                        type="primary"
                        onClick={() => {}}
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
}

// Set default props
AccountsOrders.defaultProps = {
  relativePath: ''
};

export default AccountsOrders;

import React, { SyntheticEvent } from 'react';
import { Dropdown } from 'semantic-ui-react';
import './style.css';

interface IOptionsProps {
  id: string;
  text: string;
  value: string;
}

interface IProps {
  label: string;
  defaultOption: IOptionsProps;
  dropdownOptions: IOptionsProps[];
  automationId?: string;

  /**
   * Callback for when an input is changed
   */
  onInputChange: (data: any) => void;
}

function DropdownComponent({
  label,
  defaultOption,
  dropdownOptions,
  onInputChange: inputChange,
  automationId
}: IProps) {
  const onInputChange = (_0: SyntheticEvent, data: any) => {
    inputChange(data);
  };

  return (
    <div className="content-center dropping flex items-center">
      <h4
        className="font-styleRegular text-sm leading-default font-normal text-grey-darker mr-1 drop-down-main-story"
        style={{ lineHeight: '24.08px' }}
      >
        {label}
      </h4>

      <Dropdown
        inline
        defaultValue={defaultOption.value}
        text={defaultOption.text}
        className={`cursor-pointer font-styleRegular text-grey-darker dropdown-comp ${automationId}`}
      >
        <Dropdown.Menu>
          {dropdownOptions.map(item => (
            <Dropdown.Item
              key={item.id}
              id={item.id}
              text={item.text}
              value={item.value}
              onClick={onInputChange}
              data-testid="dropdown-item"
              active={defaultOption && defaultOption.value === item.value}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default DropdownComponent;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProfileData } from '../../modules/details.ducks';
import AccountsGetReward from '../../ui-componets/accounts-get-reward';
import AddressCard from '../../ui-componets/address-card';
import Button from '../../ui-componets/button';
import AddAddress from '../../ui-componets/address-form';
import { getAddressList, addAddress, removeAddress } from "../../modules/addresses.ducks";
import { getProductStatus, postRedeemOrder, updateSelectedAddress, exchangePointsUpdate } from '../../modules/details.ducks';
import { store } from '../../redux/store';
import { Modal } from 'semantic-ui-react';
import { FiX } from 'react-icons/fi';
import QS from "query-string";
import Moment from 'react-moment';
import Loader from '../../ui-componets/loader';
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';


interface IProps {
    history: any
    getProductStatus: any
    ecardStatus: any
    getAddressList: any
    addressList: any
    addAddress: any
    removeAddress: any
    postRedeemOrder: any
    updateSelectedAddress: any
    // ecardId: any
    seletedAddress: any
    exchangePointsUpdate: any
    RedeemOrderSuccess: any
    isExchanged: any
    tokenDetails: any
}

const findPostcode = (postCode: any) => {
    const { global } = store.getState();
    return fetch(global.API_UI_END + '/rest/addresses/search?postCode=' + postCode, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            return Promise.resolve(
                data
            );
        })

}

function RedeemableRewards(props: IProps) {
    const { t } = useTranslation();
    const [isLoader, setIsLoader] = useState(true);
    const [fetchingCode, updateFetchingCode] = useState(false);
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [storeSelectedAddress, setStoreSelectedAddress] = useState();
    const [selectAddress, setSelectAddress] = useState(-1);
    const { getProductStatus, RedeemOrderSuccess, ecardStatus, getAddressList, addressList, addAddress, postRedeemOrder, updateSelectedAddress, seletedAddress, exchangePointsUpdate, isExchanged } = props;

    const handleSubmitAddedAddress = (formData: any) => {
        addAddress(formData);
        setShowAddAddress(false);
    }

    const handleCancel = () => {
        setShowAddAddress(false)
    }

    const handleAddAddress = () => {
        setShowAddAddress(true);
    }

    const onCloseModal = () => {
        setShowAddAddress(false);
    };

    const handleBackToPrint = () => {
        if (QS.parse(window.location.search).id) {
            const ecardId = QS.parse(window.location.search).id
            props.history.push(`/my-account/ecards/ecard/?id=${ecardId}`);
        } else if (QS.parse(window.location.search).state) {
            const ecardId = QS.parse(window.location.search).state
            props.history.push(`/my-account/ecards/ecard/?id=${ecardId}`);
        }
    }
    const handleSelectAddress = (Item: any, i: any) => {
        setSelectAddress(i);
        updateSelectedAddress(Item);
        setStoreSelectedAddress(Item);
    };

    const handleExchangePoints = () => {
        const ecardId = QS.parse(window.location.search).id;
        exchangePointsUpdate(ecardId);
        console.log("ecardid", ecardId);
    }

    const getCode = () => {
        let bodyFormData = {
            refreshToken: props.tokenDetails?.refresh_token
        }
        const { global } = store.getState();
        return new Promise((resolve, reject) => {
            axios({
                withCredentials: true,
                method: 'post',
                url: `${global.EP_ADMIN_URL}/api/user-authentication/exchange-code`,
                data: bodyFormData,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(function (response:any) {
                resolve(response.data)
            })
            .catch(function (error:any) {
                reject(error)
            });
          });
        
    }
    const handleToRewards = async() => {
        const { global } = store.getState();
        // get sso object from localStorage
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
          
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false)
                window.location.href = '';
            }
        }
    }
    const handleToActivity = () => {
        props.history.push('/orders');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const ecardId = QS.parse(window.location.search).id
        if (QS.parse(window.location.search).id) {
            getProductStatus(ecardId);
        }
        setTimeout(() => {
            setIsLoader(false);
        }, 1000);
        getAddressList();
    }, []);

    const handlePlacingOrder = () => {
        setTimeout(() => {
            setIsLoader(false);
        }, 1000);
        const ecardId = QS.parse(window.location.search).id
        console.log("order placing.....");
        if (storeSelectedAddress) {
            let inputData = {
                eCardId: ecardId,
                address: storeSelectedAddress,
                gift: {
                    relationship: '',
                    occasion: '',
                    message: ''
                }
            }
            postRedeemOrder(inputData);
        } else {
            let inputData = {
                eCardId: ecardId,
                address: addressList[0],
                gift: {
                    relationship: '',
                    occasion: '',
                    message: ''
                }
            }
            postRedeemOrder(inputData);
        }

    }


    return (
        <div>
            {isLoader || fetchingCode ?
                <div>
                    <Loader />
                </div> :
                <div className="redemable-rewards">
                    {ecardStatus && ecardStatus.action === 'REDEEMABLE' &&
                        <div className="redemable-rewards-wrap">
                            <div className="rdm-rr">
                                <div className="rdm-rr-head">
                                    <p className="text-black-primaryone font-styleRegular size-big-join">{t('Get your reward')}</p>
                                </div>
                                <div className="rdm-rr-card-block">
                                    <div className="rdm-rr-sub-card">
                                        <p className="font-styleBold">{t('You have been gifted')}</p>
                                    </div>
                                    <div>
                                        {ecardStatus &&
                                            <AccountsGetReward data={ecardStatus} />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="rdm-rr-address-wrap">
                                <div className="rdm-rr-adres">
                                    <div className="rebranded-another-address text-center">
                                        <Button
                                            label="+ Add a new address"
                                            
                                            width="234px"
                                            type="primary"
                                            onClick={() => handleAddAddress()}
                                        />
                                    </div>
                                    <div className="chosse-adres rebranded-another-address">
                                        <p>{t('Choose a delivery address')}</p>
                                    </div>

                                    <div className="rdm-adress-componet">
                                        <div className="your-address-main">
                                            {!showAddAddress && addressList ? addressList.filter((a: any) => a).map((item: any, i: number) =>
                                                <AddressCard
                                                    onClick={() => handleSelectAddress(item, i)}
                                                    user={{
                                                        additionalInfo: null,
                                                        addressId: item.addressId,
                                                        billingAddress: false,
                                                        city: item.city,
                                                        country: item.country,
                                                        county: item.county,
                                                        customerFullAddress: null,
                                                        firstName: item.firstName,
                                                        house: item.house,
                                                        lastName: item.lastName,
                                                        phoneNo: item.phoneNo,
                                                        postCode: item.postCode,
                                                        preferredDeliveryAddress: item.preferredDeliveryAddress,
                                                        street: item.street,
                                                    }}
                                                    isSelect={selectAddress !== -1 ? selectAddress === i : item.preferredDeliveryAddress}
                                                />
                                            ) : null}

                                            {showAddAddress &&
                                                <Modal
                                                    open={showAddAddress}
                                                    className="modal-accounts-main modal-address-main"
                                                >
                                                    <div className="modal-cross">
                                                        <FiX
                                                            onClick={onCloseModal}
                                                            data-testid="close-modal"
                                                            className="crossing-icon-modal cursor-pointer"
                                                        />
                                                    </div>
                                                    <div className="add-delivery-adrres-modal">
                                                        <h1 className="font-semibold text-2xl text-center font-styleBold check-delivery-paragraph">Add a New Address</h1>
                                                        <AddAddress
                                                            onAddAddress={(formData: any) => handleSubmitAddedAddress(formData)}
                                                            onCancel={handleCancel}
                                                            searchPostcode={(postCode) => findPostcode(postCode)}
                                                        />
                                                    </div>
                                                </Modal>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="button-for-rdm-rr-">
                                <div className="bitton-flex-rdm">
                                    <div className="cancel-rdm-btn">
                                        <Button
                                            label={t("Cancel")}
                                            
                                            width="150px"
                                            type="secondary"
                                            onClick={handleBackToPrint}
                                        />
                                    </div>
                                    <div className="order-rdm-btn">
                                        <Button
                                            label={t("Order my reward")}
                                            
                                            width="200px"
                                            type="primary"
                                            onClick={handlePlacingOrder}
                                        />
                                    </div>
                                </div>

                                {/* after placing oredr showing success */}
                                {RedeemOrderSuccess && RedeemOrderSuccess === true &&

                                    <div className="rewards-order-success flex">
                                        <div className="flexinfsuccess-gft">
                                            <div className="gft-gree">
                                                <img src="/static/reward-green.png" />
                                            </div>
                                            <div className="success-rext-gree">
                                                <p className="color-greeny">{t('Your reward is on its way!')}</p>
                                                <p className="color-greeny">{t('Your reward will be delivered within 10 days. For your rewards history, click the button below.')}</p>
                                            </div>
                                        </div>
                                        <div className="order-rdm-btn rewrds-history-btn">
                                            <Button
                                                label="Rewards history"
                                                
                                                width="200px"
                                                type="primary"
                                                onClick={handleToActivity}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {ecardStatus && ecardStatus.action === 'ORDER_FULFILLED' &&
                        <div className="redeemed-reward">
                            <div className="rdmed-rr removing-btm-border">
                                <div className="rdm-rr-head">
                                    <p className="text-black-primaryone font-styleRegular size-big-join">{t('Get your reward')}</p>
                                </div>
                                <div className="flex-for-rdmd-gft">
                                    <div className="gft-pg">
                                        <img src="/static/gifting.png" />
                                    </div>
                                    <div className="rdmd-status-text">
                                        <p className="abs-para font-styleRegular text-black-primaryone size-small-join">
                                            <Trans i18nKey="productRedeemedText">
                                                Your <span className="prod-name">product </span>
                                                was redeemed on  <span className="moment-space">
                                                    <Moment format="DD/MM/YYYY">{ecardStatus.updatedAt}</Moment>
                                                </span>.Click <a className="cursor-pointer" onClick={handleToActivity}><span className="color-greeny">here</span></a> for more details !
                                            </Trans>
                                        </p>
                                    </div>
                                </div>
                                <div className="footer-for-rdmeed-order-full">
                                    <div className="rdmed-btns">
                                        <Button
                                            label={t("Back")}
                                            
                                            width="141px"
                                            type="secondary"
                                            onClick={handleBackToPrint}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }



                    <div className="exchange-code">
                        <div>
                            <div className="EXCHANGE-points">
                                <div className="redeemed-reward">
                                    {/* exchange for points */}
                                    {ecardStatus && ecardStatus.action === 'EXCHANGE_FOR_POINTS' &&
                                        <div className="rdmed-rr">
                                            <div className="rdm-rr-head">
                                                <p className="text-black-primaryone font-styleRegular size-big-join">{t('Get your reward')}</p>
                                            </div>
                                            <div className="flex-for-rdmd-gft">
                                                <div className="gft-pg">
                                                    <img src="/static/gifting.png" />
                                                </div>
                                                <div className="rdmd-status-text">
                                                    <p className="abs-para font-styleRegular text-black-primaryone size-small-join">
                                                        <Trans i18nKey="outOfStockText">
                                                        Unfortunately, this product is out of stock. Instead, we can add <span className="plf">{ecardStatus.pointsValue} </span>
                                                        to your account so that you can order a different reward.
                                                        </Trans>
                                                    </p>
                                                    <p className="abs-para font-styleRegular text-black-primaryone size-small-join">{t('Use the buttons below to select if you want to receive these epoints or to cancel.')}</p>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    {/* points exchaged */}
                                    {ecardStatus && ecardStatus.action === 'POINTS_EXCHANGED' &&

                                        <div className="rdmed-rr">
                                            <div className="rdm-rr-head">
                                                <p className="text-black-primaryone font-styleRegular size-big-join">Get your reward</p>
                                            </div>
                                            <div className="flex-for-rdmd-gft">
                                                <div className="gft-pg">
                                                    <img src="/static/gifting.png" />
                                                </div>
                                                <div className="rdmd-status-text">
                                                    <p className="abs-para font-styleRegular text-black-primaryone size-small-join">
                                                        <Trans i18nKey="rewardOutOfStock">
                                                        Your reward was out of stock and so we added {ecardStatus.pointsValue} to your account on
                                                        <span className="moment-space">
                                                            <Moment format="DD/MM/YYYY">{ecardStatus.updatedAt}</Moment>
                                                        </span>
                                                        .<a className="color-greeny cursor-pointer" onClick={handleToActivity}>Click here </a>for more details
                                                        </Trans>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="footer-for-rdmeed">
                                        <div className="button-for-rdm-rr-">
                                            {ecardStatus && ecardStatus.action === 'EXCHANGE_FOR_POINTS' &&
                                                <div className="bitton-flex-rdm">
                                                    {!isExchanged &&
                                                        <div className="bitton-flex-rdm">
                                                            <div className="cancel-rdm-btn">
                                                                <Button
                                                                    label={t("Cancel")}
                                                                    
                                                                    width="150px"
                                                                    type="secondary"
                                                                    onClick={handleBackToPrint}
                                                                />
                                                            </div>
                                                            <div className="order-rdm-btn">
                                                                <Button
                                                                    label={t("Get points")}
                                                                    
                                                                    width="150px"
                                                                    type="primary"
                                                                    onClick={handleExchangePoints}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {isExchanged &&
                                                <div className="exchage-points-success">
                                                    <p className="m-0 font-styleRegular pb-4 color-greeny fonting-forten">{t('Epoints have been successfully added to your account')}</p>
                                                    <div className="rder-rdm-btn">
                                                        <Button
                                                            label={t("Spend your epoints")}
                                                            
                                                            width="250px"
                                                            type="primary"
                                                            onClick={handleToRewards}
                                                        />
                                                    </div>
                                                </div>}

                                            {ecardStatus && ecardStatus.action === 'POINTS_EXCHANGED' &&
                                                <div className="bitton-flex-rdm">
                                                    <div className="cancel-rdm-btn">
                                                        <Button
                                                            label={t("Back")}
                                                            
                                                            width="150px"
                                                            type="secondary"
                                                            onClick={handleBackToPrint}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    userPersonalDetails: state.details.profileData.userSubscriptionDetails,
    tokenDetails: state.join.tokens,
    global: state.global,
    loginDetails: state.join.loginDetails,
    ecardStatus: state.details.productDetails,
    addressList: state.address.addresses,
    // ecardId: state.details.ecardId,
    seletedAddress: state.details.selectedAddress,
    RedeemOrderSuccess: state.details.RedeemOrderSuccess,
    isExchanged: state.details.isExchanged
});

export default connect(mapStateToProps, { getProfileData, getProductStatus, getAddressList, addAddress, removeAddress, postRedeemOrder, updateSelectedAddress, exchangePointsUpdate })(RedeemableRewards);
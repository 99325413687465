import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddressCard from '../ui-componets/address-card';
import AddAddress from '../ui-componets/address-form';
import { FiX } from 'react-icons/fi';
import Button from '../ui-componets/button';
import { Modal } from 'semantic-ui-react';
import BreadCrumbs from "../ui-componets/breadcrumbs";

import { getAddressList, addAddress, removeAddress } from "../modules/addresses.ducks";
import { store } from '../redux/store';
import { getTokens } from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import Back from "../ui-componets/Back";
import { useTranslation } from 'react-i18next';

interface IProps {
    getAddressList: any
    addressList: any
    addAddress: any
    removeAddress:any
    history: any
    tokenDetails: any
    global:any
    getTokens: any
    isAddressFetching: any
    shopUser?: boolean
    dieselCardUser?: boolean
}


const findPostcode = (postCode: any) => {
    const { global } = store.getState();
    return fetch(global.API_UI_END + '/rest/addresses/search?postCode=' + postCode, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            return Promise.resolve(
                data
            );
        })

}



function Address(props: IProps) {
    const { t } = useTranslation();

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;
    
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Query params in Addresses", QS.parse(window.location.search));
        // setTimeout(() => {
        if(QS.parse(window.location.search).code && !isSsoUser){
            // since fetching token in this component can be overridden by other functions and give unexpected result
            props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/addresses`);
        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/addresses`, companyShortName: companyShortName });
        } else if(!isSsoUser &&props.tokenDetails && !props.tokenDetails.access_token){
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/addresses`
        } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/addresses?companyShortName=${companyShortName}`
        }
    // }, 2000);
      }, []);

    const [showAddAddress, setShowAddAddress] = useState(false);
    const [showEditAddress, setShowEditAddress] = useState(false);
    const [editAddress, setEditAddress] = useState();

    const {getAddressList, addressList, addAddress, removeAddress, tokenDetails, isAddressFetching} = props;
    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Addresses', className: 'child-breadcrumbs font-styleLight', content: t('Your Addresses'), link: false },
    ]

    useEffect(() => {
        getAddressList();
    }, [tokenDetails])

    const handleEditAddress = (Address: any) => {
        console.log("edit address");
        setShowEditAddress(true)
        setEditAddress(Address);
    }

    const handleSubmitEditedAddress = (formData: any) => {
        addAddress(formData);
        setShowEditAddress(false);
        setShowAddAddress(false);
    }

    const handleCancel = () => {
        setShowAddAddress(false)
        setShowEditAddress(false);
    }

    const handleAddAddress = () => {
        setShowAddAddress(true);
    }
    const handleRemoveAddress = (address:any) => {
        console.log("removing address", address);
        removeAddress(address);
    }
    const onCloseModal = () => {
        setShowAddAddress(false);
        setShowEditAddress(false);
      };

    return (
        <div>
        { isAddressFetching || isAddressFetching === undefined ? 
      <div>
          <Loader/>
      </div>:
        <div className="parent-order addres-btm">
            <div className="set-pref">
                {
                    props.shopUser && <div className="dropdown-for-activity dropdown-for-order pt-0 pl-5">
                        <Back
                            refreshToken={props.tokenDetails.refresh_token}
                            dieselCardUser={props.dieselCardUser}/>
                    </div>
                }
                <div className="page-heads-title-for-all-accounts">
                    <div className="page-heads-title-for-all-accounts-img">
                        <img src="/static/address-image.svg" />
                    </div>
                    <div className="page-heads-title-for-all-accounts-content">
                        <p className="m-0 font-styleBold">{t('Your Addresses')}</p>
                    </div>
                    {sections.length > 0 && !props.shopUser ? <BreadCrumbs
                        sections={sections}
                        handleClick={(url: string) => props.history.push(url)}
                        backAction={() => props.history.goBack()} />
                        : null}
                    <div className="rebranded-another-address">
                    <Button
                        label={t("+ Add a new address")}
                        width="234px"
                        
                        type="primary"
                        onClick={() => handleAddAddress() } 
                        />
                     </div>
                </div>
                <div className="your-address-main-wrap">
                    {/* {addressList && addressList.map((item:any)=>  <AccountsOrders orderData={item}/>) } */}
                    {!showEditAddress && !showAddAddress && addressList ? addressList.filter((a: any) => a).map((item: any, i: number) =>
                       
                            
                        <AddressCard
                            onClick={() => { }}
                            user={{
                                additionalInfo: null,
                                addressId: item.addressId,
                                billingAddress: false,
                                city: item.city,
                                country: item.country,
                                county: item.county,
                                customerFullAddress: null,
                                firstName: item.firstName,
                                house: item.house,
                                lastName: item.lastName,
                                phoneNo: item.phoneNo,
                                postCode: item.postCode,
                                preferredDeliveryAddress: item.preferredDeliveryAddress,
                                street: item.street,
                            }}
                            onEditAction={handleEditAddress}
                            onRemoveAction={(address) => handleRemoveAddress(address)}

                        />
                        
                    ) : null}
                    

                    {showEditAddress &&
                      <Modal
                      open={showEditAddress}
                      className="modal-accounts-main modal-address-main"
                      >
                           <div className="modal-cross">
                         <FiX
                           onClick={onCloseModal}
                           data-testid="close-modal"
                           className="crossing-icon-modal cursor-pointer"
                         />
                       </div>
                    <div>
                        <h1 className="font-semibold text-2xl text-center font-styleBold check-delivery-paragraph">{t('Edit delivery address')} </h1>
                        <AddAddress
                            onAddAddress={(formData: any) => handleSubmitEditedAddress(formData)}
                            onCancel={handleCancel}
                            searchPostcode={(postCode) => findPostcode(postCode)}
                            address={editAddress}
                        />
                    </div>
                    </Modal>
                    }

                    {showAddAddress &&
                     <Modal
                     open={showAddAddress}
                     className="modal-accounts-main modal-address-main"
                     >
                          <div className="modal-cross">
                        <FiX
                          onClick={onCloseModal}
                          data-testid="close-modal"
                          className="crossing-icon-modal cursor-pointer"
                        />
                      </div>
                    <div className="add-delivery-adrres-modal">
                        <h1 className="font-semibold text-2xl text-center font-styleBold check-delivery-paragraph">{t('Add a New Address')}</h1>
                        <AddAddress
                            onAddAddress={(formData: any) => handleSubmitEditedAddress(formData)}
                            onCancel={handleCancel}
                            searchPostcode={(postCode) => findPostcode(postCode)}
                        />
                    </div>
                    </Modal>
                    }
                </div>
            </div>
        </div>
}
</div>
    );
}
const mapStateToProps = (state: any) => ({
    addressList: state.address.addresses,
    tokenDetails: state.join.tokens,
    global: state.global,
    isAddressFetching: state.address.isAddressFetching
});

export default connect(mapStateToProps, { getAddressList, addAddress, removeAddress, getTokens })(Address);

import { Action, ReducerClass } from 'reducer-class';
import { createAction } from 'redux-actions';
import { apiRequest } from './api.ducks';
import cookie from 'cookie';
import { act } from 'react-dom/test-utils';
import QS from "query-string";
import {getExchangeToken} from './join.ducks';

const GET_WORK_PROFILE_DATA = createAction('[WORK] Get Work Profile Data');
const GET_ECARDS_HISTORY = createAction('[Ecards] Get Ecards Data');
const GET_PINTABLE_ECARD = createAction('[Ecards] Get Printable Ecard');
const GET_PRODUCT_STATUS = createAction('[Ecards] Get Product Status');
const GET_CONFIG_DATA = createAction('[Ecards] Get Config Data');
const GET_PROFILE_DATA = createAction('[Profile] Get Profile Data');
const GET_BILLING_DATA = createAction('[Billing] Get Billing Data');
const FETCH_INVOICE_DATA = createAction('[Billing] Fetch Invoice Data');



const POST_PROFILE_DATA = createAction('[Profile] Post Profile Data');
const POST_REDEEM_ORDER = createAction('[ECARDS] Post Redeem Order');

const PUT_EXCHANGE_EPOINTS = createAction('[ECARDS] Put Exchange Epoints');



const PUT_EXCHANGE_EPOINTS_SUCCESS = createAction('[ECARDS] Put Exchange Points Success');
const REDEEM_SUCCESS = createAction('[ECARDS] Redeem Order Success');
const WORK_PROFILE_DATA_FETCH_SUCCESS = createAction('[WORK] Fetch Work Profile Data Success');
const ECARDS_DATA_FETCH_SUCCESS = createAction('[WORK] Fetch Ecards Data Success');
const GET_PINTABLE_ECARD_FETCH_SUCCESS = createAction('[ECARDS] Fetch Printable Ecard Success');
const GET_PRODUCT_STATUS_FETCH_SUCCESS = createAction('[ECARDS] Fetch Product Status Success');
const PROFILE_DATA_FETCH_SUCCESS = createAction('[Profile] Fetch Profile Data Success');
const CONFIG_DATA_FETCH_SUCCESS = createAction('[Ecards] Fetch Config Data Success');
const POST_PROFILE_DATA_SUCCESS = createAction('[Profile] Post Profile Data Success');
const GET_BILLING_DATA_SUCCESS = createAction('[Billing] Get Billing Data Success');
const FETCH_INVOICE_DATA_SUCCESS = createAction('[Billing] Fetch Invoice Data Success');


const PUT_EXCHANGE_EPOINTS_ERROR = createAction('[ECARDS] Put Exchange Points Error');
const WORK_PROFILE_DATA_FETCH_ERROR = createAction('[WORK] Fetch Work Profile Data Error');
const ECARDS_DATA_FETCH_ERROR = createAction('[WORK] Ecards Data Error');
const GET_PINTABLE_ECARD_FETCH_ERROR = createAction('[ECARDS] Fetch Printable Ecard Error');
const GET_PRODUCT_STATUS_FETCH_ERROR = createAction('[ECARDS] Fetch Product Status Error');

const PROFILE_DATA_FETCH_ERROR = createAction('[Profile] Fetch Profile Data Error');
const CONFIG_DATA_FETCH_ERROR = createAction('[Profile] Fetch Profile Data Error');
const POST_PROFILE_DATA_ERROR = createAction('[Profile] Post Profile Data Error');
const GET_BILLING_DATA_ERROR = createAction('[Billing] Get Billing Data Error');
const FETCH_INVOICE_DATA_ERROR = createAction('[Billing] Fetch Invoice Data Error');
const REDEEM_ERROR = createAction('[ECARDS] Redeem Order Error');


const UPDATE_WORK_PROFILE_DATA = createAction('[Profile] Update Work Profile Data');
const UPDATE_ECARDS_DATA = createAction('[Ecards] Update Ecards Data');
const UPDATE_ECARD_ID =  createAction('[Ecards] Update Ecards Id');
const UPDATE_PRINTABLE_ECARD_SUCCESS = createAction('[Ecards] Update Printable Ecard Response')
const UPDATE_PRODUCT_STATUS_SUCCESS = createAction('[Ecards] Update Product Response')
const UPDATE_REDEEM_ORDER_SUCCESS = createAction('[ECARDS] Update Climate payment Success')
const UPDATE_EXCHANGE_POINTS_SUCCESS = createAction('[ECARDS] Update Exchange Points Success')
const UPDATE_PROFILE_FETCH_STATUS = createAction('[Profile] Update Profile Fetch Status');
const UPDATE_WORK_PROFILE_FETCH_STATUS = createAction('[Profile] Update Work Profile Fetch Status');
const UPDATE_ECARD_FETCH_STATUS = createAction('[Ecards] Update Ecard Fetch Status');
const UPDATE_PROFILE_DATA = createAction('[Profile] Update Profile Data');
const UPDATE_CONFIG_DATA = createAction('[Ecards] Update Config Data');
const UPDATE_SELECTED_ADDRESS = createAction('[BASKET] Update Selected Address');
const UPDATE_BILLING_DATA = createAction('[Billing] Update Billing Data');
const UPDATE_FETCH_INVOICE_DATA = createAction('[Billing] Update Fetch Invoice Data');



const UPDATE_POST_PROFILE_DATA = createAction('[Profile] Update Post Profile Data');

export const updateSelectedAddress = (data: any) => UPDATE_SELECTED_ADDRESS(data);
export const getWorkProfileData = () => GET_WORK_PROFILE_DATA();
export const getEcardsData = () => GET_ECARDS_HISTORY();
export const updateEcardId = (data:any) => UPDATE_ECARD_ID(data);
export const getPrintableCard = (data:any) => GET_PINTABLE_ECARD(data);
export const getProductStatus = (data:any) => GET_PRODUCT_STATUS(data);
export const getConfigData = () => GET_CONFIG_DATA();
export const getProfileData = () => GET_PROFILE_DATA();
export const getBillingData = () => GET_BILLING_DATA();
export const fetchInvoice = (data: any) => FETCH_INVOICE_DATA(data);

export const postProfileData = (formData: any) => POST_PROFILE_DATA(formData);
export const postRedeemOrder = (data: any) => POST_REDEEM_ORDER(data);

export const exchangePointsUpdate = (formData:any) => PUT_EXCHANGE_EPOINTS(formData);


// export const getCookies = () => GET_COOKIES();
const updateWorkProfileData = (data: any) => UPDATE_WORK_PROFILE_DATA(data);
const updateEcardsData = (data: any) => UPDATE_ECARDS_DATA(data);
const updatePrintableEcardSuccess = (data: any) => UPDATE_PRINTABLE_ECARD_SUCCESS(data);
const updateProductStatusSuccess = (data: any) => UPDATE_PRODUCT_STATUS_SUCCESS(data);
const updateRedeemOrderSuccess = (data: any) => UPDATE_REDEEM_ORDER_SUCCESS(data);
const updateExchangePointsSuccess = (data: any) => UPDATE_EXCHANGE_POINTS_SUCCESS(data);
const updateProfileFetchStatus = (data: any) => UPDATE_PROFILE_FETCH_STATUS(data);
const updateWorkProfileFetchStatus = (data: any) => UPDATE_WORK_PROFILE_FETCH_STATUS(data);
const updateEcardFetchStatus = (data: any) => UPDATE_ECARD_FETCH_STATUS(data);


const updateProfileData = (data: any) => UPDATE_PROFILE_DATA(data);
const updateConfigData = (data: any) => UPDATE_CONFIG_DATA(data);


const updateBillingData = (data: any) => UPDATE_BILLING_DATA(data);
const updateFetchInvoiceData = (data: any) => UPDATE_FETCH_INVOICE_DATA(data);



interface IReducerUIState {
    profileData: any
    billingData: any
    invoiceData: any
    workProfileData: any
    printEcardData: any
    configData: any
    isProfileFetching: boolean
    isWorkProfileFetching: boolean
    isEcardFetching: boolean
}


class Details extends ReducerClass<IReducerUIState> {
    initialState = {
        profileData: {},
        billingData: [],
        invoiceData: {},
        workProfileData: {},
        printEcardData: {},
        configData: {},
        isProfileFetching: true,
        isWorkProfileFetching: false,
        isEcardFetching: true
    };


    static exchangePointsUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === PUT_EXCHANGE_EPOINTS().type) {
            const { global, join, details } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "PUT",
                global.API_PROD_END_POINT + `/rest/ecards/${action.payload}/exchange-points/V2`,
                action.payload,
                PUT_EXCHANGE_EPOINTS_SUCCESS().type,
                PUT_EXCHANGE_EPOINTS_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json'
                    }}
            ))
            }
        }
    }


    static getPrintableCard = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_PINTABLE_ECARD().type) {
            const {global,details, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/ecards/${action.payload}/V2`,
                    '' + 
                    null,
                    GET_PINTABLE_ECARD_FETCH_SUCCESS().type,
                    GET_PINTABLE_ECARD_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            // 'Content-Type': 'application/json',
                        }}
                ));
            }
        }
    }


    static getProductStatus = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_PRODUCT_STATUS().type) {
            const {global,details, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(updateEcardFetchStatus(true));
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/ecards/${action.payload}/product/status/V2`,
                    '' + 
                    null,
                    GET_PRODUCT_STATUS_FETCH_SUCCESS().type,
                    GET_PRODUCT_STATUS_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            // 'Content-Type': 'application/json',
                        }}
                ));
            }
        }
    }

    static getProfileData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_PROFILE_DATA().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(updateProfileFetchStatus(true));
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + '/rest/my-account/profile/load/V2',
                    null,
                    PROFILE_DATA_FETCH_SUCCESS().type,
                    PROFILE_DATA_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }
                    }
                ));
            }
        }
    }

    static getConfigData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_CONFIG_DATA().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + '/rest/branding/config/V2',
                    null,
                    CONFIG_DATA_FETCH_SUCCESS().type,
                    CONFIG_DATA_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                        }
                    }
                ));
            }
        }
    }



    static getWorkProfileData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_WORK_PROFILE_DATA().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(updateWorkProfileFetchStatus(true));
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + '/rest/user/account/work/profile',
                    null,
                    WORK_PROFILE_DATA_FETCH_SUCCESS().type,
                    WORK_PROFILE_DATA_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }
                    }
                ));
            }
        }
    }


    static getEcardsData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_ECARDS_HISTORY().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + '/rest/ecards/activity/user/received/V2?limit=200&offset=0',
                    null,
                    ECARDS_DATA_FETCH_SUCCESS().type,
                    ECARDS_DATA_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            // 'Content-Type': 'application/json',
                        }
                    }
                ));
            }
        }
    }


    static getBillingData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_BILLING_DATA().type) {
            const { global, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                'GET',
                global.API_PROD_END_POINT + '/rest/my-account/profile/billingDetails/V2',
                null,
                GET_BILLING_DATA_SUCCESS().type,
                GET_BILLING_DATA_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }
                }
            ));
            }
        }
    }

    static fetchInvoice = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === FETCH_INVOICE_DATA().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                'GET',
                global.API_PROD_END_POINT + `/rest/my-account/profile/invoiceDetails/V2?invoiceId=${action.payload}`,
                null,
                FETCH_INVOICE_DATA_SUCCESS().type,
                FETCH_INVOICE_DATA_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }
                }
            ));
            }
        }
    }


    static postProfileData = (store: any) => (next: any) => (action: any) => {
        next(action);
        let formData = {
            'personalDetails.firstName': 'Arjit',
            'personalDetails.lastName': 'Rout',
            'personalDetails.title': '',
            'personalDetails.gender': '',
            'personalDetails.dob': ''
        }
        const data = action.payload;
        if (action.type === POST_PROFILE_DATA().type) {
            const { global, details } = store.getState();
            store.dispatch(apiRequest(
                "POST",
                global.API_PROD_END_POINT + '/rest/my-account/profile/save/personalDetails',
                data,
                POST_PROFILE_DATA_SUCCESS().type,
                POST_PROFILE_DATA_ERROR().type,
            ))
        }
    };



    static handlePostRedeemOrder = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === POST_REDEEM_ORDER().type) {
            const { global, join, details } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "POST",
                global.API_PROD_END_POINT + '/rest/users/order/V2',
                action.payload,
                REDEEM_SUCCESS().type,
                REDEEM_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }}
                    
            ))
            }
        }
    }


    static processExchangePointsUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === PUT_EXCHANGE_EPOINTS_SUCCESS().type) {
            store.dispatch(updateExchangePointsSuccess(true))
        }
    }

    static processRedeemOrder = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === REDEEM_SUCCESS().type) {
            store.dispatch(updateRedeemOrderSuccess(true));
        }
    }


    static processPrintableCard = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_PINTABLE_ECARD_FETCH_SUCCESS().type) {
            const printEcardData = action.payload;
            store.dispatch(updatePrintableEcardSuccess(printEcardData));
        }
    }


    static processProductStatus = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_PRODUCT_STATUS_FETCH_SUCCESS().type) {
            const productDetails = action.payload;
            store.dispatch(updateProductStatusSuccess(productDetails));
            store.dispatch(updateEcardFetchStatus(false));
        }
    }

    static processWorkProfileData = (store: any) => (next: any) => (action: any) => {
        next(action);
        // get sso object from localStorage
        let {global} = store.getState();
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;

        if (action.type === WORK_PROFILE_DATA_FETCH_SUCCESS().type) {
            const data = action.payload;
            store.dispatch(updateWorkProfileData(data));
            store.dispatch(updateWorkProfileFetchStatus(false));
        }
        if (action.type === WORK_PROFILE_DATA_FETCH_ERROR().type) {
            store.dispatch(updateWorkProfileFetchStatus(false));
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }

    static processEcardsData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === ECARDS_DATA_FETCH_SUCCESS().type) {
            const data = action.payload;
            store.dispatch(updateEcardsData(data));
        }
    }

    static processProfileData = (store: any) => (next: any) => (action: any) => {
        next(action);
        // get sso object from localStorage
        let {global} = store.getState();
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;
        if (action.type === PROFILE_DATA_FETCH_SUCCESS().type) {
            const data = action.payload;
            store.dispatch(updateProfileData(data));
            store.dispatch(updateProfileFetchStatus(false));
        }
        if (action.type === PROFILE_DATA_FETCH_ERROR().type) {
            store.dispatch(updateProfileFetchStatus(false));
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }

    static processConfigData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === CONFIG_DATA_FETCH_SUCCESS().type) {
            const data = action.payload;
            store.dispatch(updateConfigData(data));
        }
    }

    static processBillingData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_BILLING_DATA_SUCCESS().type) {
            const bills = action.payload;
            store.dispatch(updateBillingData(bills));
        }
    }

    static processFetchInvoiceData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === FETCH_INVOICE_DATA_SUCCESS().type) {
            const invoice = action.payload;
            store.dispatch(updateFetchInvoiceData(invoice));
        }
    }



    @Action(UPDATE_ECARD_FETCH_STATUS())
    updateEcardFetchStatus(state: IReducerUIState, action: any) {
        return {
            ...state,
            isEcardFetching: action.payload,
        };
    }

    @Action(UPDATE_PROFILE_FETCH_STATUS())
    updateProfileFetchStatus(state: IReducerUIState, action: any) {
        return {
            ...state,
            isProfileFetching: action.payload,
        };
    }

    @Action(UPDATE_WORK_PROFILE_FETCH_STATUS())
    updateWorkProfileFetchStatus(state: IReducerUIState, action: any) {
        return {
            ...state,
            isWorkProfileFetching: action.payload,
        };
    }

    @Action(UPDATE_SELECTED_ADDRESS())
    updateSelectedAddress(state: IReducerUIState, action: any) {
        return {
            ...state,
            selectedAddress: action.payload
        }
    }

    @Action(UPDATE_REDEEM_ORDER_SUCCESS())
    updateRedeemOrderSuccess(state: IReducerUIState, action: any) {
        return {
            ...state,
            RedeemOrderSuccess: action.payload,
        }
    }

    @Action(UPDATE_EXCHANGE_POINTS_SUCCESS())
    updateExchangePointsSuccess(state: IReducerUIState, action: any) {
        return {
            ...state,
            isExchanged: action.payload,
        }
    }

    @Action(UPDATE_WORK_PROFILE_DATA())
    updateWorkProfileData(state: IReducerUIState, action: any) {
        return {
            ...state,
            workProfileData: action.payload,
        };
    }

    @Action(UPDATE_ECARDS_DATA())
    updateEcardsData(state: IReducerUIState, action: any) {
        return {
            ...state,
            ecardsData: action.payload,
        };
    }

    @Action(UPDATE_ECARD_ID())
    updateEcardId(state: IReducerUIState, action: any) {
        return {
            ...state,
            ecardId: action.payload,
        };
    }

    @Action(UPDATE_PRINTABLE_ECARD_SUCCESS())
    updatePrintableEcardSuccess(state: IReducerUIState, action: any) {
        return {
            ...state,
            printEcardData: action.payload
        }
    }

    @Action(UPDATE_PRODUCT_STATUS_SUCCESS())
    updateProductStatusSuccess(state: IReducerUIState, action: any) {
        return {
            ...state,
            productDetails: action.payload
        }
    }

    @Action(UPDATE_PROFILE_DATA())
    updateProfileData(state: IReducerUIState, action: any) {
        return {
            ...state,
            profileData: action.payload,
        };
    }

    @Action(UPDATE_CONFIG_DATA())
    updateConfigData(state: IReducerUIState, action: any) {
        return {
            ...state,
            configData: action.payload,
        };
    }

    @Action(UPDATE_BILLING_DATA())
    updateBillingData(state: IReducerUIState, action: any) {
        return {
            ...state,
            billingData: action.payload,
        };
    }

    @Action(UPDATE_FETCH_INVOICE_DATA())
    updateFetchInvoiceData(state: IReducerUIState, action: any) {
        return {
            ...state,
            invoiceData: action.payload,
        };
    }

}

export default Details.create();

export const DetailsMdl = [Details.getProfileData, Details.getWorkProfileData, Details.getEcardsData, Details.getPrintableCard, Details.getProductStatus, Details.getConfigData, Details.getBillingData, Details.fetchInvoice, Details.postProfileData, Details.handlePostRedeemOrder, Details.processProfileData, Details.processExchangePointsUpdate, Details.exchangePointsUpdate, Details.processRedeemOrder, Details.processWorkProfileData, Details.processEcardsData, Details.processPrintableCard, Details.processConfigData, Details.processProductStatus, Details.processBillingData, Details.processFetchInvoiceData];

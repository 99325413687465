import React, { useEffect, useState, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import Button from '../ui-componets/button';
import InterruptScreen from '../ui-componets/interrupt-screen';
import { getCode } from '../modules/join.ducks';
import LoaderEpoints from '../ui-componets/loader';

interface IProps {
  join: any
  global: any
}

function Interrupt(props: IProps) {
  // get sso object from localStorage
  let ssoData = props.global.SSO_USER;
  let isSsoUser = ssoData?.enabled;
  let companyShortName = ssoData?.companyShortName;
  const [fetchingCode, updateFetchingCode] = useState(false);

  const redirecrToShop = async () => {
    if(props?.join?.loginDetails?.onlyDashboard) {
        window.location.href = ''
    }
    
    if(isSsoUser) {
        window.location.href = `${props.global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}`;
    } else {
      updateFetchingCode(true)
        try {
            let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
      
            if (userCode && typeof userCode === 'object') {
                if ('authenticationCode' in userCode) {
                    window.location.href = `${props.global.SHOP_URL}/?code=${userCode.authenticationCode}`;
                } else {
                  updateFetchingCode(false)
                    window.location.href = '';
                }
            } else {
              updateFetchingCode(false)
                window.location.href = '';
            }
        } catch (error) {
          updateFetchingCode(false)
            window.location.href = '';
        }
    }
}

  return (
    <>
      {fetchingCode ? 
        <div><LoaderEpoints/></div> :
        <div className="intr-screen">
            <InterruptScreen handleBack={redirecrToShop}/>
        </div>
      }
    </>
  );
}

const mapStateToProps = (state: any) => ({
  join: state.join,
  global: state.global
});
export default connect(mapStateToProps, {})(Interrupt);

import {Action, ReducerClass} from 'reducer-class';
import {createAction} from 'redux-actions';
import {apiRequest} from './api.ducks';
import {getExchangeToken} from './join.ducks';
import { toast } from 'react-toastify';




const GET_BALANCE = createAction('[ui] Get Balance');
const GET_FAQ_DATA = createAction('[ui] Get Faq Data');
const GET_ORDERS_HISTORY = createAction('[ORDERS] Get Orders History')
const GET_GDPR = createAction('[GDPR] Get Gdpr');
const PUT_GDPR = createAction('[GDPR] Put Gdpr Update');
const GET_POINTS = createAction('[Points] GET');
const GET_CLIMATE_PAYMENT = createAction('[ui] Climate Payment');
const GET_CLIMATE_CARD_STREAM = createAction('[ui] GET Climate Card Stream Form');
const GET_DONATION_PAYMENT = createAction('[ui] Donation Payment');
const GET_DONATION_CARD_STREAM = createAction('[ui] GET Donation Card Stream Form');
const GET_COUNTRIES = createAction('[ui] Get Countres');
const GET_COUNTRY_INFO = createAction('[ui] Get Country Info');




const BALANCE_FETCH_SUCCESS = createAction('[ui] Fetch Balacnce Success');
const FETCH_POINTS_SUCCESS = createAction('[ui] Fetch Points success');
const FAQ_DATA_FETCH_SUCCESS = createAction('[ui] Fetch Faq Data Success');
const GET_ORDERS_HISTORY_SUCCESS = createAction('[ORDERS] Fetch Orders History Success');
const GDPR_FETCH_SUCCESS = createAction('[ui] Fetch Gdpr Success');
const PUT_GDPR_SUCCESS = createAction('[GDPR] Put Gdpr Update Success');
const CLIMATE_CARD_STREAM_FORM_SUCCESS = createAction('[ui] Climate Card Stream Form Success');
const CLIMATE_PAYMENT_SUCCESS = createAction('[ui] Climate Payment Success');
const DONATION_CARD_STREAM_FORM_SUCCESS = createAction('[ui] Donation Card Stream Form Success');
const DONATION_PAYMENT_SUCCESS = createAction('[ui] Donation Payment Success');
const GET_COUNTRY_INFO_SUCCESS = createAction('[ui] Fetch Country Info Success');
const GET_EXCHANGE_RATE_SUCCESS = createAction('[ui] Fetch Exchange Rate Success');


const FETCH_POINTS_ERROR = createAction('[Points] Fetch Points Error');
const BALANCE_FETCH_ERROR = createAction('[ui] Fetch Balance Error');
const FAQ_DATA_FETCH_ERROR = createAction('[ui] Fetch Faq Data Error');
const GET_ORDERS_HISTORY_ERROR = createAction('[ORDERS], Fetch Orders History Error ')
const GDPR_FETCH_ERROR = createAction('[ui] Fetch Gdpr Error');
const PUT_GDPR_ERROR = createAction('[GDPR] Put Gdpr Update Error');
const CLIMATE_PAYMENT_ERROR = createAction('[ui] Climate Payment Error');
const UPDATE_CLIMATE_PAYMENT_ERROR= createAction('[ui] Update Climate Payment Error');
const CLIMATE_CARD_STREAM_FORM_ERROR = createAction('[ui] Climate Card Stream Form Error');
const DONATION_PAYMENT_ERROR = createAction('[ui] Donation Payment Error');
const UPDATE_DONATION_PAYMENT_ERROR= createAction('[ui] Update DOnation Payment Error');
const DONATION_CARD_STREAM_FORM_ERROR = createAction('[ui] Donation Card Stream Form Error');
const GET_COUNTRIES_ERROR = createAction('[ui] Get Countres Error');
const GET_COUNTRY_INFO_ERROR = createAction('[ui] Fetch Country Info Error');
const GET_EXCHANGE_RATE_ERROR = createAction('[ui] Fetch Exchange Rate Error');


const UPDATE_BALANCE = createAction('[BALANCE] Update Blance');
const UPDATE_FETCH_STATUS = createAction('[BALANCE] Update Fetch Status');
const UPDATE_ORDER_FETCH_STATUS = createAction('[Orders] Update Orders Fetch Status');
const UPDATE_MARKETING_FETCH_STATUS = createAction('ui] Update Marketing Fetch Status');
const UPDATE_COUNTRIES = createAction('[ui] update countries');
const UPDATE_COUNTRY_INFO = createAction('[ui] update country info');

const UPDATE_POINTS = createAction('[POINTS] Update');
const UPDATE_TOKENS = createAction('[TOKENS] Update TOKENS');
const UPDATE_LOGIN_DETAILS = createAction('[LOGIN] Update LOGIN DETAILS');
const UPDATE_FAQ_DATA = createAction('[BALANCE] Update Faq Data');
const UPDATE_ORDER_HISTORY = createAction('[ORDERS] Update Order History')
const UPDATE_GDPR = createAction('[GDPR] Update Gdpr');
const UPDATE_GDPR_VALUES = createAction('[GDPR] Update Gdpr Values');
const UPDATE_ORDERS_DURATION =  createAction('[ui] Update Orders Duration');
const UPDATE_CLIMATE_CARD_STREAM_FORM = createAction('[ui] Update Climate Card Stream Form');
const UPDATE_CLIMATE_PAYMENT_SUCCESS = createAction('[ui] Update Climate payment Success')
const UPDATE_CLIMATE_SUCCESS_RESPONSE = createAction('[ui] Update Climate Response')
const updateCountryInfo = (data: any) => UPDATE_COUNTRY_INFO(data);
const UPDATE_DONATION_CARD_STREAM_FORM = createAction('[ui] Update Donation Card Stream Form');
const UPDATE_DONATION_PAYMENT_SUCCESS = createAction('[ui] Update Donation payment Success')


export const getBalance = () => GET_BALANCE();
export const getFaqData = () => GET_FAQ_DATA();
export const getGdpr = () => GET_GDPR();
export const getOrders = () => GET_ORDERS_HISTORY();
export const gdprUpdate = (formData:any) => PUT_GDPR(formData);
export const updateOrdersDuration = (data:any) => UPDATE_ORDERS_DURATION(data);
export const getPoints = () => GET_POINTS();
export const updatePoints = (data: any) => UPDATE_POINTS(data);
export const climatePaymentRequest = (data: any) => GET_CLIMATE_PAYMENT(data);
export const getClimateCardStreamForm = (data: any) => GET_CLIMATE_CARD_STREAM(data);
export const updateClimateCardStreamForm = (data: any) => UPDATE_CLIMATE_CARD_STREAM_FORM(data);
export const donationPaymentRequest = (data: any) => GET_DONATION_PAYMENT(data);
export const getDonationCardStreamForm = (data: any) => GET_DONATION_CARD_STREAM(data);
export const updateDonationCardStreamForm = (data: any) => UPDATE_DONATION_CARD_STREAM_FORM(data);
export const getCountries = () => GET_COUNTRIES();
export const getCountryInfo = (data:any) => GET_COUNTRY_INFO(data);

const updateBalance = (data: any) => UPDATE_BALANCE(data);
const updateFetchStatus = (data: any) => UPDATE_FETCH_STATUS(data);
const updateOrderFetchStatus = (data: any) => UPDATE_ORDER_FETCH_STATUS(data);
const updateMarketingFetchStatus = (data: any) => UPDATE_MARKETING_FETCH_STATUS(data);
const updateTokens= (data: any) => UPDATE_TOKENS(data);
const updateLoginDetails= (data: any) => UPDATE_LOGIN_DETAILS(data);
const updateFaqData = (data: any) => UPDATE_FAQ_DATA(data);
const updateOrderHistory = (data:any) => UPDATE_ORDER_HISTORY(data);
const updateGdpr = (data:any) => UPDATE_GDPR(data);
const updateCountries = (data: any) => UPDATE_COUNTRIES(data);
const updateGdprValues = (data:any) => UPDATE_GDPR_VALUES(data);
const updateClimateSuccess = (data: any) => UPDATE_CLIMATE_PAYMENT_SUCCESS(data);
const updateClimateSuccessResponse = (data: any) => UPDATE_CLIMATE_SUCCESS_RESPONSE(data);
const updateClimateErr = (data: any) => UPDATE_CLIMATE_PAYMENT_ERROR(data);
const updateDonationSuccess = (data: any) => UPDATE_DONATION_PAYMENT_SUCCESS(data);
const updateDonationErr = (data: any) => UPDATE_DONATION_PAYMENT_ERROR(data);

// toast error message
const errNotify = (msg: any) => toast.error(msg, {
    position: "top-right",
    closeButton: false,
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "taost-bg-red",
    // icon: ({}) =>  <img src="/static/toast-tick.svg"/>
});
interface IReducerUIState {
    balance : {};
    climateSuccess : boolean;
    climateSuccessResponse: any;
    donationSuccess: boolean;
    blocksData: any;
    isBalanceFetching: boolean;
    isOrderFetching: boolean;
    isMarketingDetailsFetching: boolean;
    countryCode : any;
    countryDetails: any;

    // gdpr: {}
}

class UI extends ReducerClass<IReducerUIState> {
    initialState = {
        balance: {},
        gdpr: null,
        ordersHistory : [],
        ordersDuration: 30,
        faqData: {},
        climateSuccess: false,
        donationSuccess: false,
        blocksData: [],
        formData:{},
        donationFormData:{},
        climateSuccessResponse:{},
        isBalanceFetching: true,
        isOrderFetching: true,
        isMarketingDetailsFetching: true,
        countryCode: null,
        countryDetails: null
     }

     static getPoints = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_POINTS().type) {
            const {global} = store.getState();
            store.dispatch(apiRequest(
                'GET',
                global.API_END + global.POINTS_CONFIG,
                null,
                FETCH_POINTS_SUCCESS().type,
                FETCH_POINTS_ERROR().type)
            );
        }
    };


    static handleClimatePayment = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_CLIMATE_CARD_STREAM().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "GET",
                global.API_UI_END + '/rest/payment/process/payment/V2?amount=' + Math.ceil(action.payload * 100) + '&page=climatechange&type=card&redirectUrl=' + window.location.origin,
                null,
                CLIMATE_CARD_STREAM_FORM_SUCCESS().type,
                CLIMATE_CARD_STREAM_FORM_ERROR().type,
                {
                  headers: {
                      'Authorization': join.tokens.access_token
                  }}
            ))
            }
        }
    };

    static handleGetDonationPayment = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_DONATION_CARD_STREAM().type) {
            const { global, join } = store.getState();
            if (join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "GET",
                global.API_UI_END + '/rest/payment/process/payment/V2?amount=' + Math.ceil(action.payload * 100) + '&page=donation&type=card&redirectUrl=' + window.location.origin,
                null,
                DONATION_CARD_STREAM_FORM_SUCCESS().type,
                DONATION_CARD_STREAM_FORM_ERROR().type,
                {
                  headers: {
                      'Authorization': join.tokens.access_token
                  }}
            ))
            }
        }
    };

    static getBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_BALANCE().type) {
            const {global, join} = store.getState();
            if (join.tokens &&  join.tokens.access_token) {
                store.dispatch(updateFetchStatus(true));
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + '/rest/user/balance/V2',
                    null,
                    BALANCE_FETCH_SUCCESS().type,
                    BALANCE_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }}
                ));
            }
        }
    }
    static getFaqData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_FAQ_DATA().type) {
            const {global} = store.getState();
                store.dispatch(apiRequest(
                    'GET',
                    global.API_UI_END + '/rest/configuration/locale/translations.en-gb.json',
                    null,
                    FAQ_DATA_FETCH_SUCCESS().type,
                    FAQ_DATA_FETCH_ERROR().type,
                ));
        }
    }

    static getOrders = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_ORDERS_HISTORY().type) {
            const {global, ui, join} = store.getState();
            if(join.tokens && join.tokens.access_token){
                store.dispatch(updateOrderFetchStatus(true));
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/users/rewards/V2?size=100&duration=${ui.ordersDuration}`,
                    null,
                    GET_ORDERS_HISTORY_SUCCESS().type,
                    GET_ORDERS_HISTORY_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }}
                ));
                    }
        }
        
        // if(action.type === UPDATE_ORDERS_DURATION().type){
        //     const {global, ui} = store.getState();
        //     const getCookie = document.cookie;
        //     const userCookie = cookie.parse(getCookie).user;
        //         store.dispatch(apiRequest(
        //             'GET',
        //             global.API_UI_END + `/rest/users/${JSON.parse(userCookie).email}/rewards?size=${ui.ordersDuration}`,
        //             null,
        //             GET_ORDERS_HISTORY_SUCCESS().type,
        //             GET_ORDERS_HISTORY_ERROR().type,
        //         ));
        // }
    }

    static getGdpr = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_GDPR().type) {
            const {global,join} = store.getState();
            if(join.tokens && join.tokens.access_token){
                store.dispatch(updateMarketingFetchStatus(true));
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + '/rest/user/gdpr/V2',
                    null,
                    GDPR_FETCH_SUCCESS().type,
                    GDPR_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json'
                        }}
                ));
                    }
        }
    }

    static gdprUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === PUT_GDPR().type) {
            const { global, join } = store.getState();
            if(join.tokens && join.tokens.access_token){
            store.dispatch(apiRequest(
                "PUT",
                global.API_PROD_END_POINT + '/rest/user/gdpr/update/V2',
                action.payload,
                PUT_GDPR_SUCCESS().type,
                PUT_GDPR_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json'
                    }}
            ))
            }
        }
    }

    static handleClimateChange = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_CLIMATE_PAYMENT().type) {
            const { global, join } = store.getState();
            if(join.tokens && join.tokens.access_token){
            store.dispatch(apiRequest(
                "POST",
                global.API_PROD_END_POINT + '/rest/users/climateChange/V2',
                action.payload,
                CLIMATE_PAYMENT_SUCCESS().type,
                CLIMATE_PAYMENT_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }}
                    
            ))
            }
        }
    }

    static handleDonation= (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_DONATION_PAYMENT().type) {
            const { global, join } = store.getState();
            if(join.tokens && join.tokens.access_token){
            store.dispatch(apiRequest(
                "POST",
                global.API_PROD_END_POINT + '/rest/users/climateChange/V2',
                action.payload,
                DONATION_PAYMENT_SUCCESS().type,
                DONATION_PAYMENT_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }}
                    
            )) }
        }
    }

    static getCountryInfo = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_COUNTRY_INFO().type) {
            const {global, join} = store.getState();
            
            const url = global.API_UI_END +'/rest/login/loginSuccess/V2';
            store.dispatch(apiRequest(
                'GET',
                url,
                null,
                GET_COUNTRIES().type,
                GET_COUNTRIES_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }
                }
            ));
        }
    }

    static getCountries = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_COUNTRIES().type) {
            const {global, join} = store.getState();
            store.dispatch(apiRequest(
                'GET',
                global.API_PROD_END_POINT + `/rest/countries`,
                null,
                GET_COUNTRY_INFO_SUCCESS().type,
                GET_COUNTRY_INFO_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }}
            ));
        }
    }
    
    static processCountryInfo = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_COUNTRY_INFO_SUCCESS().type) {
            const {global, join} = store.getState();
            let countries = action.payload;
            let countryCode = join?.loginDetails?.userCountryCode || join?.loginDetails?.countryCode || 'GB';
            let countryInfo = countries.filter((eachCountry: any) => {
                return eachCountry?.code === countryCode;
            });
            
            let country = countryInfo.length === 1 ? countryInfo[0] : {
                "country": "United Kingdom",
                "code": "GB",
                "flag_link": "https://flagpedia.net/data/flags/normal/gb.png",
                "currencyCode": "GBP",
                "currency": "£"
            };
            join.loginDetails.userLanguageCode = join.loginDetails.userLanguageCode || 'en_GB';
            if(join.loginDetails.userLanguageCode) {
                document.documentElement.setAttribute('lang', join.loginDetails.userLanguageCode);
            }

            // if the language is right to left then add dir="rtl" attribute
            document.documentElement.setAttribute('dir', join?.loginDetails?.rtl ? 'rtl' : 'ltr');
            join.loginDetails.userCountryCode = join?.loginDetails?.multiCountryEnabled ? (join.loginDetails.userCountryCode || 'GB') : 'GB';
            if(country?.currencyCode !== 'GBP') {
                store.dispatch(apiRequest(
                    'GET',
                    global.EP_ADMIN_URL + `/api/exchangeRate/base/${country?.currencyCode}/exchangeTo/GBP`,
                    null,
                    GET_EXCHANGE_RATE_SUCCESS().type,
                    GET_EXCHANGE_RATE_ERROR().type,
                )); 
                store.dispatch(updateCountryInfo(country))
            } else {
                country.exchangeRate = 1;
                store.dispatch(updateCountryInfo(country))
            }            
        }        
    }

    static processExchangeRateInfo = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_EXCHANGE_RATE_SUCCESS().type) {
            let {ui} = store.getState();
            let countryDetails = ui.countryDetails;
            countryDetails.exchangeRate = action.payload.exchangeRate;
            store.dispatch(updateCountryInfo(countryDetails));
        }
        
    }

    static processClimatePayment = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === CLIMATE_CARD_STREAM_FORM_SUCCESS().type) {
            const formData = action.payload;
            store.dispatch(updateClimateCardStreamForm(formData))
        }
    };

    static processClimateChange = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === CLIMATE_PAYMENT_SUCCESS().type) {
            const climateSuccessResponse = action.payload;
            store.dispatch(updateClimateSuccess(true));
            store.dispatch(updateClimateSuccessResponse(climateSuccessResponse));
        }
        if (action.type === CLIMATE_PAYMENT_ERROR().type) {
            store.dispatch(updateClimateErr(true));
        }
    }

    static processDonationPayment = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === DONATION_CARD_STREAM_FORM_SUCCESS().type) {
            const donationFormData = action.payload;
            store.dispatch(updateDonationCardStreamForm(donationFormData))
        }
    };

    static processDonation = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === DONATION_PAYMENT_SUCCESS().type) {
            const climateSuccessResponse = action.payload;
            store.dispatch(updateDonationSuccess(true));
            store.dispatch(updateClimateSuccessResponse(climateSuccessResponse));

        }
        if (action.type === DONATION_PAYMENT_ERROR().type) {
            store.dispatch(updateDonationErr(true));
        }
    }



    static processBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        // get sso object from localStorage
        let {global} = store.getState();
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;
        if (action.type === BALANCE_FETCH_SUCCESS().type) {
            const balance = action.payload;
            store.dispatch(updateBalance(balance));
            store.dispatch(updateFetchStatus(false));
        }
        if (action.type === BALANCE_FETCH_ERROR().type) {
            store.dispatch(updateFetchStatus(false));
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }
    	
    static processFaqData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === FAQ_DATA_FETCH_SUCCESS().type) {
            const faqData = action.payload;
            store.dispatch(updateFaqData(faqData));
        }
    }

    static processOrders = (store: any) => (next: any) => (action: any) => {
        next(action);
        // get sso object from localStorage
        let {global} = store.getState();
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;
        if (action.type === GET_ORDERS_HISTORY_SUCCESS().type) {
            const orders = action.payload;
            store.dispatch(updateOrderHistory(orders));
            store.dispatch(updateOrderFetchStatus(false));
        }
        if (action.type === GET_ORDERS_HISTORY_ERROR().type) {
            store.dispatch(updateOrderFetchStatus(false));
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }

    static processGdpr = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GDPR_FETCH_SUCCESS().type) {
            const gdprValue = action.payload;
            store.dispatch(updateGdpr(gdprValue));
            store.dispatch(updateMarketingFetchStatus(false));
        }
        if (action.type === GDPR_FETCH_ERROR().type) {
            // get sso object from localStorage
            let {global} = store.getState();
            let ssoData = global.SSO_USER;
            let isSsoUser = ssoData?.enabled;
            let companyShortName = ssoData?.companyShortName;

            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }

    static processGdprUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === PUT_GDPR_SUCCESS().type) {
            store.dispatch(getGdpr());
        }
        if (action.type === PUT_GDPR_ERROR().type) {
            errNotify('Something went wrong. Please try again!')
            // get sso object from localStorage
            let {global} = store.getState();
            let ssoData = global.SSO_USER;
            let isSsoUser = ssoData?.enabled;
            let companyShortName = ssoData?.companyShortName;
            
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }

    static processPoints = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === FETCH_POINTS_SUCCESS().type) {
            const blocksData = action.payload;
            store.dispatch(updatePoints(blocksData))
        }
    };

    @Action(UPDATE_COUNTRIES())
    updateCountries(state: IReducerUIState, action: { payload: any }) {
        return {
            ...state,
            countryCode: action.payload,
        };
    }

    @Action(UPDATE_COUNTRY_INFO())
    updateCountryInfo(state: IReducerUIState, action: { payload: any }) {
        return {
            ...state,
            countryDetails: action.payload,
        };
    }

    @Action(UPDATE_CLIMATE_CARD_STREAM_FORM())
    updateClimateCardStreamForm(state: IReducerUIState, action: any) {
        return {
            ...state,
            formData: action.payload
        }
    }

    @Action(UPDATE_DONATION_CARD_STREAM_FORM())
    updateDonationCardStreamForm(state: IReducerUIState, action: any) {
        return {
            ...state,
            donationFormData: action.payload
        }
    }

    @Action(UPDATE_POINTS())
    updatePoints(state: IReducerUIState, action: { payload: {} }) {
        return {
            ...state,
           blocksData: action.payload,
        }
    }

    @Action(UPDATE_BALANCE())
    updateBalance(state: IReducerUIState, action: any) {
        return {
            ...state,
            balance: action.payload,
        };
    }

    @Action(UPDATE_FETCH_STATUS())
    updateFetchStatus(state: IReducerUIState, action: any) {
        return {
            ...state,
            isBalanceFetching: action.payload,
        };
    }


    @Action(UPDATE_ORDER_FETCH_STATUS())
    updateOrderFetchStatus(state: IReducerUIState, action: any) {
        return {
            ...state,
            isOrderFetching: action.payload,
        };
    }

    @Action(UPDATE_MARKETING_FETCH_STATUS())
    updateMarketingFetchStatus(state: IReducerUIState, action: any) {
        return {
            ...state,
            isMarketingDetailsFetching: action.payload,
        };
    }

    @Action(UPDATE_CLIMATE_PAYMENT_ERROR())
    updateClimateErr(state: IReducerUIState, action: any) {
        return {
            ...state,
            climateError: action.payload
        }
    }

    @Action(UPDATE_CLIMATE_PAYMENT_SUCCESS())
    updateClimateSuccess(state: IReducerUIState, action: any) {
        return {
            ...state,
            climateSuccess: action.payload,
        }
    }

    @Action(UPDATE_CLIMATE_SUCCESS_RESPONSE())
    updateClimateSuccessResponse(state: IReducerUIState, action: any) {
        return {
            ...state,
            climateSuccessResponse: action.payload
        }
    }

    

    @Action(UPDATE_DONATION_PAYMENT_ERROR())
    updateDonationErr(state: IReducerUIState, action: any) {
        return {
            ...state,
            donationError: action.payload
        }
    }

    @Action(UPDATE_DONATION_PAYMENT_SUCCESS())
    updateDonationSuccess(state: IReducerUIState, action: any) {
        return {
            ...state,
            donationSuccess: action.payload
        }
    }

    @Action(UPDATE_FAQ_DATA())
    updateFaqData(state: IReducerUIState, action: any) {
        return {
            ...state,
            faqData: action.payload,
        };
    }

    @Action(UPDATE_ORDER_HISTORY())
    updateOrderHistory(state: IReducerUIState, action: any) {
        return {
            ...state,
            ordersHistory: action.payload,
        };
    }

    @Action(UPDATE_GDPR())
    updateGdpr(state: IReducerUIState, action: any) {
        return {
            ...state,
            gdpr: action.payload,
        };
    }

    @Action((UPDATE_ORDERS_DURATION))
    updateOrdersDuration(state: IReducerUIState, action: any) {
        return {
            ...state,
            ordersDuration: action.payload,
        };
    }
    @Action(UPDATE_TOKENS())
    updateTokens(state: IReducerUIState, action: any) {
        return {
            ...state,
            tokens: action.payload,
        };
    }

    @Action(UPDATE_LOGIN_DETAILS())
    updateLoginDetails(state: IReducerUIState, action: any) {
        return {
            ...state,
            loginDetails: action.payload,
        };
    }
}

export default UI.create();

export const UiMdl = [UI.getPoints, UI.getCountries, UI.getCountryInfo, UI.processCountryInfo, UI.processExchangeRateInfo, UI.processPoints, UI.processClimatePayment, UI.processDonationPayment, UI.handleGetDonationPayment, UI.handleClimatePayment, UI.getBalance, UI.getFaqData,  UI.processBalance, UI.processFaqData, UI.getGdpr, UI.getOrders, UI.processOrders, UI.processGdpr,UI.gdprUpdate, UI.processGdprUpdate, UI.handleClimateChange, UI.handleDonation, UI.processClimateChange, UI.processDonation];

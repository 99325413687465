import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   */
  width?: string;
  /**
   * Svg's height
   */
  height?: string;
  /**
   * Svg's stroke
   */
  stroke?: string;
  /**
   * Svg's fill
   */
  fill?: string;
  /**
   * Svg's classNames
   */
  className?: string;
  /**
   * Svg's style
   */
  style?: CSSProperties;
}

const BasketIcon = ({
  width,
  height,
  stroke,
  fill,
  className,
  style
}: IProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 34"
    version="1.1"
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Transition-Elements"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Screen-/-Transition-Elements-/-Desk-Tablet-Mobile"
        transform="translate(-742.000000, -197.000000)"
        stroke={stroke}
        strokeWidth="1.2"
      >
        <g
          id="Components-/-Global-/-Header-/-Tablet-/-Full"
          transform="translate(69.000000, 186.000000)"
        >
          <g
            id="Particles-/-Icons-/-Active-/-Bag-Full"
            transform="translate(670.000000, 10.000000)"
          >
            <g id="Group" transform="translate(4.235294, 2.000000)">
              <path
                d="M5.19866476,8 L22.3304427,8 C23.8468134,8 25.1247636,9.1315378 25.3083699,10.6367516 L27.5040147,28.6367516 C27.7046313,30.2814154 26.5339997,31.7773107 24.8893359,31.9779272 C24.7688113,31.9926289 24.6475054,32 24.5260875,32 L3.00301996,32 C1.34616571,32 0.00301995682,30.6568542 0.00301995682,29 C0.00301995682,28.8785821 0.0103911029,28.7572762 0.0250927197,28.6367516 L2.22073752,10.6367516 C2.40434391,9.1315378 3.68229409,8 5.19866476,8 Z"
                id="kwadrat"
                fill={fill}
                strokeLinecap="round"
              ></path>
              <path
                d="M20.6470588,7 L6.88235294,7 C6.88235294,3.13400673 9.96368735,0 13.7647059,0 C17.5657244,0 20.6470588,3.13400673 20.6470588,7 Z"
                id="kolko"
              ></path>
              <g
                id="kreski"
                transform="translate(6.882353, 7.000000)"
                strokeLinecap="round"
              >
                <path
                  d="M0.458823529,0 L0.458823529,5 M13.3058824,0 L13.3058824,5"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BasketIcon.defaultProps = {
  width: '30px',
  height: '34px',
  stroke: '#4A4A4A',
  fill: '#FFFFFF',
  className: ''
};

export default BasketIcon;

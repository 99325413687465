import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import QS from "query-string";
import { getTokens } from "../modules/join.ducks";
import Loader from '../ui-componets/loader';


interface IProps {
    getTokens: any
    loginDetails: any
    history: any
}

function SsoLogin(props: IProps) {

    useEffect(() => {
        console.log("Query params in SSO", QS.parse(window.location.search));
        let str = QS.parse(window.location.search).redirectUrl; 
        let finalString = JSON.stringify(str);
        let ecardID = finalString?.replace(/[^0-9]/g,'');

        if(ecardID){
            console.log("Setting Ecard ID",ecardID);
            localStorage.setItem('ssoecardId', ecardID);
        }


        let redirectUrl = QS.parse(window.location.search).redirectUrl;
        let companyShortName = QS.parse(window.location.search).companyShortName;
        console.log("sso.tsx file before if condition", redirectUrl, companyShortName);
        // props.history.push(`/${QS.parse(window.location.search).redirectUrl}?companyShortName=${companyShortName}`);

        if (companyShortName) {
            console.log("sso.tsx file after if condition", companyShortName);
            let ssoData = {
                enabled: true,
                companyShortName
            }
            localStorage.setItem('ssoUser', JSON.stringify(ssoData));
            if (redirectUrl) {
                console.log("sso.tsx file after if redirect url condition", companyShortName);
                if(redirectUrl.includes("my-account/ecards/ecard")){
                    console.log("SSO ecard view in sso");
                    props.history.push(`/my-account/ecards/ecard/?companyShortName=${companyShortName}`);
                } else {
                    console.log("SSO NOT a Ecard Page");
                    props.history.push(`/${redirectUrl}?companyShortName=${companyShortName}`);
                }
            } else {
                console.log("sso.tsx file after else redirect url condition", companyShortName);
                props.history.push(`/account?companyShortName=${companyShortName}`);
            }
            //   props.getTokens({code:QS.parse(window.location.search).code, redirectURL:`/sso?companyShortName=${companyShortName}`, companyShortName:companyShortName});
        }
    }, []);

    //   useEffect(() => {
    //     if(props.loginDetails){
    //      props.history.push('/account');
    //     }
    // }, [props.loginDetails]);
    return (
        <div>
            <Loader />
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    loginDetails: state.join.loginDetails
});

export default connect(mapStateToProps, { getTokens })(SsoLogin);

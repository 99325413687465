export { default as BasketIcon } from './basket/index';
export { default as Blog } from './blog/index';
export { default as Twitter } from './twitter/index';
export { default as Facebook } from './facebook/index';
export { default as Instagram } from './instagram/index';
export { default as Pinterest } from './pinterest/index';
export { default as YouTube } from './youtube/index';
export { default as Mail } from './mail/index';
export { default as Search } from './search/index';
export { default as Info } from './info/index';
export { default as LogoSmall } from './logo-small/index';
export { default as Logo } from './logo/index';
export { default as TickMark } from './tick-mark/index';
export { default as Zoom } from './zoom/index';
export { default as Bupa } from './bupa/index';
export { default as Triangle } from './triangle/index';
export { default as Filter } from './filter/index';
export { default as VerticalLineIcon } from './vertical-line/index';
export { default as VisitManagerIcon } from './visit-manager/index';
export { default as CelebrateJobIcon } from './celebrate-job/index';
export { default as TrashIcon } from './trash/index';
export { default as ArrowBack } from './arrow-back/index';
export { default as ArrowNext } from './arrow-next/index';
export { default as CloseError } from './close-error/index';
export { default as PounInCircle } from './pound-with-circle/index';

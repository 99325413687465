import React from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 34
   */
  width?: string;
  /**
   * Svg's height
   * @default 34
   */
  height?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
}

const SearchIcon = ({ width, height, className }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path
      fill="#333"
      fillRule="evenodd"
      d="M33.43 30.676l-8.901-8.898c1.709-2.278 2.732-5.092 2.732-8.148 0-3.645-1.439-6.961-3.778-9.41-.639-.667-1.344-1.27-2.106-1.799C19.176.895 16.506 0 13.631 0 6.113 0 0 6.114 0 13.63c0 7.515 6.113 13.631 13.63 13.631 3.055 0 5.87-1.024 8.149-2.732l8.897 8.9c.76.761 1.993.761 2.753.001.761-.761.761-1.994 0-2.754zM2 13.5C2 7.157 7.157 2 13.5 2 19.84 2 25 7.157 25 13.5 25 19.84 19.84 25 13.5 25 7.157 25 2 19.84 2 13.5z"
    />
  </svg>
);

SearchIcon.defaultProps = {
  width: '34',
  height: '34',
  className: ''
};

export default SearchIcon;

import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 14
   */
  width?: string;
  /**
   * Svg's height
   * @default 24
   */
  height?: string;
  /**
   * Svg's fill
   * @default '#4A4A4A'
   */
  fill?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
  /**
   * Svg's style
   * @default ''
   */
  style?: CSSProperties;
}

const FacebookIcon = ({ width, height, fill, className, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    style={style}
  >
    <defs>
      <path id="prefix__a" d="M0 0L13.621 0 13.621 24 0 24z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        fill={fill}
        d="M4.814 22.593H8.17v-9.056c0-.428.349-.777.777-.777h3.128l.002-3.23h-3.13c-.428 0-.777-.347-.777-.776v-2.1c0-.55.056-1.174.472-1.644.5-.568 1.29-.634 1.84-.634l1.732-.001V1.412l-2.688-.004c-2.906 0-4.713 1.86-4.713 4.856v2.49c0 .429-.35.777-.777.777h-2.63v3.229h2.63c.428 0 .777.349.777.777v9.056zM8.414 24H4.572c-.642 0-1.164-.522-1.164-1.164v-8.67H1.163C.523 14.167 0 13.646 0 13.004V9.288c0-.642.522-1.164 1.165-1.164h2.242v-1.86c0-1.844.579-3.414 1.675-4.538C6.181.596 7.72 0 9.527 0l2.931.005c.643 0 1.162.524 1.162 1.164v3.45c0 .64-.52 1.164-1.162 1.164h-1.974c-.601 0-.755.12-.788.158-.053.06-.118.235-.118.714v1.469h2.731c.205 0 .404.05.576.146.37.207.599.597.599 1.018l-.002 3.715c0 .642-.522 1.164-1.165 1.164h-2.74v8.669c0 .642-.521 1.164-1.163 1.164z"
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

FacebookIcon.defaultProps = {
  width: '14',
  height: '24',
  fill: '#4A4A4A',
  className: ''
};

export default FacebookIcon;

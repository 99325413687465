import {Action, ReducerClass} from 'reducer-class';
import {createAction} from 'redux-actions';
import {apiRequest} from './api.ducks';
import cookie from 'cookie';
import {getExchangeToken} from './join.ducks';


const GET_CURRENT_BALANCE = createAction('[Activity] Get Current Balance');
const GET_PENDING_BALANCE = createAction('[Activity] Get Pending Balance');
const GET_DECLINED_BALANCE = createAction('[Activity] Get Declined Balance');


const CURRENT_BALANCE_FETCH_SUCCESS = createAction('[Activity] Fetch Current Balacnce Success');
const PENDING_BALANCE_FETCH_SUCCESS = createAction('[Activity] Fetch Pending Balacnce Success');
const DECLINED_BALANCE_FETCH_SUCCESS = createAction('[Activity] Fetch Declined Balacnce Success');



const CURRENT_BALANCE_FETCH_ERROR = createAction('[Activity] Fetch Current Balance Error');
const PENDING_BALANCE_FETCH_ERROR = createAction('[Activity] Fetch Pedning Balance Error');
const DECLINED_BALANCE_FETCH_ERROR = createAction('[Activity] Fetch Declined Balance Error');


const UPDATE_CURRENT_BALANCE = createAction('[Activity] Update Current Blance');
const UPDATE_PENDING_BALANCE = createAction('[Activity] Update Pending Blance');
const UPDATE_DECLINED_BALANCE = createAction('[Activity] Update Declined Blance');
const UPDATE_DURATION =  createAction('[ui] Update Activity Duration');
const UPDATE_ACTIVITY_FETCH_STATUS = createAction('[Activity] Update Activity Fetch Status');

export const getCurrentBalance = () => GET_CURRENT_BALANCE();
export const getPendingBalance = () => GET_PENDING_BALANCE();
export const getDeclinedBalance = () => GET_DECLINED_BALANCE();
export const updateActivityDuration = (data:any) => UPDATE_DURATION(data);

// export const getCookies = () => GET_COOKIES();

const updateCurrentBalance = (data: any) => UPDATE_CURRENT_BALANCE(data);
const updatePendingBalance = (data: any) => UPDATE_PENDING_BALANCE(data);
const updateDeclinedBalance = (data: any) => UPDATE_DECLINED_BALANCE(data);
const updateActivityFetchStatus = (data: any) => UPDATE_ACTIVITY_FETCH_STATUS(data);



interface IReducerUIState {
    isActivityFetching: boolean
   
}

class Activity extends ReducerClass<IReducerUIState> {
    initialState = {
        currentData: {},
        pendingData: {},
        declinedData: {},
        activityDuration: 30,
        isActivityFetching: true
    };

    static getCurrentBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_CURRENT_BALANCE().type) {
            const {global, activity, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(updateActivityFetchStatus(true));
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/users/transactions/V2?page=0&size=100&sort=latest,desc&type=CONFIRMED,SPENT,REDEEMED,RECEIVED,TRANSFERED&duration=${activity.activityDuration}`,
                    null,
                    CURRENT_BALANCE_FETCH_SUCCESS().type,
                    CURRENT_BALANCE_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }}
                ));
            }
        }
    }

    static getPendingBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_PENDING_BALANCE().type) {
            const {global,activity,join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/users/transactions/V2?page=0&size=100&sort=latest,desc&type=PENDING&duration=${activity.activityDuration}`,
                    null,
                    PENDING_BALANCE_FETCH_SUCCESS().type,
                    PENDING_BALANCE_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }}
                ));
            }
        }
    }

    static getDeclinedBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_DECLINED_BALANCE().type) {
            const {global, activity, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/users/transactions/V2?page=0&size=100&sort=latest,desc&type=DECLINED&duration=${activity.activityDuration}`,
                    null,
                    DECLINED_BALANCE_FETCH_SUCCESS().type,
                    DECLINED_BALANCE_FETCH_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }}
                ));
            }
        }
    }

    static processCurrentBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        // get sso object from localStorage
        let {global} = store.getState();
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;

        if (action.type === CURRENT_BALANCE_FETCH_SUCCESS().type) {
            const balance = action.payload;
            store.dispatch(updateCurrentBalance(balance));
            store.dispatch(updateActivityFetchStatus(false));
        }
        if (action.type === CURRENT_BALANCE_FETCH_ERROR().type) {
            store.dispatch(updateActivityFetchStatus(false));
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }

    static processPendingBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === PENDING_BALANCE_FETCH_SUCCESS().type) {
            const balance = action.payload;
            store.dispatch(updatePendingBalance(balance));
        }
    }
    static processDeclinedBalance = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === DECLINED_BALANCE_FETCH_SUCCESS().type) {
            const balance = action.payload;
            store.dispatch(updateDeclinedBalance(balance));
        }
    }


    @Action(UPDATE_ACTIVITY_FETCH_STATUS())
    updateActivityFetchStatus(state: IReducerUIState, action: any) {
        return {
            ...state,
            isActivityFetching: action.payload,
        };
    }

    @Action(UPDATE_CURRENT_BALANCE())
    updateCurrentBalance(state: IReducerUIState, action: any) {
        return {
            ...state,
            currentData: action.payload,
        };
    }

    @Action(UPDATE_PENDING_BALANCE())
    updatePendingBalance(state: IReducerUIState, action: any) {
        return {
            ...state,
            pendingData: action.payload,
        };
    }

    @Action(UPDATE_DURATION())
    updateActivityDuration(state: IReducerUIState, action: any) {
        return {
            ...state,
            activityDuration: action.payload,
        };
    }

    @Action(UPDATE_DECLINED_BALANCE())
    updateDeclinedBalance(state: IReducerUIState, action: any) {
        return {
            ...state,
            declinedData: action.payload,
        };
    }
}

export default Activity.create();

export const ActivityMdl = [Activity.getCurrentBalance, Activity.getPendingBalance, Activity.getDeclinedBalance, Activity.processCurrentBalance, Activity.processPendingBalance, Activity.processDeclinedBalance];

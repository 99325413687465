import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { store } from './redux/store';

// Define a custom backend for i18next
const customBackend = {
  read: function (language, namespace, callback) {
    // Construct the S3 URL
    const url = `https://eachperson-asset-images.s3.eu-west-1.amazonaws.com/accounts-ui/i18n/${language}.json`;

    fetch(url)
      .then(response => response.json())
      .then(data => callback(null, data))
      .catch(error => callback(error, false));
  }
};

// Initialize i18next
i18n
  .use({ type: 'backend', init: () => {}, read: customBackend.read }) // Use custom backend
  .use(initReactI18next) // Connect with React
  .init({
    backend: {
      read: customBackend.read,
    },
    fallbackLng: 'en_GB',
    debug: true,
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    keySeparator: false, // Disable key separator for flat JSON
  });

// Function to change language dynamically
export const changeLanguage = (langCode) => {
  i18n.changeLanguage(langCode);
};

// Example function to get language code from an API and change language
export const fetchAndSetLanguage = async (token, API_URL) => {
  try {
    const {global} = store.getState();
    const response = await fetch(`${global.API_UI_END}/rest/login/loginSuccess/V2`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`, // Replace with your actual token
        'Content-Type': 'application/json',
      },
    });
    const { userLanguageCode } = await response.json();
    if (userLanguageCode) {
      changeLanguage(userLanguageCode);
    }
  } catch (error) {
    console.error('Error fetching language:', error);
  }
};

export default i18n;
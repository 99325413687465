import {combineReducers} from "redux";
import uiReducer from "../modules/ui.ducks";
import activityReducer from "../modules/activity.ducks";
import salaryReducer from "../modules/salary.ducks";
import detailsRouter from "../modules/details.ducks";
import addressReducer from "../modules/addresses.ducks";
import joinReducer from "../modules/join.ducks";


export interface IReducerGlobalState {
    API_END?: string,
    API_UI_END?: string,
    SHOP_URL?: string,
    API_PROD_END_POINT ?: string,
    POINTS_CONFIG?: string,
    COGNITO_URL?: string,
    SAVE_SALARY_PATNERID?: any,
    ACCOUNT_SSO_URL?: string,
    EP_ADMIN_URL?: string,
    EPOINTS_DOMAIN?: string,
    EACH_PERSON_END?: string,
    SSO_USER?: any,
    SSO_LOGOUT_URL: string
}

// get the ssoUser detail stored in localStorage
const getSsoData = () => {
    let ssoUserData = localStorage.getItem('ssoUser');
    let parsedSsoData = JSON.parse(ssoUserData as string);
    return parsedSsoData;
}

// sso login configs
const ssoLoginConfigs = {
    // staging branch has no omnicom setup so using normal cognito hosted UI URL
    omnicom: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=2psom8uqb572qrt4uhqm8qjrum&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=3h07p71k246vu1r0nr99qs8n0e&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage: 'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=525udqifbhjmungubaa8v5ahip&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='
    },
    phg: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=4qg4r2lepembpj72537vti1qm5&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=528emai9eebmgju3bcgfr6p5i9&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=2g6ohce2jdp8v571q061n1n9l5&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    btg: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=16btsq1qrubqvobpmfv1bu816r&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=5u9ibr8lfn280k1mli30ftvi0d&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=6amsjb5mt2ki7spvib3q8velj0&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    testnewidp: {
        qa: 'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=1digucpfi97t2hpt58er6s6dlr&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=1digucpfi97t2hpt58er6s6dlr&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=1digucpfi97t2hpt58er6s6dlr&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    allegis: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=ditigu0rs700t3o5139cuu5ik&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=ditigu0rs700t3o5139cuu5ik&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=ditigu0rs700t3o5139cuu5ik&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    "allegis-sso": {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=7pammmrpvi324tdvhtpps46ric&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=7pammmrpvi324tdvhtpps46ric&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=7pammmrpvi324tdvhtpps46ric&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    secamb: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=4ag9pt32cukpplsifquu1cghp8&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=79ulnjsfkdh260odflqvj8okhp&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=4ag9pt32cukpplsifquu1cghp8&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    cote: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=7sni8put3876s249ngpeid97d0&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=25210mg5sihbjfq2oi0tpr97aa&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=7sni8put3876s249ngpeid97d0&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    sbhl: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=66b7t00prv1puj1aojh63o7i5o&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=44fi2schae0qb9nqsjo2v7id5l&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=2opgpv1ihrvic5800s2294uhp&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    virgin_active: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=1egtgs41ucbankkoof1n4jrsj1&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=25210mg5sihbjfq2oi0tpr97aa&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=1egtgs41ucbankkoof1n4jrsj1&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
    each_person: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/login?client_id=7sni8put3876s249ngpeid97d0&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        prod: 'https://login.epoints.com/login?client_id=5r5afmcd8mi9qdjatmv10kelig&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/login?client_id=193kltn0vs7vg5434v4mua1skm&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
    },
};

const ssoLogoutConfigs = {
    omnicom: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=2psom8uqb572qrt4uhqm8qjrum&logout_uri=https://accounts.qa.epoints.com/loginHandler',
        prod: 'https://login.epoints.com/logout?client_id=3h07p71k246vu1r0nr99qs8n0e&logout_uri=https://accounts.epoints.com/loginHandler',
        stage: 'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=525udqifbhjmungubaa8v5ahip&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    phg: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=4qg4r2lepembpj72537vti1qm5&rlogout_uri=https://accounts.qa.epoints.com/logoutHandler',
        prod: 'https://login.epoints.com/logout?client_id=528emai9eebmgju3bcgfr6p5i9&logout_uri=https://accounts.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=2g6ohce2jdp8v571q061n1n9l5&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    btg: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=16btsq1qrubqvobpmfv1bu816r&rlogout_uri=https://accounts.qa.epoints.com/logoutHandler',
        prod: 'https://login.epoints.com/logout?client_id=5u9ibr8lfn280k1mli30ftvi0d&logout_uri=https://accounts.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=6amsjb5mt2ki7spvib3q8velj0&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    testnewidp: {
        qa: 'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=1digucpfi97t2hpt58er6s6dlr&logout_uri=https://accounts.stag.epoints.com/logoutHandler',
        prod: 'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=1digucpfi97t2hpt58er6s6dlr&logout_uri=https://accounts.stag.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=1digucpfi97t2hpt58er6s6dlr&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    allegis: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=ditigu0rs700t3o5139cuu5ik&logout_uri=https://accounts.qa.epoints.com/logoutHandler',
        prod: 'https://login.epoints.com/logout?client_id=ditigu0rs700t3o5139cuu5ik&logout_uri=https://accounts.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=ditigu0rs700t3o5139cuu5ik&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    "allegis-sso": {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=7pammmrpvi324tdvhtpps46ric&logout_uri=https://accounts.qa.epoints.com/logoutHandler',
        prod: 'https://login.epoints.com/logout?client_id=7pammmrpvi324tdvhtpps46ric&logout_uri=https://accounts.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=7pammmrpvi324tdvhtpps46ric&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    secamb: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=4ag9pt32cukpplsifquu1cghp8&logout_uri=https://accounts.qa.epoints.com/logoutHandler',
        prod: 'https://login.epoints.com/logout?client_id=79ulnjsfkdh260odflqvj8okhp&logout_uri=https://accounts.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=4ag9pt32cukpplsifquu1cghp8&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    cote: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=7sni8put3876s249ngpeid97d0&logout_uri=https://accounts.qa.epoints.com/logoutHandler',
        prod: 'https://login.epoints.com/logout?client_id=25210mg5sihbjfq2oi0tpr97aa&logout_uri=https://accounts.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=7sni8put3876s249ngpeid97d0&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    sbhl: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=66b7t00prv1puj1aojh63o7i5o&logout_uri=https://accounts.qa.epoints.com/loginHandler',
        prod: 'https://login.epoints.com/logout?client_id=44fi2schae0qb9nqsjo2v7id5l&logout_uri=https://accounts.epoints.com/loginHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=2opgpv1ihrvic5800s2294uhp&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    virgin_active: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=1egtgs41ucbankkoof1n4jrsj1&logout_uri=https://accounts.qa.epoints.com/loginHandler',
        prod: 'https://login.epoints.com/logout?client_id=25210mg5sihbjfq2oi0tpr97aa&logout_uri=https://accounts.epoints.com/loginHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=1egtgs41ucbankkoof1n4jrsj1&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
    each_person: {
        qa: 'https://qa-login.auth.eu-west-1.amazoncognito.com/logout?client_id=7sni8put3876s249ngpeid97d0&logout_uri=https://accounts.qa.epoints.com/logoutHandler',
        prod: 'https://login.epoints.com/logout?client_id=5r5afmcd8mi9qdjatmv10kelig&logout_uri=https://accounts.epoints.com/logoutHandler',
        stage:'https://stag-login.auth.eu-west-1.amazoncognito.com/logout?client_id=193kltn0vs7vg5434v4mua1skm&logout_uri=https://accounts.stag.epoints.com/logoutHandler'
    },
}

const getRedirectUrl = () => {
    let RedirectDomainName = "https://accounts.epoints.com";
    if(window.location.hostname === "accounts.qa.epoints.com"){
        RedirectDomainName = "https://accounts.qa.epoints.com"
    } else if(window.location.hostname === "accounts.stag.epoints.com"){
        RedirectDomainName = "https://accounts.stag.epoints.com"
    } else if(window.location.hostname === "accounts.epoints.com" ){
        RedirectDomainName = "https://accounts.epoints.com"
    } else if(window.location.hostname === "localhost") {
        RedirectDomainName = "http://localhost:3000"
    } 
    return RedirectDomainName;
}

const getShopUrl = () => {
    let RedirectDomainName = "https://shop.eachperson.com";
    if(window.location.hostname === "accounts.qa.epoints.com"){
        RedirectDomainName = "https://qa-shop.eachperson.com"
    } else if(window.location.hostname === "accounts.stag.epoints.com"){
        RedirectDomainName = "https://staging-shop.eachperson.com"
    } else if(window.location.hostname === "accounts.epoints.com" ){
        RedirectDomainName = "https://shop.eachperson.com"
    } else if(window.location.hostname === "localhost") {
        RedirectDomainName = "https://qa-shop.eachperson.com"
    }
    return RedirectDomainName;
}

const getProdUrl = () => {
    let RedirectDomainName = undefined;
    if(window.location.hostname === "accounts.qa.epoints.com"){
        RedirectDomainName = "https://accounts.qa.epoints.com"
    } else if(window.location.hostname === "accounts.stag.epoints.com"){
        RedirectDomainName = "https://accounts.stag.epoints.com"
    } else if(window.location.hostname === "accounts.epoints.com" ){
        RedirectDomainName = "https://www.epoints.com"
    } else if(window.location.hostname === "localhost") {
        RedirectDomainName = "http://localhost:3000"
    }
    return RedirectDomainName;
};

const getLoginCongnitoURL = () => {
    return `${eachpersonAdminURL()}/login?redirect_uri=`
}

const getSAVE_SALARY_PATNERID = () => {

    let SAVE_SALARY_PATNERID;
    if(window.location.hostname === "accounts.qa.epoints.com"){
        SAVE_SALARY_PATNERID = [2147335217]
    } else if(window.location.hostname === "accounts.epoints.com" ){
        SAVE_SALARY_PATNERID = [100252]
    } else if(window.location.hostname === "localhost") {
        SAVE_SALARY_PATNERID = [2147335217]
    }
    return SAVE_SALARY_PATNERID;
}

const eachpersonAdminURL = () => {
    let RedirectDomainName = "https://admin.eachperson.com";
    if(window.location.hostname === "accounts.qa.epoints.com"){
        RedirectDomainName = "https://qa-admin.eachperson.com"
    } else if(window.location.hostname === "accounts.stag.epoints.com"){
        RedirectDomainName = "https://stag-admin.eachperson.com"
    } else if(window.location.hostname === "accounts.epoints.com" ){
        RedirectDomainName = "https://admin.eachperson.com"
    } else if(window.location.hostname === "localhost") {
        RedirectDomainName = "http://localhost:4200"
    }
    return RedirectDomainName;
}

const epointsDomain = () => {
    let RedirectDomainName = "https://www.epoints.com";
    if(window.location.hostname === "accounts.qa.epoints.com"){
        RedirectDomainName = "https://qa.epoints.com"
    } else if(window.location.hostname === "accounts.stag.epoints.com"){
        RedirectDomainName = "https://stag.epoints.com"
    } else if(window.location.hostname === "accounts.epoints.com" ){
        RedirectDomainName = "https://www.epoints.com"
    } else if(window.location.hostname === "localhost") {
        RedirectDomainName = "http://localhost:8500"
    }
    return RedirectDomainName;
}

const getAccountSSOUrl = () => {
    let ssoURL = undefined;
    let company = getSsoData()?.companyShortName;
    switch (window.location.hostname) {
        case 'accounts.qa.epoints.com': ssoURL = ssoLoginConfigs[company as keyof typeof ssoLoginConfigs]?.qa;break;
        case 'accounts.epoints.com': ssoURL = ssoLoginConfigs[company as keyof typeof ssoLoginConfigs]?.prod;break;
        case 'accounts.stag.epoints.com': ssoURL = ssoLoginConfigs[company as keyof typeof ssoLoginConfigs]?.stage;break;
        default: ssoURL = ssoLoginConfigs[company as keyof typeof ssoLoginConfigs]?.qa;break;
    }
    return ssoURL;
}

const getSSOLogoutURL = () => {
    let ssoURL = undefined;
    let company = getSsoData()?.companyShortName;
    switch (window.location.hostname) {
        case 'accounts.qa.epoints.com': ssoURL = ssoLogoutConfigs[company as keyof typeof ssoLogoutConfigs]?.qa;break;
        case 'accounts.epoints.com': ssoURL = ssoLogoutConfigs[company as keyof typeof ssoLogoutConfigs]?.prod;break;
        case 'accounts.stag.epoints.com': ssoURL = ssoLogoutConfigs[company as keyof typeof ssoLogoutConfigs]?.stage;break;
        default: ssoURL = ssoLogoutConfigs[company as keyof typeof ssoLogoutConfigs]?.qa;break;
    }
    return ssoURL;
}

// get points config json files as per the domain
const getPointsConfig = () => {
    let redirectUrl = getRedirectUrl();
    if(redirectUrl == 'https://accounts.epoints.com')
        return '/rest/configuration/blocks/points.individual.json'
    else if(redirectUrl == 'https://accounts.stag.epoints.com')
        return '/rest/configuration/blocks/stag/stag-points.individual.json'
    else
        return '/rest/configuration/blocks/qa/qa-points.individual.json'
}

const global = (): IReducerGlobalState => ({
    API_END: '',
    API_UI_END: getRedirectUrl(),
    API_PROD_END_POINT: getProdUrl(),
    SHOP_URL: getShopUrl(),
    COGNITO_URL: getLoginCongnitoURL(),
    SAVE_SALARY_PATNERID: getSAVE_SALARY_PATNERID(),
    ACCOUNT_SSO_URL : getAccountSSOUrl(),
    EP_ADMIN_URL: eachpersonAdminURL(),
    EPOINTS_DOMAIN: epointsDomain(),
    POINTS_CONFIG: getPointsConfig(),
    EACH_PERSON_END: "https://qa-control.epoints.com",
    SSO_USER: getSsoData(),
    SSO_LOGOUT_URL: getSSOLogoutURL()
});

export const reducers = combineReducers({
    global,
    ui: uiReducer,
    activity: activityReducer,
    salary: salaryReducer,
    details: detailsRouter,
    address: addressReducer,
    join: joinReducer
});

/**
 * some html entity characters cannot parse characters like &nbsp; &pound; if directly displayed as string.
 * so, they should be displayed inside innerHTML.
 */
export function decodeHtmlEntities(html: any) {
    var txt = document.createElement('div');
    txt.innerHTML = html;
    return txt;
  }
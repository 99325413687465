import React, { useEffect, useState }  from 'react';
import {connect} from 'react-redux';
import AccountsTable from '../ui-componets/accounts-table';
import CategoriesDropdown from "../ui-componets/dropdown";
import {getCurrentBalance, getPendingBalance, getDeclinedBalance, updateActivityDuration} from "../modules/activity.ducks"
import BreadCrumbs from "../ui-componets/breadcrumbs";
import { getTokens } from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import Back from "../ui-componets/Back";
import { useTranslation } from 'react-i18next';

interface IProps {
    getCurrentBalance : any
    getPendingBalance : any
    getDeclinedBalance : any
    currentData: any
    pendingData: any
    declinedData: any
    updateActivityDuration : any
    duration: any
    history: any
    tokenDetails: any
    global:any
    getTokens: any
    isActivityFetching: any
    shopUser?: boolean
    dieselCardUser?: boolean
}

function Activity(props:IProps) {
    const { t } = useTranslation();

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;

    const SortByOptions = [
        {
            id: 'Last_30_Days',
            text: t('Last 30 Days'),
            value: 'Last_30_Days',
        },
        {
            id: 'Last_3_Months',
            text: t('Last 3 Months'),
            value: 'Last_3_Months'
        },
        {
            id: 'Last_Year',
            text: t('Last Year'),
            value: 'Last_Year',
        }
    ];
    
    useEffect(() => {
        console.log("Query params in Activity", QS.parse(window.location.search));
        // setTimeout(() => {
        if (QS.parse(window.location.search).code && !isSsoUser) {
            // since fetching token in this component can be overridden by other functions and give unexpected result
            props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/activity`);
        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/activity`, companyShortName: companyShortName });
        } else if (!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/activity`
        } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/activity?companyShortName=${companyShortName}`
        }
    // }, 2000);
        window.scrollTo(0, 0);
    }, []);

    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Activity', className: 'child-breadcrumbs font-styleLight', content: t('Your Activity'), link: false },
    ]
    const [dropDownValue, setDropDownValue] = useState();
    const {getCurrentBalance, getPendingBalance, getDeclinedBalance, currentData, pendingData, declinedData, updateActivityDuration, duration, tokenDetails, isActivityFetching} = props;

     const handleChange = (data: any) =>{
        setDropDownValue(data.id);
         let durationValue = 30;
         console.log("data key", data);
         if(data.id === "Last_30_Days"){
            durationValue = 30
         } else if(data.id === "Last_3_Months"){
            durationValue = 90
         } else if(data.id === "Last_Year"){
            durationValue = 365
         } 
        updateActivityDuration(durationValue);
    }
    
    useEffect(() => {
        getPendingBalance();
        getCurrentBalance();
        getDeclinedBalance();
    }, [duration, tokenDetails]);

    

    return (
        <div>
              { isActivityFetching || isActivityFetching === undefined ?
            <div>
                <Loader/>
            </div>:
        <div className="activity-bg-color">
            <div className="activity-page-table-binder-through-accounts">
                <div className="maximising-the-width">
                    {
                        props.shopUser && <div className="dropdown-for-activity dropdown-for-order pt-0 pl-5">
                            <Back
                            refreshToken={props.tokenDetails.refresh_token}
                            dieselCardUser={props.dieselCardUser}/>
                        </div>
                    }
                <div className="page-heads-title-for-all-accounts">
                    <div className="page-heads-title-for-all-accounts-img">
                         <img src="/static/your-activity.svg" />
                    </div>
                    <div className="page-heads-title-for-all-accounts-content">
                        <p className="m-0 font-styleBold">{t('Your Activity')}</p>
                    </div>
                    {sections.length > 0 && !props.shopUser ? <BreadCrumbs
                        sections={sections}
                        handleClick={(url: string) => props.history.push(url)}
                        backAction={() => props.history.goBack()} />
                        : null}
                </div>
                <div className="dropdown-for-activity">
                    <div className="dropdown-for-activity-sub-binder">
                        <p className="m-0 fornt-styleBold">{t('Show')}</p>
                    </div>
                    <div className="dropdown-for-activity-componet">
                    <CategoriesDropdown
                                automationId=""
                                label=""
                                dropdownOptions={SortByOptions}
                                defaultOption={SortByOptions.find(item => item.value === dropDownValue) || SortByOptions[0]}
                                onInputChange={data => handleChange(data)}
                            />
                    </div>
                </div>
                <div className="activity-table-binder">
            {currentData && pendingData && declinedData &&
             <AccountsTable
             currentData={currentData}
             pendingData={pendingData}
             declinedData={declinedData}
             token={props.tokenDetails?.access_token}
             refreshToken={props.tokenDetails?.refresh_token}/> }
            </div>   
            </div>
            </div>
        </div>
        }
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    duration: state.activity.activityDuration,
    currentBalance: state.ui.balance,
    currentData: state.activity.currentData,
    pendingData: state.activity.pendingData,
    declinedData: state.activity.declinedData,
    tokenDetails: state.join.tokens,
    global: state.global,
    isActivityFetching: state.activity.isActivityFetching
});

export default connect(mapStateToProps, {getCurrentBalance, getPendingBalance, updateActivityDuration, getDeclinedBalance, getTokens})(Activity);
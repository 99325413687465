import React, { ReactChild } from 'react';

interface IWrapper {
  url?: string;
  children: ReactChild;
}

const AnchorTagCheck = (props: IWrapper) => {
  return props.url ? (
    <a href={props.url} rel="noopener norefer">
      {props.children}
    </a>
  ) : (
    <>{props.children}</>
  );
};

export default AnchorTagCheck;

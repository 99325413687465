import React, { useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import AccountsOrders from '../ui-componets/accounts-orders';
import CategoriesDropdown from "../ui-componets/dropdown";
import BreadCrumbs from "../ui-componets/breadcrumbs";
import {getOrders, updateOrdersDuration} from "../modules/ui.ducks";
import { getTokens } from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import Back from "../ui-componets/Back";
import { useTranslation } from 'react-i18next';


interface IProps {
    getOrders:any
    ordersHistory:any
    updateOrdersDuration: any
    duration: any
    history: any
    tokenDetails: any
    global: any
    getTokens: any
    isOrderFetching: any
    countryInfo: any
    loginDetails: any
    shopUser?: boolean
    dieselCardUser?: boolean
}

function Orders(props:IProps) {
    const { t } = useTranslation();
    const [isLoader, setIsLoader] = useState(true);

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;

    const SortByOptions = [
        {
            id: 'Last_30_Days',
            text: t('Last 30 Days'),
            value: 'Last_30_Days',
        },
        {
            id: 'Last_3_Months',
            text: t('Last 3 Months'),
            value: 'Last_3_Months'
        },
        {
            id: 'Last_Year',
            text: t('Last Year'),
            value: 'Last_Year',
        }
    ];
  
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Query params in Orders page", QS.parse(window.location.search));
        // setTimeout(() => {
        if (QS.parse(window.location.search).code && !isSsoUser) {
            // since fetching token in this component can be overridden by other functions and give unexpected result
            props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/orders`);
        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/orders`, companyShortName: companyShortName });
        } else if (!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/orders`
        } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/orders?companyShortName=${companyShortName}`
        }
    // }, 2000);
    }, []);

    const [dropDownValue, setDropDownValue] = useState();
    const { getOrders, ordersHistory, updateOrdersDuration, duration, tokenDetails, isOrderFetching, countryInfo} = props;
    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Orders', className: 'child-breadcrumbs font-styleLight', content: t('Your Orders'), link: false },
    ]

    const handleChange = (data: any) =>{
        setDropDownValue(data.id);
         let durationValue = 30;
         console.log("data key", data);
         if(data.id === "Last_30_Days"){
            durationValue = 30
         } else if(data.id === "Last_3_Months"){
            durationValue = 90
         } else if(data.id === "Last_Year"){
            durationValue = 365
         } 
         updateOrdersDuration(durationValue);
    }

    useEffect(()=>{
        getOrders();
        setTimeout(() => {
            setIsLoader(false);
          }, 2000);
    },[duration, tokenDetails])    

    return (
        <div>
              { isOrderFetching || isOrderFetching === undefined ?
            <div>
                <Loader/>
            </div>:
        <div className="parent-order order-pb">
            <div className="set-pref pt-5">
                {
                    props.shopUser && <div className="dropdown-for-activity dropdown-for-order pt-0 pl-5">
                        <Back
                            refreshToken={props.tokenDetails.refresh_token}
                            dieselCardUser={props.dieselCardUser}/>
                    </div>
                }
                <div className="page-heads-title-for-all-accounts">
                    <div className="page-heads-title-for-all-accounts-img">
                        <img src="/static/orders.svg" />
                    </div>
                    <div className="page-heads-title-for-all-accounts-content">
                        <p className="m-0 font-styleBold">{t('Your Orders')}</p>
                    </div>
                    {sections.length > 0 && !props.shopUser ? <BreadCrumbs
                        sections={sections}
                        handleClick={(url: string) => props.history.push(url)}
                        backAction={() => props.history.goBack()} />
                        : null}
                </div>
                <div className="dropdown-for-activity dropdown-for-order">
                    <div className="dropdown-for-activity-sub-binder dropdown-for-order-sub-binder">
                        <p className="m-0 fornt-styleBold">{t('Show')}</p>
                    </div>
                    <div className="dropdown-for-activity-componet dropdown-for-order-componet">
                    <CategoriesDropdown
                                automationId=""
                                label=""
                                dropdownOptions={SortByOptions}
                                defaultOption={SortByOptions.find(item => item.value === dropDownValue) || SortByOptions[0]}
                                onInputChange={data => handleChange(data)}
                            />
                    </div>
                </div>
            <div className="your-orders">
            {ordersHistory && ordersHistory.map((item:any)=>  
                <AccountsOrders
                orderData={item}
                refreshToken={props.tokenDetails?.refresh_token}
                shopUser={props.shopUser}
                onlyDashboard={props.loginDetails?.onlyDashboard}
                countryInfo={countryInfo}
                />
            ) }
            </div>
            </div>
        </div>
    }
        </div>
    );
}
const mapStateToProps = (state: any) => ({
    duration: state.ui.ordersDuration,
    ordersHistory: state.ui.ordersHistory,
    tokenDetails: state.join.tokens,
    global: state.global,
    isOrderFetching: state.ui.isOrderFetching,
    countryInfo : state.ui.countryDetails,
    loginDetails : state.join.loginDetails,
});

export default connect(mapStateToProps, { getOrders, updateOrdersDuration, getTokens})(Orders);

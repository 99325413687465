import React, { CSSProperties } from 'react';

interface IProps {
  /**
   * Svg's width
   */
  width?: string;
  /**
   * Svg's height
   */
  height?: string;
  /**
   * Svg's stroke
   */
  stroke?: string;
  /**
   * Svg's fill
   */
  fill?: string;
  /**
   * Svg's classNames
   */
  className?: string;
  /**
   * Svg's style
   */
  style?: CSSProperties;
}

const TrashIcon = ({
  width,
  height,
  stroke,
  fill,
  className,
  style
}: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 34"
    className={className}
    style={style}
  >
    <g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth="1.2">
      <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
      <path strokeLinecap="round" d="M8.576 8v5m12.848-5v5" />
    </g>
  </svg>
);

TrashIcon.defaultProps = {
  width: '30',
  height: '34',
  stroke: '#4A4A4A',
  fill: '#FFC800',
  className: ''
};

export default TrashIcon;

import React from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 54
   */
  width?: string;
  /**
   * Svg's height
   * @default 40
   */
  height?: string;
  /**
   * Svg's fill
   * @default '#00B451'
   */
  fill?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
}

const MailIcon = ({ width, height, fill, className }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M4.538 37.91c-.432 0-.834-.126-1.195-.33l15.77-17.813 7.286 6.827c.173.163.388.246.601.246.213 0 .427-.083.6-.246l7.287-6.827 15.77 17.813c-.362.204-.764.33-1.196.33H4.538zM2.07 36.05c-.126-.346-.199-.723-.199-1.119V5.067c0-.437.09-.85.244-1.226l15.561 14.581L2.07 36.05zM49.461 2.087c.393 0 .762.102 1.099.272L27 24.432 3.44 2.36c.336-.17.706-.272 1.098-.272h44.923zM36.325 18.422l15.561-14.58c.153.374.244.788.244 1.225v29.866c0 .395-.073.77-.2 1.117L36.326 18.422zM53.999 5.067c0-1.302-.455-2.48-1.181-3.38-.013-.017-.016-.039-.028-.056-.013-.016-.03-.022-.043-.037C51.922.617 50.757 0 49.461 0H4.538C3.242 0 2.078.617 1.25 1.594c-.012.015-.03.021-.042.038-.013.016-.015.04-.028.057C.455 2.587 0 3.765 0 5.067v29.866c0 1.334.474 2.54 1.232 3.447.015.019.02.041.036.06.006.006.012.006.018.013C2.11 39.405 3.262 40 4.538 40h44.924c1.276 0 2.427-.597 3.252-1.548.006-.005.012-.007.017-.013.017-.016.021-.04.036-.057.76-.907 1.233-2.114 1.233-3.45V5.068z"
    />
  </svg>
);

MailIcon.defaultProps = {
  width: '54',
  height: '40',
  fill: '#00B451',
  className: ''
};

export default MailIcon;

import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import AccountsHeader from './ui-componets/accounts-header';
import AccountsFooter from './ui-componets/accounts-footer';
import { getBalance, getCountryInfo } from "./modules/ui.ducks";
import { logOut, getSelfServiceInfo, getCode } from './modules/join.ducks';

import 'semantic-ui-css/semantic.min.css';
import './styles/tailwind.css';
import './styles/index.css';
import './styles/custom.css';
import './styles/mobile.css';
import './styles/ipad.css';
import "./styles/rtl.css";
import Routes from "./Routes";

// @ts-ignore
import { RootState } from "./redux/store";
import Loader from './ui-componets/loader';

const App: React.FC = (props: any) => {
    props = {...props , shopUser: props?.join?.loginDetails?.shopOnly , dieselCardUser: props?.join?.loginDetails?.dieselCardUser }
    const location = useLocation();
    let countryCode = props?.join?.loginDetails?.userCountryCode || props?.join?.loginDetails?.countryCode || 'GB';
    const { getBalance, logOut, global, selfServiceInfo, getSelfServiceInfo, getCountryInfo } = props;

    const [fetchingCode, updateFetchingCode] = useState(false);
    useEffect(() => {
        getSelfServiceInfo();
    }, [props.join.tokens]);

    useEffect(() => {
        getBalance();
        getCountryInfo();
    }, [getBalance]);

    useEffect(() => {
        if(selfServiceInfo && selfServiceInfo.isSelfServiceUser && !selfServiceInfo.isPaymentCompleted){
            props.history.push('/NoAccess');
        }
    }, [selfServiceInfo]);

    useEffect(() => {
        // Anything in here is fired on component mount.
        const unlisten = props.history.listen((location: any, action: any) => {
            if (window.location.hostname == 'accounts.epoints.com')
                ReactGA.initialize('UA-41781197-8');
            else
                ReactGA.initialize('UA-41781197-1');
            ReactGA.pageview(window.location.pathname + window.location.search);
        });
        return () => {
            // Anything in here is fired on component unmount.
            unlisten();
        }
    }, [])

    const onURLChange = (url: string) => {
        props.history.push(url);
    }

    // get sso object from localStorage
    let ssoData = global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;

    const redirecrToShop = async () => {        
        if(props?.join?.loginDetails?.onlyDashboard) {
            window.location.href = ''
        }
        
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
          
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false);
                window.location.href = '';
            }
        }
    }

    const redirectingDomainSpecificPrivacy = async () => {
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}&intended_url=${global.SHOP_URL}/privacy-policy/`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
          
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}&redirect_to=${global.SHOP_URL}/privacy-policy/`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false);
                window.location.href = '';
            }
        }
    }

    const redirectingDomainSpecificterms = async () => {
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}&intended_url=${global.SHOP_URL}/terms-conditions/`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
          
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}&redirect_to=${global.SHOP_URL}/terms-conditions/`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false);
                window.location.href = '';
            }
        }
    }

    const redirectingDomainSpecificCookies = () => {
        let RedirectDomainName = 'https://www.eachperson.com/cookie-policy/';
        return RedirectDomainName;
    }

    const redirectingDomainSpecificFaq = async() => {
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}&intended_url=${global.SHOP_URL}/faqs/`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
          
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}&redirect_to=${global.SHOP_URL}/faqs/`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false)
                window.location.href = '';
            }
        }
    }

    const redirecrToEcahPerson = async() => {
        if (isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/sso?companyShortName=${companyShortName}&redirectUrl=hr/dashboard`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
          
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${global.EP_ADMIN_URL}/seamless-redirection?code=${userCode.authenticationCode}`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false)
                window.location.href = '';
            }
        }
    }
    const redirecrToAbout = () => {
        let RedirectDomainName = 'https://www.eachperson.com/about/';
        return RedirectDomainName;
    }
    const redirecrToContact = async() => {
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/sso?companyShortName=${companyShortName}&redirectUrl=contact-us`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
        
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        if (props?.shopUser){
                            window.location.href = `${props.global.SHOP_URL}/?code=${userCode?.authenticationCode}&redirect_to=${props.global.SHOP_URL}/contact-us/`;
                        }
                        else if(props?.join?.loginDetails?.onlyDashboard) {
                            window.location.href = `${props.global.EP_ADMIN_URL}/seamless-redirection?code=${userCode?.authenticationCode}&state=/contact-us`;
                        } else {
                            window.location.href = `${props.global.EP_ADMIN_URL}/seamless-redirection?code=${userCode?.authenticationCode}&state=/contact-us`;
                        }
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false)
                window.location.href = '';
            }
        }
        
    }

    const redirecrToDonate = async() => {
        if(isSsoUser) {
            window.location.href = `${global.EP_ADMIN_URL}/sso?companyShortName=${companyShortName}&redirectUrl=hr/donate`;
        } else {
            updateFetchingCode(true)
            try {
                let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
        
                if (userCode && typeof userCode === 'object') {
                    if ('authenticationCode' in userCode) {
                        window.location.href = `${props.global.EP_ADMIN_URL}/seamless-redirection?code=${userCode?.authenticationCode}&state=/hr/donate`;
                    } else {
                        updateFetchingCode(false)
                        window.location.href = '';
                    }
                } else {
                    updateFetchingCode(false)
                    window.location.href = '';
                }
            } catch (error) {
                updateFetchingCode(false)
                window.location.href = '';
            }
        }
    }

    const gotoLink = (tag: string) => {
        switch (tag) {
            case 'your-account':
                onURLChange('/account')
                break;
            case 'your-details':
                onURLChange('/details')
                break;
            case 'your-activity':
                onURLChange('/activity')
                break;
            case 'your-orders':
                onURLChange('/orders')
                break;
            case 'your-address':
                onURLChange('/addresses')
                break;
            case 'marketing-preferences':
                onURLChange('/preferences')
                break;
            case 'membership-details':
                onURLChange('/membership')
                break;
            case 'payment-details':
                onURLChange('/payments')
                break;
            case 'donate':
                redirecrToDonate();
                break;
            case 'work-profile':
                onURLChange('/profile')
                break;
            case 'epoints':
                redirecrToShop();
                break;
            case 'each-person':
                redirecrToEcahPerson();
                break;
            case 'logout':
                // clearTokens();
                logOut();
                break;
            default:
                props.history.push(`/${tag}`);
                break;
        }
    }
    const gotoFooterLink = (tag: string) => {
        switch (tag) {
            case 'terms':
                redirectingDomainSpecificterms();
                break;
            case 'privacy':
                redirectingDomainSpecificPrivacy();
                break;
            case 'cookie-policy':
                window.location.href = redirectingDomainSpecificCookies();
                break;
            case 'faq':
                redirectingDomainSpecificFaq();
                break;
            case 'about':
                window.location.href = redirecrToAbout();
                break;
            case 'epoints':
                redirecrToShop();
                break;
            case 'contact-us':
                redirecrToContact();
                break;
            default:
                props.history.push(`/${tag}`);
                break;
        }
    }
    const renderSimpleHeader = () => location.pathname.split('/')[1] === 'join'

    // Define a fallback component
    const FallbackComponent = () => <div>Loading...</div>;

    return (
        <>
            {fetchingCode ?
            <div><Loader/></div> :
            <>
                <main className="accounts-container" onClick={(e) => (e)}>
                    <div className="accounts-body-binder">

                        <section>
                            <AccountsHeader
                                simpleHeaderAccounts={renderSimpleHeader()}
                                handleLinkClick={(tag: string) => gotoLink(tag)} 
                                isGbUser={props.join.loginDetails && countryCode && countryCode === 'GB' ? true : false}
                                showBlock={(props.join && props.join.loginDetails && props.join.loginDetails.isBusinessUser == true) ||  (props.join.tokens && !props.join.tokens.access_token)  ? true : false }
                                token={props.join.tokens?.access_token}
                                refreshToken={props.join.tokens?.refresh_token}
                                shopUser={props.shopUser}
                                onlyDashboardUser={props?.join?.loginDetails?.onlyDashboard}
                                />
                            <Routes {...props} />
                        </section>
                    </div>
                </main>
                {/* <div className="helplines">
                    <HelplineStrip />
                </div> */}
                <div><AccountsFooter handleLinkClick={(tag: string) => gotoFooterLink(tag)}></AccountsFooter></div>
            </>
        }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    global: state.global,
    join: state.join,
    selfServiceInfo : state.join.selfServiceInfo
});

export default withRouter(connect(mapStateToProps, { logOut, getBalance, getCountryInfo, getSelfServiceInfo })(App));

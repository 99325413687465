import React, { useState } from 'react';
import classNames from 'classnames';
import RemoveAddressDialog from './removeAddressDialog';
import './styles.css';
import { useTranslation } from 'react-i18next';

export interface DeliveryAddressType {
  addressId: string;
  firstName: string;
  lastName: string;
  country: string | null;
  county: string | null;
  city: string | null;
  postCode: string | null;
  street: string | null;
  house: string | null;
  additionalInfo: string | null;
  phoneNo: string | null;
  preferredDeliveryAddress: boolean;
  billingAddress: boolean;
  customerFullAddress: string | null;
}

export interface IProps {
  /**
   * The currently logged in user
   */
  user: DeliveryAddressType;
  /**
   *  Indicates active address
   */
  isSelect?: boolean;
  /**
   *  Callback function for edit button
   */
  onEditAction?: (address: DeliveryAddressType) => void;
  /**
   * Callback function for remove button
   */
  onRemoveAction?: (address: DeliveryAddressType) => void;
  /**
   * callback function trigger while clicked address section
   * */
  onClick?: (address: DeliveryAddressType) => void;
  relativePath?: string;
}

function SavedAddress(props: IProps) {
  const { t } = useTranslation();
  const { user, onClick, onEditAction, onRemoveAction } = props;
  const isSelected = props.isSelect === undefined ? false : props.isSelect;
  // console.log('isSelected - ', isSelected);
  const userName = `${user!.firstName} ${user!.lastName}`;
  const userAddress: { [U: string]: string | null } = {
    numberAndStreet: user.house + ' ' + user.street,
    city: user.city,
    country: user.country,
    postalCode: user.postCode,
    contactNumber: user.phoneNo
  };
  let addressData = [];
  for (let key in userAddress) {
    addressData.push(userAddress[key]);
  }

  const [showDialog, setShowDialog] = useState(false);
  const openDialog = () => setShowDialog(true);
  const cancelDialog = () => setShowDialog(false);

  const address = addressData.map((item, index) => (
    <span
      className="inline-block w-full font-styleRegular tex-of-address"
      key={index}
      data-testid="address"
    >
      {item}
    </span>
  ));
  const onSelectAddress = () => {
    user && onClick && onClick(user);
  };
  const onEditAddress = () => {
    user && onEditAction && onEditAction(user);
  };
  const onRemoveAddress = () => {
    user && onRemoveAction && onRemoveAction(user);
    cancelDialog();
  };
  return (
    <div
      className={classNames(
        'border rounded py-6 px-5  cursor-pointer border-adress-card-fixes',
        { 'hover:border-eco-green border-grey': !isSelected },
        { 'border-eco-green': isSelected }
      )}
      data-testid="address-card-main-div"
    >
      <div className="block relative mr-2" onClick={onSelectAddress}>
        <span
          data-testid="user-name"
          className="inline-block w-5/6 font-styleBold overflow-hidden pr-5 pb-1 user-name-class"
        >
          {userName}
        </span>
        {isSelected && (
          <span
            data-testid="tick-selected"
            className="inline-block w-1/6 pin-r text-right tick-selected"
          >
            <img src={`${props.relativePath}/static/ticking.png`} alt="" />
          </span>
        )}
      </div>
      <div onClick={onSelectAddress}>{address}</div>
      {(props.onEditAction || props.onRemoveAction) && (
        <div className="relative mt-3 h-8">
          {props.onEditAction && (
            <p
              automation-id="edit-address"
              data-testid="edit-address"
              className="pin-l p-2 w-24 rounded-sm absolute text-base text-left editoption text-eco-green hover:text-black-primary font-styleRegular"
              onClick={onEditAddress}
            >
              {t('Edit')}
            </p>
          )}
          {showDialog && (
            <RemoveAddressDialog
              style={{ bottom: '3rem', right: '-6%', width: '112%' }}
              onConfirmRemove={onRemoveAddress}
              cancelDialog={cancelDialog}
            />
          )}
          {props.onRemoveAction && (
            <p
              automation-id="remove-address"
              data-testid="remove-address"
              className="pin-r p-2 w-24 rounded-sm text-red absolute text-base text-right remove-option font-styleRegular"
              onClick={openDialog}
            >
              {t('Remove')}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

// Set default props
SavedAddress.defaultProps = {
  relativePath: ''
};

export default SavedAddress;

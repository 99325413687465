import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import Button from '../button';
import cx from 'classnames';
import './styles.css';
import { useTranslation } from 'react-i18next';

export interface IProps {
  /**
   * callback function for while searching the post code
   */
  searchPostcode: (postCode: string) => Promise<IAddress[]>;
  /**
   * delivery address of user
   */
  address?: DeliveryAddressType;
  /**
   * callback function called when pressed cancel
   */
  onCancel?: () => void;
  /**
   * callback function called when add address button clicked
   */
  onAddAddress?: (formData: any) => void;
  errorMessage: string;
}

export interface IAddress {
  country: string;
  county: string;
  city: string;
  postcode: string;
  street: string;
  house: string;
}

export interface DeliveryAddressType {
  addressId: string;
  firstName: string;
  lastName: string;
  country: string | null;
  county: string | null;
  city: string | null;
  postCode: string | null;
  street: string | null;
  house: string | null;
  additionalInfo: string | null;
  phoneNo: string | null;
  preferredDeliveryAddress: boolean;
  billingAddress: boolean;
  customerFullAddress: string | null;
}

const generateAddress = ({ house, street, city, country, county }: IAddress) =>
  `${house}, ${street} ${city} ${county}, ${country}.`;

const FloatedLableInput = ({
  placeholder,
  name,
  className,
  readonly,
  ...props
}: any) => {
  const hasValue = !!props.value;
  return (
    <div
      className={`ui fluid input mb-4 relative floated-lable-input ${className}`}
    >
      <input
        className="labeling font-styleRegular"
        name={name}
        readOnly={readonly}
        {...props}
      />
      <label
        className={cx('absolute', 'label-up', {
          'click-outside-floating-label': hasValue
          // focus: hasValue,
          // 'bg-white': hasValue,
          // 'z-N1': !hasValue,
          // 'z-10': hasValue
        })}
        htmlFor={name}
      >
        {placeholder}
      </label>
    </div>
  );
};

function AddAddress(props: IProps) {
  const { t } = useTranslation();
  const { address, errorMessage } = props;
  const [disableFindAddress, setdisableFindAddressState] = useState(true);
  const [dropdownOptions, setDropdownOptions] = useState<IAddress[]>([]);
  const [isSelectAddress, setIsSelectAddress]: any = useState(false);
  const [saveAddressDisables, setSaveAddressDisables] = useState(false);
  // const [formData, setFormData]: any = useState({});
  const [formData, setFormData]: any = useState({
    firstName: '',
    lastName: '',
    postCode: '',
    phoneNo: '',
    house: '',
    street: '',
    city: '',
    country: '',
    preferredDeliveryAddress: ''
  });
  const setDropDownOptions = (res: IAddress[]) => {
    if (res && res.length > 0) {
      res.forEach((addressObject: any, i: number) => {
        const address: string = generateAddress(addressObject);
        addressObject['key'] = address;
        addressObject['value'] = i;
        addressObject['text'] = address;
      });
      setDropdownOptions(res);
    }
  };

  const { searchPostcode } = props;

  useEffect(() => {
    if (address) {
      setFormData({ ...address });
      setdisableFindAddressState(false);
      setIsSelectAddress(true);

      setDropdownOptions([]);
      // @ts-ignore
      async function fetchAddress(postCode: string) {
        const res = await searchPostcode(postCode);
        setDropDownOptions(res);
      }
      fetchAddress(address.postCode || '');
    } else {
      setdisableFindAddressState(true);
      setDropdownOptions([]);
      setIsSelectAddress(false);
    }
  }, [address, searchPostcode]);

  const onTextInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onToggleCheckBox = ({
    target: { name, checked }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [name]: checked });
  };

  const onFindAddressChange = (e: any) => {
    onTextInputChange(e);
    const UKGovPostcodeRegex = /^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$/;
    const postcode = e.target.value;
    if (UKGovPostcodeRegex.test(postcode.toUpperCase()))
      setdisableFindAddressState(false);
    else setdisableFindAddressState(true);
  };

  const onFindAddressClick = (e: any) => {
    e.preventDefault();
    // console.log('e :: ', e);
    props.searchPostcode(formData.postCode).then((res: any) => {
      setDropDownOptions(res);
    });
  };
  const onSelectAddressChange = (_0: any, data: any) => {
    if (data.value !== undefined) {
      const address: object = dropdownOptions[data.value];
      setFormData({ ...formData, ...address });
      setIsSelectAddress(true);
    } else {
      setIsSelectAddress(false);
    }
  };
  const onCancel = () => {
    props.onCancel && props.onCancel();
  };

  const onAddAddress = () => {
    props.onAddAddress && props.onAddAddress(formData);
    setSaveAddressDisables(true);
  };
  let errors: any[] = [];
  const requiredField: string[] = [
    t('firstName'),
    t('lastName'),
    t('postCode'),
    t('phoneNo')
  ];
  const validateForm = (requiredField: string[]): boolean => {
    requiredField.forEach((field, i) => {
      if (!formData[field]) {
        errors[i] = `${field} ${t('cannot be empty')}`;
      }
    });

    return errors.length > 0;
  };

  return (
    <div className="ad-binder">
      <div className="container mx-auto editing-adress-container">
        <form>
          <div>
            <FloatedLableInput
              name="firstName"
              onChange={onTextInputChange}
              // fluid
              value={formData.firstName}
              placeholder={t("First name *")}
            />
            <FloatedLableInput
              name="lastName"
              onChange={onTextInputChange}
              // fluid
              value={formData.lastName}
              placeholder={t("Last name *")}
            />
          </div>
          <div className="flex">
            <FloatedLableInput
              name="postCode"
              aria-label="postCode"
              placeholder={t("Post code *")}
              className="w-1/2 mr-2"
              value={formData.postCode}
              onChange={onFindAddressChange}
            />
            <Button
              floated="right"
              dataTestId="find-address-button"
              extraClassNames="w-1/2 ml-2 mb-4"
              disabled={disableFindAddress}
              onClick={(e: any) => onFindAddressClick(e)}
              style={{ marginBottom: '1rem', padding: 0 }}
              label={t("Find address")}
              type="secondary"
            />
          </div>
          {dropdownOptions && dropdownOptions.length > 0 && (
            <div>
              <Dropdown
                className="mb-4 find-address-dropdown"
                data-testid="find-address-dropdown"
                fluid
                clearable
                selectOnBlur={false}
                onChange={onSelectAddressChange}
                placeholder={t("Select your address")}
                selection
                options={dropdownOptions}
              />
            </div>
          )}
          {isSelectAddress && (
            <div>
              <FloatedLableInput
                // fluid
                name="house"
                aria-label="house"
                placeholder={t("House number")}
                value={formData.house}
                onChange={onTextInputChange}
              />
              <FloatedLableInput
                // fluid
                placeholder={t("Street Name")}
                name="street"
                value={formData.street}
                onChange={onTextInputChange}
              />
              <FloatedLableInput
                // fluid
                placeholder={t("Town/City")}
                name="city"
                value={formData.city}
                onChange={onTextInputChange}
              />
              <FloatedLableInput
                // fluid
                placeholder={t("Country")}
                name="country"
                value={formData.country}
                onChange={onTextInputChange}
                readonly={true}
              />
              <FloatedLableInput
                name="phoneNo"
                onChange={onTextInputChange}
                value={formData.phoneNo}
                // fluid
                placeholder={t("Mobile Number *")}
              />
            </div>
          )}
          <div className="ui checkbox relative mt-4 mb-2 w-full address-checkbox">
            <input
              className="absolute leading-tight h-8 w-8 pin-t pin-l z-10 cursor-pointer outline-none"
              checked={formData.preferredDeliveryAddress}
              onChange={onToggleCheckBox}
              name="preferredDeliveryAddress"
              type="checkbox"
            />
            <label className="label-for-check check-lbl">
              {t('Use this as your preferred address')}
            </label>
          </div>
        </form>
        {errorMessage.length > 0 ? (
          <div className="addres-errors-msgs">
            <div className="adres-err">
              <p> {errorMessage}</p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-8 address-form-btn">
        <Button
          // extraClassNames=""
          floated="left"
          label={t("Cancel")}
          width="48%"
          type="secondary"
          onClick={onCancel}
        />

        {isSelectAddress && (
          <Button
            // extraClassNames=""
            floated="right"
            label={t("Save address")}
            width="48%"
            disabled={validateForm(requiredField) || saveAddressDisables}
            onClick={onAddAddress}
          />
        )}
      </div>
    </div>
  );
}

// Set default props
AddAddress.defaultProps = {
  errorMessage: ''
};

export default AddAddress;

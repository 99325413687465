import React, { useEffect, useState }  from 'react';
import {connect} from 'react-redux';
import axios, { AxiosError } from 'axios';
import AccountsProfile from '../ui-componets/accounts-profile';
import BreadCrumbs from "../ui-componets/breadcrumbs";
import { getProfileData, postProfileData } from '../modules/details.ducks';
import {getTokens, credentialChangeLogout} from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Back from "../ui-componets/Back";
import { useTranslation } from 'react-i18next';

interface IProps {
    getProfileData : any
    postProfileData: any
    personalDetails: any
    accountDetails: any
    apiEnd:any
    history: any
    tokenDetails:any
    global:any
    getTokens: any
    getBalance:any
    isProfileFetching: any
    credentialChangeLogout: any
    dieselCardUser?: boolean
    shopUser?: boolean
}

function Details(props: IProps) {
    const { t } = useTranslation();
    const { shopUser } = props
    const [isLoader, setIsLoader] = useState(false);
    const { credentialChangeLogout } = props;
    const notify = (msg: any) => toast.success(msg, {
        position: "top-right",
        closeButton: false,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "taost-bg-green",
        icon: ({}) =>  <img src="/static/toast-tick.svg"/>
    });

    const emailNotify = (msg: any) => toast.success(msg, {
        position: "top-right",
        closeButton: false,
        autoClose: 7000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "taost-bg-green",
        icon: ({}) =>  <img src="/static/toast-tick.svg"/>
    });

    const errNotify = (msg: any) => toast.error(msg, {
        position: "top-right",
        closeButton: false,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "taost-bg-red",
        // icon: ({}) =>  <img src="/static/toast-tick.svg"/>
    });  

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Query params in Details", QS.parse(window.location.search));
        // setTimeout(() => {
        if (QS.parse(window.location.search).code && !isSsoUser) {
            // since fetching token in this component can be overridden by other functions and give unexpected result
            props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/details`);
        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/details`, companyShortName: companyShortName });
        } else if (!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/details`
        } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/details?companyShortName=${companyShortName}`
            // props.history.push(`/${QS.parse(window.location.search).redirectUrl}?companyShortName=${companyShortName}`);
        }
    // }, 2000);

    }, []);

      useEffect(() => {
        window.scrollTo(0, 0);
        getProfileData();
        console.log("profiledata");
      }, [props.tokenDetails]);

    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Details', className: 'child-breadcrumbs font-styleLight', content: t('Your Details'), link: false },
    ]
    const {getProfileData,postProfileData, personalDetails, accountDetails, apiEnd, isProfileFetching} = props;

    useEffect(() => {
        getProfileData();
        console.log("Global object", global)
        let formData ={
            marketingPrefEmail: true,
            marketingPrefSMS: false,
            privacyAccepted: true,
            tncAccepted: true
        }
    }, []);

    const handleFormSubmit = (data:any) => {
        let bodyFormData = new FormData();
        bodyFormData.append('personalDetails.firstName', data.firstName ? data.firstName : '');
        bodyFormData.append('personalDetails.lastName', data.lastName ? data.lastName : '');
        bodyFormData.append('personalDetails.title', data.title);
        bodyFormData.append('personalDetails.gender', data.gender);
        bodyFormData.append('personalDetails.dob', data.dob);
        axios({
            withCredentials: true,
            method: 'post',
            url: `${apiEnd}/rest/my-account/profile/save/personalDetails/V2`,
            data: bodyFormData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: props.tokenDetails.access_token //the token is a variable which holds the token
              }
        })
        .then(function (response:any) {
            setIsLoader(true);
            getProfileData();
            notify(t('Name Change Saved!'));
        })
        .catch(function (error:any) {
            console.log(error);
        });
    }



    const handleEmailFormSubmit = (data:any) => {
        console.log("EMail is", data)
        let bodyFormData = new URLSearchParams();
        bodyFormData.append('accountDetails.email', data.Email);
        axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json, text/plain, */*',
                    Authorization: props.tokenDetails.access_token //the token is a variable which holds the token
            },
            withCredentials: true,
            method: 'POST',
            url: `${apiEnd}/rest/my-account/profile/save/email/V2`,
            data: bodyFormData
        })
        .then(function (response:any) {
            setIsLoader(true);
            emailNotify('Your email change will be complete when you have followed the steps in the email we have just sent to your new email address. You will be promptly logged out!');
            setTimeout(() => {
               credentialChangeLogout()
            }, 2000)
        })
        .catch(function (error) {
            if (error.response) {
                errNotify(error.response.data.message);
            } 
        })
    }

    const handlePasswordSubmit = (data:any) => {
        let bodyFormData = new URLSearchParams();
        bodyFormData.append('accountDetails.existingPassword', data.password);
        bodyFormData.append('accountDetails.newPassword', data.newPassword);

        axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json, text/plain, */*',
                Authorization: props.tokenDetails.access_token
            },
            withCredentials: true,
            method: 'POST',
            url: `${apiEnd}/rest/my-account/profile/save/password/V2`,
            data: bodyFormData
        })
        .then(function (response:any) {
            setIsLoader(true);
            notify('Password Change Saved. You will be promptly logged out!');
            setTimeout(() => {
                credentialChangeLogout()
            }, 2000)
        })
        .catch(function (error) {
                if (error.response) {
                    errNotify(error.response.data.message);
                } 
            })
    }



    return (
        <div>
            { (isProfileFetching && !isLoader) || isProfileFetching === undefined ?
                <div>
                    <Loader/>
                </div>:
                <div className="AccountsProfile-layout">
                    <ToastContainer />
                    <div className="AccountsProfile-acc-page">
                        <div className="maximising-the-width">
                            {
                                props.shopUser && <div className="dropdown-for-activity dropdown-for-order pt-0 pl-5">
                                    <Back
                                        refreshToken={props.tokenDetails.refresh_token}
                                        dieselCardUser={props.dieselCardUser}/>
                                </div>
                            }
                            <div className="page-heads-title-for-all-accounts">
                                <div className="page-heads-title-for-all-accounts-img">
                                    <img src="/static/your-deatils-main.svg" />
                                </div>
                                <div className="page-heads-title-for-all-accounts-content">
                                    <p className="m-0 font-styleBold">{t('Your Details')}</p>
                                </div>
                                {sections.length > 0 && !props.shopUser ? <BreadCrumbs
                                    sections={sections}
                                    handleClick={(url: string) => props.history.push(url)}
                                    backAction={() => props.history.goBack()} />
                                    : null}
                            </div>
                            {  personalDetails &&  <AccountsProfile firstName={personalDetails.firstName}
                                lastName={personalDetails.lastName}
                                email={accountDetails.email}
                                ssoUser={isSsoUser}
                                onFormSubmit = { (data) => handleFormSubmit(data)}
                                onConfirmEmailSave = {(data)=> handleEmailFormSubmit(data)}
                                onPasswordChangeSave = {(data) => handlePasswordSubmit(data)}
                            />
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}


const mapStateToProps = (state: any) => ({
    personalDetails : state.details.profileData.personalDetails,
    accountDetails : state.details.profileData.accountDetails,
    tokenDetails: state.join.tokens,
    apiEnd : state.global.API_PROD_END_POINT,
    global: state.global,
    isProfileFetching: state.details.isProfileFetching
});

export default connect(mapStateToProps, {getProfileData, postProfileData, getTokens, credentialChangeLogout})(Details);
import React from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 21
   */
  width?: string;
  /**
   * Svg's height
   * @default 24
   */
  height?: string;
  /**
   * Svg's fill
   * @default '#4A4A4A'
   */
  fill?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
}

const YouTubeIcon = ({ width, height, fill, className }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <defs>
      <path id="prefix__a" d="M0 0.879L20.259 0.879 20.259 14.999 0 14.999z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 9)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          fill={fill}
          d="M18.75 8.976h-2.448v1.198c0 .625.204.938.625.938.302 0 .476-.164.547-.491.01-.067.027-.338.027-.83h1.249v.179c0 .395-.015.666-.026.788-.041.272-.137.518-.286.734-.34.491-.841.733-1.481.733-.641 0-1.128-.23-1.481-.692-.26-.338-.394-.87-.394-1.589V7.578c0-.723.118-1.251.379-1.592.353-.463.84-.694 1.466-.694.614 0 1.1.231 1.444.694.257.341.379.869.379 1.592v1.398zm-4.621 1.128c0 .625-.042 1.075-.123 1.361-.164.503-.491.759-.98.759-.434 0-.854-.241-1.264-.747v.655h-1.224V3.064h1.224v2.961c.395-.487.815-.733 1.265-.733.488 0 .815.256.979.762.082.273.123.719.123 1.359v2.691zm-4.65 2.027H8.253v-.737c-.486.559-.95.83-1.4.83-.394 0-.666-.159-.788-.502-.067-.204-.108-.527-.108-1.005V5.374h1.225v4.975c0 .287 0 .437.01.477.03.19.122.286.287.286.246 0 .502-.189.774-.574V5.374h1.225v6.757zm-3.7-7.792H4.312v7.792H2.95V4.339H1.51V3.064H5.78v1.275zm14.13-1.188c-.248-1.078-1.13-1.873-2.19-1.991-2.512-.281-5.055-.282-7.586-.281-2.531-.001-5.074 0-7.587.281-1.06.119-1.941.914-2.19 1.991C.005 4.685 0 6.36 0 7.94c0 1.58 0 3.254.354 4.788C.6 13.806 1.48 14.6 2.543 14.719c2.512.28 5.054.282 7.586.28 2.532.002 5.074 0 7.587-.28 1.059-.119 1.94-.913 2.189-1.991.353-1.534.355-3.208.355-4.788s.002-3.255-.351-4.789z"
          mask="url(#prefix__b)"
        />
      </g>
      <path
        fill={fill}
        d="M16.916 15.394c-.41 0-.614.313-.614.937v.625h1.223v-.625c0-.624-.204-.937-.61-.937M12.372 15.394c-.201 0-.405.096-.61.298v4.118c.204.205.409.302.61.302.354 0 .533-.302.533-.911v-2.885c0-.609-.18-.922-.533-.922M13.056 9.256c.452 0 .921-.273 1.414-.838v.744h1.236V2.334H14.47v5.22c-.275.387-.535.578-.782.578-.165 0-.264-.098-.29-.29-.015-.04-.015-.19-.015-.48V2.333H12.15v5.4c0 .482.04.808.108 1.015.126.346.4.507.799.507M4.442 5.452v3.71h1.372v-3.71L7.464 0H6.078l-.937 3.597L4.167 0H2.723c.29.848.59 1.702.88 2.553.44 1.277.715 2.24.84 2.898M8.714 4.313c0-.632.19-.947.59-.947.399 0 .59.315.59.947v2.872c0 .632-.191.948-.59.948-.4 0-.59-.316-.59-.948V4.313zm.59 4.943c.62 0 1.1-.232 1.443-.698.26-.342.385-.881.385-1.606V4.56c0-.73-.125-1.264-.385-1.608-.342-.468-.823-.701-1.443-.701-.616 0-1.098.233-1.441.7-.262.345-.386.879-.386 1.609v2.39c0 .726.124 1.265.386 1.607.343.466.825.698 1.44.698z"
      />
    </g>
  </svg>
);

YouTubeIcon.defaultProps = {
  width: '21',
  height: '24',
  fill: '#4A4A4A',
  className: ''
};

export default YouTubeIcon;

import {APIMdl} from "../modules/api.ducks";
import {UiMdl} from "../modules/ui.ducks";
import {ActivityMdl} from "../modules/activity.ducks";
import {SalaryMdl} from "../modules/salary.ducks";
import {DetailsMdl} from "../modules/details.ducks";
import {AddressMdl} from "../modules/addresses.ducks";
import {JoinMdl} from "../modules/join.ducks";

export default [
    ...APIMdl,
    ...UiMdl,
    ...ActivityMdl,
    ...SalaryMdl,
    ...DetailsMdl,
    ...AddressMdl,
    ...JoinMdl,
];

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../ui-componets/button';
import {joinInitRequest, updateJoinStep, updateJoinSuccessMessage, updateJoinErrMsg } from '../../modules/join.ducks'
import Loader from '../../ui-componets/loader';
import { useTranslation } from 'react-i18next';

interface IProps {
    joinInitRequest: any
    joinInitSuccess: any
    updateJoinStep: any
    updateJoinSuccessMessage: any
    joinEmailError: any
    updateJoinErrMsg: any
    global : any
  }

const FloatedLableInput = ({
    placeholder,
    name,
    className,
    readonly,
    ...props
}: any) => {
    const hasValue = !!props.value;
    return (
        <div
            className={`ui fluid input mb-4 relative floated-lable-input ${className}`}
        >
            <input className="labeling font-styleRegular" name={name} placeholder={placeholder} {...props} />
        </div>
    );
};
const joinFormInitialState = {
    firstName: undefined,
    lastName: undefined ,
    email: undefined,
  }
function JoinDetails(props: IProps) {
    const { t } = useTranslation();
  const [isLoader, setIsLoader] = useState(true);
  const [joinFormData, setJoinFormData]: any = useState(joinFormInitialState);
  const [regExEmail, setRegEx] = useState(false);
  const {joinInitSuccess, joinEmailError, updateJoinErrMsg} = props

  useEffect(() => {
    props.updateJoinStep(0);
    props.updateJoinSuccessMessage(false);
    updateJoinErrMsg(false);
    setTimeout(() => {
        setIsLoader(false);
        window.scrollTo(0, 0);
      }, 2000);

}, []);

  const onJoinTextInputChange = (e: any) => {
    setJoinFormData({...joinFormData, [e.target.name]: e.target.value });
    if (e.target.name === 'email') {
        regularExpForEmail(e);
        updateJoinErrMsg(false)
      }
  };

  const regularExpForEmail = (e: any) => {
    let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const addEmail = e.target.value;
    if (!pattern.test(addEmail)) {
      setRegEx(true);
    } else {
      setRegEx(false);
    }
  };

  const joinSubmit = () => {
    setIsLoader(true);
      props.joinInitRequest(joinFormData);
      setTimeout(() => {
        setIsLoader(false);
        window.scrollTo(300, 500);
      }, 2500);
  }
  const redirecrToAbout = () => {
    window.location.href = `https://www.eachperson.com/about/`;
}

  const signInRedirect = () => {
    window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/account`
   }


    return (
        <div>
        { isLoader?
      <div>
          <Loader/>
      </div>:
        <div className="join-details">
            <div className="join-details-sub-binder">
                <div className="join-details-head">
                    <div className="join-head-para">
                        <p className="text-black-primaryone text-center font-styleBold size-big-join head-j-main">{t('Always better off with Epoints.com.')}</p>
                        <p className="text-black-primaryone text-center font-styleBold size-big-join head-j-sub desktop-para-join-head">{t('JUST £24 A YEAR')} <span className="font-styleBold size-big-join text-orange-primaryone ">({t(`That’s £2 per month!`)})</span></p>
                        <p className="text-black-primaryone text-center font-styleBold size-big-join head-j-sub mobile-para-join-head">{t('JUST £24 A YEAR')}</p> <p className="text-center font-styleBold size-big-join head-j-sub  text-orange-primaryone mobile-para-join-head">({t(`That’s £2 per month!`)})</p>

                        <p className="font-styleRegular text-center text-black-primaryone size-small-join">{t('After an initial, one off joining fee of £100')}</p>
                        <a className="cursor-pointer font-styleBold" onClick={redirecrToAbout}><p className="font-styleRegular text-center font-sixteen color-green-primary lear-join">{t('Learn more')}</p></a>
                    </div>
                </div>
                {/* box */}
                <div className="join-box">
                    <div className="join-sub-box">
                        <div className="join-box-header">
                            <div className="join-box-sub-head">
                                <p className="text-black-primaryone font-styleBold size-big-join text-center">{t('Join')}</p>
                            </div>
                        </div>
                        <div className="join-box-body">
                            {!joinInitSuccess ?
                            <div className="join-box-body-sub">
                                <p className="font-styleBold text-center text-black-primaryone size-small-join">{t('Enter your details below')}</p>
                                <p className="font-styleregular text-center text-black-primaryone size-small-join join-para-pad">{t('Let us know your name and email address so that we can set up your account and get you verified.')}</p>
                                <div className="join-input-forms">
                                    <div className="join-input-forms-sub">
                                        {/* Email Address */}
                                        <div className="form-sub-cont frm-sb">
                                            <div className="input-box-acc">
                                                <FloatedLableInput
                                                    name="email"
                                                    placeholder={t("Email")}
                                                    readOnly={false}
                                                    value={joinFormData.email}
                                                    onChange={onJoinTextInputChange}
                                                    className="default-email-field-uneditable"
                                                />
                                            </div>
                                        
                                            <div className="input-box-acc">
                                                <FloatedLableInput
                                                    name="firstName"
                                                    placeholder={t("First name")}
                                                    readOnly={false}
                                                    value={joinFormData.firstName}
                                                    onChange={onJoinTextInputChange}
                                                    className="default-email-field-uneditable"
                                                />
                                            </div>
                                            <div className="input-box-acc">
                                                <FloatedLableInput
                                                    name="lastName"
                                                    placeholder={t("Last name")}
                                                    readOnly={false}
                                                    value={joinFormData.lastName}
                                                    onChange={onJoinTextInputChange}
                                                    className="default-email-field-uneditable"
                                                />
                                            </div>
                                                     {/* Validations */}
                                            {regExEmail &&  (
                                                <div className="addres-errors-msgs">
                                                <div className="adres-err">
                                                    <p className="text-center">{t('Please enter a valid email address')}</p>
                                                </div>
                                                </div>
                                            )}
                                            {joinEmailError &&  (
                                                <div className="addres-errors-msgs">
                                                <div className="adres-err">
                                                    <p className="text-center">{t('User already registered.')}</p>
                                                </div>
                                                </div>
                                            )}
                                            <div className="join-btn">
                                            <Button
                                                width={247}
                                                
                                                label={t("Join now")}
                                                onClick={joinSubmit}
                                                type="primary"
                                                disabled={regExEmail 
                                                || joinFormData.firstName == undefined
                                                || joinFormData.firstName == ''
                                                || joinFormData.lastName == undefined
                                                || joinFormData.lastName == ''
                                                || joinFormData.email == undefined
                                                || joinFormData.email == ''
                                                || joinEmailError 
                                                }
                                                />
                                            </div>
                                            {joinEmailError && (
                                            <Button
                                                width={247}
                                                
                                                label={t("Sign in")}
                                                onClick={signInRedirect}
                                                type="primary"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="join-box-body-sub text-center">
                                <p className="font-styleBold text-black-primaryone size-small-join">{t('Your account has been created')}</p>
                                <p className="font-styleregular text-black-primaryone size-small-join join-para-pad width-fine">{t(`We've sent you an email. You'll need to verify your email address before registration is complete.`)}</p>
                                <div className="join-input-forms">
                                    <div className="join-input-forms-sub">
                                        <div className="form-sub-conts">
                                             <img src="/static/msg-env.svg" />
                                        </div>
                                        <div className="mail-cke">
                                            <a className="cursor-auto"><p className="font-styleregular text-black-primaryone size-small-join">{t('Check your email')}</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    joinEmailError: state.join.joinError,
    joinInitSuccess: state.join.joinSuccesss,
    global: state.global
});

export default connect(mapStateToProps, {joinInitRequest, updateJoinSuccessMessage, updateJoinStep, updateJoinErrMsg})(JoinDetails);

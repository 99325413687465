import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getGdpr, gdprUpdate } from "../modules/ui.ducks";
import BreadCrumbs from "../ui-componets/breadcrumbs";
import AccountsMarketingPreferences from '../ui-componets/accounts-marketing-preferences';
import { getTokens } from "../modules/join.ducks";
import QS from "query-string";
import Loader from '../ui-componets/loader';
import { ToastContainer } from 'react-toastify';
import Back from "../ui-componets/Back";
import { useTranslation } from 'react-i18next';

interface IProps {
    gdprUpdate: any
    getGdpr: any
    gdprValues: any
    history: any
    tokenDetails: any
    global: any
    getTokens: any
    isMarketingDetailsFetching: any
    shopUser?: boolean
    dieselCardUser?: boolean
}
function Preferences(props: IProps) {
    const { t } = useTranslation();

    // get sso object from localStorage
    let ssoData = props.global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;
        
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Query params in Prefernces", QS.parse(window.location.search));
        // setTimeout(() => {
        if (QS.parse(window.location.search).code && !isSsoUser) {
            // since fetching token in this component can be overridden by other functions and give unexpected result
            props.history.push(`/seamless-login?code=${QS.parse(window.location.search).code}&redirectURL=/preferences`);
        } else if (QS.parse(window.location.search).code && isSsoUser) {
            console.log("QS.parse(window.location.search).code && isSsoUser");
            props.getTokens({ code: QS.parse(window.location.search).code, redirectURL: `/preferences`, companyShortName: companyShortName });
        } else if (!isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            window.location.href = `${props.global.COGNITO_URL}${window.location.origin}/preferences`
        } else if (isSsoUser && props.tokenDetails && !props.tokenDetails.access_token) {
            console.log("isSsoUser && props.tokenDetails && !props.tokenDetails.access_token");
            window.location.href = `${props.global.ACCOUNT_SSO_URL}${window.location.origin}/preferences?companyShortName=${companyShortName}`
        }
    // }, 2000);
    }, []);

    const { getGdpr, gdprUpdate, gdprValues, isMarketingDetailsFetching } = props;
    const sections = [
        { key: 'Home', content: t('Your Account'), className: 'parent-breadcrumbs font-styleLight', href: '/', link: true },
        { key: 'Preferences', className: 'child-breadcrumbs font-styleLight', content: t('Marketing Preferences'), link: false },
    ]

    useEffect(() => {
        getGdpr();
    }, [props.tokenDetails]);

    const handleGdprUpdate = (email: any, userInteractedForEmailSubscription: any, sms: any) => {
        /**
         * Ticket: https://iat-technology.atlassian.net/browse/WS-709
         * user enters to this page in order to update email or sms subscriptions.
         * This page is not seen only on first time login of the user so
         * here, userInteractedForEmailSubscription is always set to true when the user toggles the email preferences
         * to determine the user has interacted for EMail subscription
         * 
         * so that the sticky view for GDPR signup won't display on each person pages
         */
        let formData = {
            marketingPrefEmail: email,
            marketingPrefSMS: sms,
            privacyAccepted: gdprValues.privacyAccepted,
            tncAccepted: gdprValues.tncAccepted,
            userInteractedForEmailSubscription: userInteractedForEmailSubscription || gdprValues.userInteractedForEmailSubscription
        }
        gdprUpdate(formData);
    }

    return (
        <div>
        { isMarketingDetailsFetching || isMarketingDetailsFetching === undefined ?
      <div>
          <Loader/>
      </div>:
    <div className="preference-main-wrap">
        <ToastContainer />
       <div className="set-pref">
           {
               props.shopUser && <div className="dropdown-for-activity dropdown-for-order pt-0 pl-5">
                   <Back
                    refreshToken={props.tokenDetails.refresh_token}
                    dieselCardUser={props.dieselCardUser}/>
               </div>
           }
                <div className="page-heads-title-for-all-accounts">

                    <div className="page-heads-title-for-all-accounts-img">
                        <img src="/static/pref.svg" />
                    </div>
                    <div className="page-heads-title-for-all-accounts-content">
                        <p className="m-0 font-styleBold">{t('Marketing Preferences')}</p>
                    </div>
                    {sections.length > 0  && !props.shopUser ? <BreadCrumbs
                        sections={sections}
                        handleClick={(url: string) => props.history.push(url)}
                        backAction={() => props.history.goBack()} />
                        : null}
                </div>
                <div className="AccountsProfile-layout market-page-pref">
                    {gdprValues && <AccountsMarketingPreferences optForEmail={gdprValues.marketingPrefEmail} optForSms={gdprValues.marketingPrefSMS} updateGdpr={handleGdprUpdate} />}
                </div>
            </div>
        </div>
}
</div>
    );
}

const mapStateToProps = (state: any) => ({
    gdprValues: state.ui.gdpr,
    tokenDetails: state.join.tokens,
    global: state.global,
    isMarketingDetailsFetching: state.ui.isMarketingDetailsFetching
});

export default connect(mapStateToProps, { getGdpr, gdprUpdate, getTokens })(Preferences);

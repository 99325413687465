import React from 'react';
import { Logo } from '../icons';
import './styles.css';
import Button from '../button';
import { useTranslation } from 'react-i18next';

interface IProps {
  relativePath?: string;
  handleBack?: () => void;
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonLink?: string;
}

function InterruptScreen(props: IProps) {
  const { t } = useTranslation(); // Moved inside the component function

  const handleBack = (e: any) => {
    props.handleBack && props.handleBack();
  };

  // Use translation function for default props if not provided
  const title = props.title || t('No Access');
  const description = props.description || t('You don’t currently have access to this area on Epoints.');
  const buttonLabel = props.buttonLabel || t('Back to Epoints');

  return (
    <div className="interupt-screen">
      <div className="interupt-screen-bg">
        <div className="bg-for-box">
          <div className="bg-for-box-sub">
            <div className="header-interrupt text-center">
              <Logo width="108px" />
            </div>
            <div className="bdr-hr"></div>
            <div className="inter-err-text">
              <p className="font-styleBold font-twenty blac-prm">{title}</p>
            </div>
            <div className="interupt-cross">
              <img
                src={`${props.relativePath}/static/interrupt-cross.svg`}
                alt=""
              />
            </div>
            <div className="inter-es-text">
              <p>{description}</p>
            </div>
            <div className="btn-for-interrupt">
              <Button
                extraClassNames="sm-max:w-full clearall-button"
                width={'234px'}
                label={`${buttonLabel}`}
                onClick={handleBack}
                type="secondary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Set default props
InterruptScreen.defaultProps = {
  relativePath: ''
};

export default InterruptScreen;

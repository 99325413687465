import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import Button from '../button';
import { FiX } from 'react-icons/fi';
import { Modal } from 'semantic-ui-react';

import './styles.css';
import { useTranslation } from 'react-i18next';

interface accountsProfileProps {
  relativePath?: string;
  handleLinkClick?: any;
  firstName: string;
  lastName: string;
  title?: string;
  gender?: string;
  dob?: number;
  email: string;
  ssoUser: boolean;
  onFormSubmit: (emailformData: any) => void;
  onConfirmEmailSave?: (emailformData: any) => void;
  onPasswordChangeSave?: (pwdFormData: any) => void;
}

const FloatedLableInput = ({
  placeholder,
  name,
  className,
  readonly,
  ...props
}: any) => {
  const hasValue = !!props.value;
  return (
    <div
      className={`ui fluid input mb-4 relative floated-lable-input ${className}`}
    >
      <input className="labeling font-styleRegular" name={name} {...props} />
    </div>
  );
};
function AccountsProfile(props: accountsProfileProps) {
  const { t } = useTranslation();
  const [isModalActive, setIsModalActive] = useState(false);
  const [isFirstModalActive, setFirstModalActiveModalActive] = useState(false);
  const [isLastModalActive, setlastModalActiveModalActive] = useState(false);
  const [isEmailModalActive, setEmailModalActiveModalActive] = useState(false);
  const [regExEmail, checkRegEx] = useState(false);
  const [emailMatching, checkEmailMatching] = useState(false);
  const [saveEmailDisables, setSaveEmailDisables] = useState(false);
  const [ispasswordModalActive, setPasswordModalActiveModalActive] = useState(
    false
  );
  const [showValidationMsgs, setShowValidationMsgs] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCapsAlert, setShowCapsAlert] = useState(true);
  const [showSmallLtrAlert, setSmallLtrAlert] = useState(true);
  const [showNumberAlert, setShowNumberAlert] = useState(true);
  const [showMinCharAlert, setshowMinCharAlert] = useState(true);
  const [showPasswordNotMatch, setShowPasswordNotMatch] = useState(false);

  const [formData, setFormData]: any = useState({
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    addEmail: '',
    confirmEmail: ''
  });

  const [pwdFormData, setPwdFormData]: any = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    const { addEmail, confirmEmail } = formData;
    if (addEmail !== '' && confirmEmail !== '') {
      if (addEmail === confirmEmail) {
        checkEmailMatching(false);
      } else if (confirmEmail && confirmEmail !== '') {
        checkEmailMatching(true);
      }
    }
  }, [formData]);

  const onTextInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'addEmail') {
      regularExpForEmail(e);
    }
  };

  const onPasswordFormChange = async (e: any) => {
    setPwdFormData({ ...pwdFormData, [e.target.name]: e.target.value });
    if (e.target.name === 'newPassword') {
      validatePassword(e.target.value);
    }
    if (e.target.name === 'confirmPassword') {
      if (
        pwdFormData.newPassword !== '' &&
        pwdFormData.newPassword !== e.target.value
      ) {
        setShowPasswordNotMatch(true);
      } else {
        setShowPasswordNotMatch(false);
      }
    }
  };

  const validatePassword = (value: any) => {
    if (
      pwdFormData.confirmPassword !== '' &&
      pwdFormData.confirmPassword !== value
    ) {
      setShowPasswordNotMatch(true);
    } else {
      setShowPasswordNotMatch(false);
    }

    setShowValidationMsgs(true);
    if (new RegExp('[A-Z]').test(value)) {
      setShowCapsAlert(false);
    } else {
      setShowCapsAlert(true);
    }

    if (new RegExp('[a-z]').test(value)) {
      setSmallLtrAlert(false);
    } else {
      setSmallLtrAlert(true);
    }

    if (new RegExp('[0-9]').test(value)) {
      setShowNumberAlert(false);
    } else {
      setShowNumberAlert(true);
    }

    if (value.length < 6) {
      setshowMinCharAlert(true);
    } else {
      setshowMinCharAlert(false);
    }
  };

  // regualr expression for valid email
  const regularExpForEmail = (e: any) => {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const addEmail = e.target.value;
    if (!pattern.test(addEmail)) {
      checkRegEx(true);
    } else {
      checkRegEx(false);
    }
  };

  const firstNameModal = () => {
    setIsModalActive(true);
    setFirstModalActiveModalActive(true);
    setFormData({ ...formData, firstName: props.firstName });
  };

  const lastNameModal = () => {
    setIsModalActive(true);
    setlastModalActiveModalActive(true);
    setFormData({ ...formData, lastName: props.lastName });
  };

  const emailModal = () => {
    setIsModalActive(true);
    setEmailModalActiveModalActive(true);
  };

  const passwordModal = () => {
    setIsModalActive(true);
    setPasswordModalActiveModalActive(true);
  };

  const onCloseModal = () => {
    setIsModalActive(false);
    setFirstModalActiveModalActive(false);
    setlastModalActiveModalActive(false);
    setEmailModalActiveModalActive(false);
    setPasswordModalActiveModalActive(false);
  };
  const resetEmailChange = () => {
    checkRegEx(false);
    checkEmailMatching(false);
    setSaveEmailDisables(false);
    setFormData({ addEmail: '', confirmEmail: '' });
  };

  const resetPasswordModel = () => {
    setPwdFormData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    setShowValidationMsgs(false);
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setShowCapsAlert(true);
    setSmallLtrAlert(true);
    setshowMinCharAlert(true);
    setShowNumberAlert(true);
    setShowPasswordNotMatch(false);
  };

  const closePwdForm = () => {
    onCloseModal();
    resetPasswordModel();
  };

  const onConfirmPwdChange = () => {
    props.onPasswordChangeSave &&
      props.onPasswordChangeSave({
        password: pwdFormData.currentPassword,
        newPassword: pwdFormData.newPassword
      });
    onCloseModal();
    resetPasswordModel();
  };

  const disableSaveBtn = () => {
    if (
      pwdFormData.currentPassword === '' ||
      pwdFormData.newPassword === '' ||
      pwdFormData.confirmPassword === '' ||
      showCapsAlert ||
      showSmallLtrAlert ||
      showMinCharAlert ||
      showNumberAlert ||
      showPasswordNotMatch
    ) {
      return true;
    } else return false;
  };

  const onConfirmEmailSave = () => {
    props.onConfirmEmailSave &&
      props.onConfirmEmailSave({
        Email: formData.addEmail
      });
    onCloseModal();
    resetEmailChange();
  };

  const closeEmailModel = () => {
    onCloseModal();
    resetEmailChange();
  };

  const handleFormSubmit = () => {
    onCloseModal();
    let personalDetails = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      title: props.title ? props.title : '',
      gender: props.gender ? props.gender : '',
      dob: props.dob ? props.dob : ''
    };
    props.onFormSubmit && props.onFormSubmit(personalDetails);
  };

  const showCurrentPwd = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const showNewPwd = () => {
    setShowNewPassword(!showNewPassword);
  };

  const showConfirmPwd = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="accounts-profile-binder">
      <div className="accounts-profile-sub-binder">
        <div className="ac-profile-componets">
          <div className="profile-lable">
            <p className="m-0 font-styleBold">{t('First Name')}</p>
          </div>
          <div className="profile-details-binder">
            <div className="profile-details-sub-binder">
              <div className="profile-details-dynamic-value">
                <p className="m-0 font-styleRegular">{props.firstName}</p>
              </div>
              <div className="profile-details-dynamic-value-button">
                <Button
                  label={t("Edit")}
                  width="101px"
                  disabled={false}
                  type="secondary"
                  onClick={firstNameModal}
                />
              </div>
            </div>
          </div>
        </div>

        {/* last name */}
        <div className="ac-profile-componets">
          <div className="profile-lable">
            <p className="m-0 font-styleBold">{t('Last Name')}</p>
          </div>
          <div className="profile-details-binder">
            <div className="profile-details-sub-binder">
              <div className="profile-details-dynamic-value">
                <p className="m-0 font-styleRegular">{props.lastName}</p>
              </div>
              <div className="profile-details-dynamic-value-button">
                <Button
                  label={t("Edit")}
                  width="101px"
                  disabled={false}
                  type="secondary"
                  onClick={lastNameModal}
                />
              </div>
            </div>
          </div>
        </div>

        {/* email; */}
        <div className="ac-profile-componets">
          <div className="profile-lable">
            <p className="m-0 font-styleBold">{t('Email')}</p>
          </div>
          <div className="profile-details-binder">
            <div className="profile-details-sub-binder">
              <div className="profile-details-dynamic-value">
                <p className="m-0 font-styleRegular">{props.email}</p>
              </div>
              {/* disabled temporarily - 30th Nov 2023 */}
              {/* <div className="profile-details-dynamic-value-button">
                <Button
                  label={t("Edit")}
                  width="101px"
                  disabled={true}
                  type="secondary"
                  onClick={emailModal}
                />
              </div> */}
            </div>
          </div>
        </div>
        {/* password - remove password edit for the SSO users https://iat-technology.atlassian.net/browse/EPWS-709 */}
        { !props.ssoUser && (
          <div className="ac-profile-componets">
            <div className="profile-lable">
              <p className="m-0 font-styleBold">{t('Password')}</p>
            </div>
            <div className="profile-details-binder">
              <div className="profile-details-sub-binder">
                <div className="profile-details-dynamic-value">
                  <p className="m-0 font-styleRegular">•••••••••••••••</p>
                </div>
                <div className="profile-details-dynamic-value-button">
                  <Button
                    label={t("Edit")}
                    width="101px"
                    disabled={false}
                    type="secondary"
                    onClick={passwordModal}
                  />
                </div>
              </div>
            </div>
          </div>
        )} 
      </div>
      {isModalActive && (
        <div className="modal-accounts">
          <Modal
            open={isModalActive}
            closeOnDimmerClick={true}
            closeOnDocumentClick={true}
            className="modal-accounts-main"
          >
            {/* inside tags */}
            <div className="accounts-profile-all-content-binder">
              <div className="accounts-profile-all-content-sub-binder">
                {isFirstModalActive && (
                  <div className="first-name-modal">
                    <div className="modal-head-con">
                      <div className="model-text">
                        <p className="m-0 model-text-head font-styleBold">
                          {t('Edit First Name')}
                        </p>
                      </div>
                      <div className="modal-cross">
                        <FiX
                          onClick={onCloseModal}
                          data-testid="close-modal"
                          className="crossing-icon-modal"
                        />
                      </div>
                    </div>
                    {/* modal body */}
                    <div className="modal-inputs-all">
                      <div className="modal-inputs-all-sub">
                        <div className="modal-inputs-all-sub-label">
                          <p className="m-0 font-styleBold">{t('First Name')}</p>
                        </div>
                        <div className="modal-input-accounts">
                          <form>
                            <FloatedLableInput
                              name="firstName"
                              value={formData.firstName}
                              readOnly={false}
                              onChange={onTextInputChange}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer-accounts">
                        <div className="modal-accounts-btns">
                          <div className="modal-accounts-primnary-btn">
                            <Button
                              label={t("Cancel")}
                              width="128px"
                              disabled={false}
                              type="secondary"
                              onClick={onCloseModal}
                            />
                          </div>
                          <div className="modal-accounts-secondary-btn">
                            <Button
                              label={t("Save")}
                              width="128px"
                              disabled={
                                formData.firstName === props.firstName ||
                                formData.firstName === ''
                              }
                              type="primary"
                              onClick={() => {
                                // onCloseModal();
                                handleFormSubmit();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isLastModalActive && (
                  <div className="last-name-modal">
                    <div className="modal-head-con">
                      <div className="model-text">
                        <p className="m-0 model-text-head font-styleBold">
                          {t('Edit last Name')}
                        </p>
                      </div>
                      <div className="modal-cross">
                        <FiX
                          onClick={onCloseModal}
                          data-testid="close-modal"
                          className="crossing-icon-modal"
                        />
                      </div>
                    </div>
                    {/* modal body */}
                    <div className="modal-inputs-all">
                      <div className="modal-inputs-all-sub">
                        <div className="modal-inputs-all-sub-label">
                          <p className="m-0 font-styleBold">{t('Last Name')}</p>
                        </div>
                        <div className="modal-input-accounts">
                          <form>
                            <FloatedLableInput
                              name="lastName"
                              value={formData.lastName}
                              readOnly={false}
                              onChange={onTextInputChange}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer-accounts">
                        <div className="modal-accounts-btns">
                          <div className="modal-accounts-primnary-btn">
                            <Button
                              label={t("Cancel")}
                              width="128px"
                              disabled={false}
                              type="secondary"
                              onClick={onCloseModal}
                            />
                          </div>
                          <div className="modal-accounts-secondary-btn">
                            <Button
                              label={t("Save")}
                              width="128px"
                              disabled={
                                formData.lastName === props.lastName ||
                                formData.lastName === ''
                              }
                              type="primary"
                              onClick={handleFormSubmit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isEmailModalActive && (
                  <div className="email-modal">
                    <div className="modal-head-con">
                      <div className="model-text">
                        <p className="m-0 model-text-head font-styleBold">
                          {t('Edit Email Address')}
                        </p>
                      </div>
                      <div className="modal-cross">
                        <FiX
                          onClick={closeEmailModel}
                          data-testid="close-modal"
                          className="crossing-icon-modal"
                        />
                      </div>
                    </div>
                    {/* modal body */}
                    <div className="modal-inputs-all">
                      <div className="modal-inputs-all-sub">
                        <div className="modal-inputs-all-sub-label">
                          <p className="m-0 font-styleBold">
                            New Email Address
                          </p>
                        </div>
                        <div className="modal-input-accounts">
                          <form>
                            {/* Enter New Email */}
                            <FloatedLableInput
                              name="addEmail"
                              value={formData.addEmail}
                              onChange={onTextInputChange}
                            />

                            {/* Re-enter email address */}
                            <div className="spacing">
                              <div className="modal-inputs-all-sub-label">
                                <p className="m-0 font-styleBold">
                                  {t('Re-enter New Email Address')}
                                </p>
                              </div>
                              <FloatedLableInput
                                name="confirmEmail"
                                value={formData.confirmEmail}
                                onChange={onTextInputChange}
                              />
                            </div>
                          </form>
                        </div>
                      </div>

                      {/* Validations */}
                      {regExEmail && (
                        <div className="addres-errors-msgs">
                          <div className="adres-err">
                            <p>{t('Enter valid email')}</p>
                          </div>
                        </div>
                      )}

                      {emailMatching && (
                        <div className="addres-errors-msgs">
                          <div className="adres-err">
                            <p>{t('Email address not matching')}</p>
                          </div>
                        </div>
                      )}

                      {/* confirmation message */}
                      <div className="confirmation-msg-on-accounts-email">
                        <div className="confirmation-msg-on-accounts-email-binder">
                          <p className="m-0 font-styleRegualr">
                            {t('We’ll send your new address a verification email to confirm.')}
                          </p>
                        </div>
                      </div>
                      <div className="modal-footer-accounts">
                        <div className="modal-accounts-btns">
                          <div className="modal-accounts-primnary-btn">
                            <Button
                              label={t("Cancel")}
                              width="128px"
                              disabled={false}
                              type="secondary"
                              onClick={closeEmailModel}
                            />
                          </div>
                          <div className="modal-accounts-secondary-btn">
                            <Button
                              label={t("Save")}
                              width="128px"
                              disabled={
                                saveEmailDisables ||
                                formData.addEmail === '' ||
                                formData.confirmEmail === '' ||
                                regExEmail ||
                                emailMatching
                              }
                              type="primary"
                              onClick={onConfirmEmailSave}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ispasswordModalActive && (
                  <div className="password-modal">
                    <div className="modal-head-con">
                      <div className="model-text">
                        <p className="m-0 model-text-head font-styleBold">
                          {t('Update Password')}
                        </p>
                      </div>
                      <div className="modal-cross">
                        <FiX
                          onClick={closePwdForm}
                          data-testid="close-modal"
                          className="crossing-icon-modal"
                        />
                      </div>
                    </div>
                    {/* modal body */}
                    <div className="modal-inputs-all">
                      <div className="modal-inputs-all-sub">
                        <div className="modal-inputs-all-sub-label">
                          <p className="m-0 font-styleBold">{t('Current Password')}</p>
                        </div>
                        <div className="modal-input-accounts">
                          <form>
                            <FloatedLableInput
                              name="currentPassword"
                              readOnly={false}
                              type={showCurrentPassword ? 'text' : 'password'}
                              value={pwdFormData.currentPassword}
                              onChange={onPasswordFormChange}
                            />
                            <div className="eye-images-binder">
                              <div className="eye-images-sub-binder">
                                {showCurrentPassword && (
                                  <div className="eye-open">
                                    <a
                                      className="cursor-pointer"
                                      onClick={showCurrentPwd}
                                    >
                                      <img src="./static/eye-open.svg" />
                                    </a>
                                  </div>
                                )}
                                {!showCurrentPassword && (
                                  <div className="eye-close">
                                    <a
                                      className="cursor-pointer"
                                      onClick={showCurrentPwd}
                                    >
                                      <img src="./static/eye-close.svg" />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="modal-inputs-all-sub spacing">
                        <div className="modal-inputs-all-sub-label">
                          <p className="m-0 font-styleBold">{t('New Password')}</p>
                        </div>
                        <div className="modal-input-accounts">
                          <form>
                            <FloatedLableInput
                              name="newPassword"
                              readOnly={false}
                              value={pwdFormData.newPassword}
                              onChange={onPasswordFormChange}
                              type={showNewPassword ? 'text' : 'password'}
                            />
                            <div className="eye-images-binder">
                              <div className="eye-images-sub-binder">
                                {showNewPassword && (
                                  <div className="eye-open">
                                    <a
                                      className="cursor-pointer"
                                      onClick={showNewPwd}
                                    >
                                      <img src="./static/eye-open.svg" />
                                    </a>
                                  </div>
                                )}
                                {!showNewPassword && (
                                  <div className="eye-close">
                                    <a
                                      className="cursor-pointer"
                                      onClick={showNewPwd}
                                    >
                                      <img src="./static/eye-close.svg" />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* required validations for password input box */}
                            {showValidationMsgs && (
                              <div className="required-ps-match-binder">
                                <div className="required-ps-match-sub-binder">
                                  {showMinCharAlert && (
                                    <p className="m-0 font-styleRegular">
                                      <span className="reqiured-cross">
                                        <img src="./static/password-required-cross.svg" />
                                      </span>
                                      {t('Must be at Least 6 Characters')}
                                    </p>
                                  )}
                                  {showNumberAlert && (
                                    <p className="m-0 font-styleRegular">
                                      <span className="reqiured-cross">
                                        <img src="./static/password-required-cross.svg" />
                                      </span>
                                      {t('Requires a Number')}
                                    </p>
                                  )}
                                  {/* <p className="m-0 font-styleRegular">
                                  <span className="reqiured-cross">
                                    <img src="./static/password-required-cross.svg" />
                                  </span>
                                  Requires a Special Character
                                </p> */}
                                  {showCapsAlert && (
                                    <p className="m-0 font-styleRegular">
                                      <span className="reqiured-cross">
                                        <img src="./static/password-required-cross.svg" />
                                      </span>
                                      {t('Requires Uppercase Letters')}
                                    </p>
                                  )}
                                  {showSmallLtrAlert && (
                                    <p className="m-0 font-styleRegular">
                                      <span className="reqiured-cross">
                                        <img src="./static/password-required-cross.svg" />
                                      </span>
                                      {t('Requires Lowercase Letters')}
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                      <div className="modal-inputs-all-sub spacing">
                        <div className="modal-inputs-all-sub-label">
                          <p className="m-0 font-styleBold">
                            {t('Confirm New Password')}
                          </p>
                        </div>
                        <div className="modal-input-accounts">
                          <form>
                            <FloatedLableInput
                              name="confirmPassword"
                              readOnly={false}
                              value={pwdFormData.confirmPassword}
                              onChange={onPasswordFormChange}
                              type={showConfirmPassword ? 'text' : 'password'}
                            />
                            <div className="eye-images-binder">
                              <div className="eye-images-sub-binder">
                                {showConfirmPassword && (
                                  <div className="eye-open">
                                    <a
                                      className="cursor-pointer"
                                      onClick={showConfirmPwd}
                                    >
                                      <img src="./static/eye-open.svg" />
                                    </a>
                                  </div>
                                )}
                                {!showConfirmPassword && (
                                  <div className="eye-close">
                                    <a
                                      className="cursor-pointer"
                                      onClick={showConfirmPwd}
                                    >
                                      <img src="./static/eye-close.svg" />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* error messages */}

                            {showPasswordNotMatch && (
                              <div className="acc-err-messages">
                                <div className="addres-errors-msgs">
                                  <div className="adres-err">
                                    <p>{t('Passwords do not match')}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer-accounts spacing">
                        <div className="modal-accounts-btns">
                          <div className="modal-accounts-primnary-btn">
                            <Button
                              label={t("Cancel")}
                              width="128px"
                              disabled={false}
                              type="secondary"
                              onClick={closePwdForm}
                            />
                          </div>
                          <div className="modal-accounts-secondary-btn">
                            <Button
                              label={t("Save")}
                              width="128px"
                              disabled={disableSaveBtn()}
                              type="primary"
                              onClick={onConfirmPwdChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

// Set default props
AccountsProfile.defaultProps = {
  relativePath: ''
};

export default AccountsProfile;
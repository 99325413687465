import React from 'react';

interface IProps {
  /**
   * Svg's width
   * @default 22
   */
  width?: string;
  /**
   * Svg's height
   * @default 22
   */
  height?: string;
  /**
   * Svg's stroke
   */
  stroke?: string;
  /**
   * Svg's classNames
   * @default ''
   */
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const ZoomIcon = ({
  width,
  height,
  className,
  stroke,
  onMouseEnter,
  onMouseLeave
}: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#333"
          strokeWidth="1.5"
          d="M8.501.75h10.5c1.104 0 2 .896 2 2v10.5c0 1.104-.896 2-2 2h-10.5c-1.104 0-2-.896-2-2V2.75c0-1.104.896-2 2-2z"
        />
        <path
          stroke="#333"
          d="M15 19.253c0 1.104-.896 2-2 2H2.5c-1.105 0-2-.896-2-2v-10.5c0-1.104.895-2 2-2"
        />
        <path stroke={stroke} d="M2.5 6.753L3.767 6.753M15 17.999L15 19.266" />
      </g>
    </svg>
  );
};

ZoomIcon.defaultProps = {
  width: '22',
  height: '22',
  stroke: '#2D2D2D',
  className: ''
};

export default ZoomIcon;

import React, { CSSProperties } from 'react';

interface IProps {
  width?: string;
  height?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const FilterIcon = ({
  width = '36',
  height = '34',
  className,
  style
}: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 36 34"
    className={className}
    style={style}
  >
    <g fill="none" fillRule="nonzero" transform="translate(0 1)">
      <rect width="36" height="4" y="26" fill="#009AFF" rx="2" />
      <rect width="36" height="4" y="14" fill="#009AFF" rx="2" />
      <circle
        cx="9.5"
        cy="16.5"
        r="4.5"
        fill="#FFF"
        stroke="#009AFF"
        strokeWidth="1.5"
      />
      <circle
        cx="22.5"
        cy="27.5"
        r="4.5"
        fill="#FFF"
        stroke="#009AFF"
        strokeWidth="1.5"
      />
      <rect width="36" height="4" y="2" fill="#009AFF" rx="2" />
      <circle
        cx="27.5"
        cy="4.5"
        r="4.5"
        fill="#FFF"
        stroke="#009AFF"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

FilterIcon.defaultProps = {
  width: '36',
  height: '34',
  stroke: '#009AFF',
  fill: 'none',
  className: ''
};

export default FilterIcon;

import cx from 'classnames';
import React, {useState} from 'react';
import { Tab } from 'semantic-ui-react';
import ToolTip from 'react-tooltip';
import Moment from 'react-moment';
import './styles.css';
import { store } from '../../redux/store';
import { getCode } from '../../modules/join.ducks';
import LoaderEpoints from '../loader';
import { decodeHtmlEntities } from '../../redux/reducers';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  currentData: any;
  pendingData?: any;
  declinedData: any;
  token?: string;
  refreshToken?: string;
}
function numberWithCommas(x: number | string) {
  if (x) {
    return x
      .toString()
      .split('.')[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0';
  }
}



const CurrentBalanceContent = (props: any) => {
  const { t } = useTranslation();
  const { data } = props;
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr className="tr-class-bg-change">
            <th className="stickey-head">
              <div className="th padding-applied">
                <p className="font-styleLight">{t('Date')}</p>
              </div>
            </th>
            <th>
              <div className="th width-47">
                <p className="font-styleLight">{t('Activity')}</p>
              </div>
            </th>
            <th className="width-16">
              <div className="th">
                <p className="font-styleLight">{t('In')}</p>
              </div>
            </th>
            <th>
              <div className="th">
                <p className="font-styleLight">{t('Out')}</p>
              </div>
            </th>
            <th className="texting-end">
              <div className="th">
                <p className="font-styleLight">{t('Balance')}</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map(
              (row: any) =>
                decodeHtmlEntities(row.title).innerText !== 'epoints purchased' && (
                  <tr className="pading-for-td">
                    <td className="border-right-applied">
                      <p className="padding-applied font-styleRegular">
                        <Moment format="DD/MM/YYYY">{row.date}</Moment>
                      </p>
                    </td>
                    <td className="width-47">
                      {console.log('length for read more', decodeHtmlEntities(row?.title).innerText?.length)}
                      {decodeHtmlEntities(row?.title).innerText?.length < 30 ? (
                        <p className="font-styleRegular">{decodeHtmlEntities(row.title).innerText}</p>
                      ) : (
                        <div>
                          {decodeHtmlEntities(row?.title).innerText?.length && (
                            <p className="font-styleRegular">
                              {decodeHtmlEntities(row?.title).innerText?.substring(0, 46)}...
                              <span className="color-green-hover">
                                Read more
                                <div className="tooling">
                                  <ToolTip
                                    place="bottom"
                                    type="light"
                                    effect="solid"
                                    multiline={true}
                                    delayHide={500}
                                    border={true}
                                    // scrollHide={true}
                                    className="tool-tip w-262px h-16 leading-block rounded-large text-center text-sm bg-eco-green p-2 savings-grid-tooltip"
                                  >
                                    <p className="m-0 color-whitein color-black-on">
                                      {decodeHtmlEntities(row.title).innerText}
                                    </p>
                                  </ToolTip>
                                </div>
                              </span>
                            </p>
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      <p className="font-styleRegular">
                        {row.earnedPoints && row.earnedPoints.points
                          ? numberWithCommas(row.earnedPoints.points)
                          : ''}
                      </p>
                    </td>
                    <td>
                      <p className="font-styleRegular">
                        {numberWithCommas(row.spentPoints)}
                      </p>
                    </td>
                    <td className="texting-end">
                      <p className="font-styleRegular">
                        {numberWithCommas(row.balance)}
                      </p>
                    </td>
                  </tr>
                )
            )}
        </tbody>
      </table>
    </div>
  );
};
const PendingContent = (props: any) => {
  const { t } = useTranslation();
  const { pendingData } = props;
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr className="tr-class-bg-change">
            <th className="stickey-head">
              <div className="th padding-applied">
                <p className="font-styleLight">{t('Date')}</p>
              </div>
            </th>
            <th>
              <div className="th width-47">
                <p className="font-styleLight">{t('Activity')}</p>
              </div>
            </th>
            <th className="width-16">
              <div className="th">
                <p className="font-styleLight">{t('In')}</p>
              </div>
            </th>
            <th>
              <div className="th">
                <p className="font-styleLight">{t('Out')}</p>
              </div>
            </th>
            <th className="texting-end">
              <div className="th">
                <p className="font-styleLight">{t('Balance')}</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {pendingData &&
            pendingData.map((row: any) => (
              <tr className="pading-for-td">
                <td className="border-right-applied">
                  <p className="padding-applied font-styleRegular">
                    <Moment format="DD/MM/YYYY">{row.date}</Moment>
                  </p>
                </td>
                <td className="width-47">
                  <p className="font-styleRegular">{decodeHtmlEntities(row.title).innerText}</p>
                </td>
                <td>
                  <p className="font-styleRegular">
                    {row.earnedPoints && row.earnedPoints.points
                      ? numberWithCommas(row.earnedPoints.points)
                      : ''}
                  </p>
                </td>
                <td>
                  <p className="font-styleRegular">
                    {numberWithCommas(row.spentPoints)}
                  </p>
                </td>
                <td className="texting-end">
                  <p className="font-styleRegular">
                    {numberWithCommas(row.balance)}
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
const DeclinedContent = (props: any) => {
  const { t } = useTranslation();
  const { declinedData } = props;
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr className="tr-class-bg-change">
            <th className="stickey-head">
              <div className="th padding-applied">
                <p className="font-styleLight">{t('Date')}</p>
              </div>
            </th>
            <th>
              <div className="th width-47">
                <p className="font-styleLight">{t('Activity')}</p>
              </div>
            </th>
            <th className="width-16">
              <div className="th">
                <p className="font-styleLight">{t('In')}</p>
              </div>
            </th>
            <th>
              <div className="th">
                <p className="font-styleLight">{t('Out')}</p>
              </div>
            </th>
            <th className="texting-end">
              <div className="th">
                <p className="font-styleLight">{t('Balance')}</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {declinedData &&
            declinedData.map((row: any) => (
              <tr className="pading-for-td">
                <td className="border-right-applied">
                  <p className="padding-applied font-styleRegular">
                    <Moment format="DD/MM/YYYY">{row.date}</Moment>
                  </p>
                </td>
                <td className="width-47">
                  <p className="font-styleRegular">{decodeHtmlEntities(row.title).innerText}</p>
                </td>
                <td>
                  <p className="font-styleRegular">
                    {row.earnedPoints && row.earnedPoints.points
                      ? numberWithCommas(row.earnedPoints.points)
                      : ''}
                  </p>
                </td>
                <td>
                  <p className="font-styleRegular">
                    {numberWithCommas(row.spentPoints)}
                  </p>
                </td>
                <td className="texting-end">
                  <p className="font-styleRegular">
                    {numberWithCommas(row.balance)}
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

// messages when no data found

function AccountsTable(props: IProps) {
  const { t } = useTranslation();
  let pendingInfo = t('You currently have no pending points.');
  console.log(pendingInfo)
  const { currentData, pendingData, declinedData } = props;
  const [fetchingCode, updateFetchingCode] = useState(false);

  const redirectingDomainSpecificFaq = async () => {
    const { global } = store.getState();
    
    // get sso object from localStorage
    let ssoData = global.SSO_USER;
    let isSsoUser = ssoData?.enabled;
    let companyShortName = ssoData?.companyShortName;
    if(isSsoUser) {
      window.location.href = `${global.EP_ADMIN_URL}/login-handler?redirect_to=new-shop&companyShortName=${companyShortName}&intended_url=${global.SHOP_URL}/faqs/`;
    } else {
      updateFetchingCode(true)
      try {
        let userCode = await getCode() as { authenticationCode?: string }; // Adjust the type based on the actual structure;
  
        if (userCode && typeof userCode === 'object') {
            if ('authenticationCode' in userCode) {
                window.location.href = `${global.SHOP_URL}/?code=${userCode.authenticationCode}&redirect_to=${global.SHOP_URL}/faqs/`;
            } else {
              updateFetchingCode(false)
                window.location.href = '';
            }
        } else {
          updateFetchingCode(false)
            window.location.href = '';
        }
      } catch (error) {
        updateFetchingCode(false)
          window.location.href = '';
      }
    }
  };
  const panes = [
    {
      menuItem: t(`Current Balance`),
      // menuItem: `Current Balance (${currentData.length})`,
      render: () => (
        <div>
          {currentData.transactions && currentData.transactions.length > 0 ? (
            <Tab.Pane className="tab-main-cls" attached={false}>
              <CurrentBalanceContent data={currentData.transactions} />
            </Tab.Pane>
          ) : (
            <div>
              <div className="empty-messages">
                <p className="font-styleRegular m-0 pad-b">
                  {t('You currently have no current balance.')}
                </p>
                <p className="font-styleRegular m-0">
                  <Trans i18nKey="currentActivityInfo">
                    It usually takes 24 hours from the time of purchase until your epoints appear here. Points remain pending for 30 days before they are added to your account. If you want to know more about this then have a look at our{' '}
                    <a onClick={redirectingDomainSpecificFaq} className="anchor-greens cursor-pointer">FAQs.</a>
                  </Trans>
                </p>
              </div>
            </div>
          )}
        </div>
      )
    },
    {
      menuItem: t(`Pending`),
      // menuItem: `Pending (${pendingData.length})`,
      render: () => (
        <div>
          {pendingData.transactions && pendingData.transactions.length > 0 ? (
            <Tab.Pane className="tab-main-cls" attached={false}>
              <PendingContent pendingData={pendingData} />
            </Tab.Pane>
          ) : (
            <div>
              <div className="empty-messages">
                <p className="font-styleRegular m-0 pad-b">
                  {t('You currently have no pending points.')}
                </p>
                <p className="font-styleRegular m-0">
                  <Trans i18nKey="currentActivityInfo">
                    It usually takes 24 hours from the time of purchase until your
                    epoints appear here. Points remain pending for 30 days before
                    they are added to your account. If you want to know more about
                    this then have a look at our{' '}
                    <a onClick={redirectingDomainSpecificFaq} className="anchor-greens cursor-pointer">FAQs.</a>
                  </Trans>
                </p>
              </div>
            </div>
          )}
        </div>
      )
    },
    {
      menuItem: t(`Declined`),
      // removed from the above label for points
      // menuItem: `Declined (${declinedData.length})`,
      render: () => (
        <div>
          {declinedData.transactions && declinedData.transactions.length > 0 ? (
            <Tab.Pane className="tab-main-cls" attached={false}>
              <DeclinedContent declinedData={declinedData} />
            </Tab.Pane>
          ) : (
            <div>
              <div className="empty-messages">
                <p className="font-styleRegular m-0 pad-b">
                  {t('You currently have no declined points.')}
                </p>
              </div>
            </div>
          )}
        </div>
      )
    }
  ];
  return (
    <>
      {fetchingCode ?
        <div><LoaderEpoints/></div> :
        <div className="table-lib">
          {/* tabs */}
          <div className="tabs-for-table-binder">
            <div className="tabs-for-table-sub">
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </div>
          </div>
        </div>
      }
    </>
  );
}

// Set default props
AccountsTable.defaultProps = {
  relativePath: ''
};

export default AccountsTable;
import {applyMiddleware, compose, createStore} from "redux";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {reducers} from "./reducers";
import Mdls from "./middlewares";

// @ts-ignore
declare var window;

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['join'] // only cart will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

export type RootState = ReturnType<typeof persistedReducer>

// dev tool
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(...Mdls),
    ),
);

export const persistor = persistStore(store);

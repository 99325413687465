import {Action, ReducerClass} from 'reducer-class';
import {createAction} from 'redux-actions';
import {apiRequest} from './api.ducks';
import cookie from 'cookie';
import {getExchangeToken} from './join.ducks';

const GET_EMPLOYEE_ELIGIBILITY_STATUS = createAction('[Salary] Get Employee Eligibility Status');
const GET_EMPLOYEE_INFO = createAction('[Salary] Get Employee Info');
const GET_CONTRIBUTION_STATEMENT = createAction('[Salary] Get Contribution Statement');
const GET_WITHDRAW_SAVINGS = createAction('[Salary] Get Withdraw Savings');


const POST_EMPLOYEE_DATA = createAction('[Salary] Post Employee Data');
const POST_WITHDRAW_DATA = createAction('[Salary] Post Withdraw Data');

const EDIT_MONTHLY_CONTRIBUTION = createAction('[Salary] Edit Monthly Contribution');


const GET_EMPLOYEE_ELIGIBILITY_SUCCESS = createAction('[Salary] Get Employee Eligibility Status Success');
const GET_EMPLOYEE_INFO_SUCCESS = createAction('[Salary] Get Employee Info Success');
const GET_WITHDRAW_SAVINGS_SUCCESS = createAction('[Salary] Get Withdraw Savings Success');
const CONTRIBUTION_FETCH_SUCCESS = createAction('[Salary] Contribution Fetch Success');

const POST_EMPLOYEE_DATA_SUCCESS = createAction('[Salary] Post Employee Data Success');
const POST_WITHDRAW_DATA_SUCCESS = createAction('[Salary] Post Withdraw Data Success');

const EDIT_MONTHLY_CONTRIBUTION_SUCCESS = createAction('[Salary] Edit Monthly Contribution Success');



const UPDATE_EMPLOYEE_ELIGIBILITY_STATUS = createAction('[Salary] Update Employee Eligibility Status');
const UPDATE_EMPLOYEE_INFO = createAction('[Salary] Update Employee Info');
const UPDATE_WITHDRAW_SAVINGS = createAction('[Salary] Update Withdraw Savings');
const UPDATE_EMPLOYEE_DATA_SUCCESS = createAction('[Salary] Update Employee Data Success')
const UPDATE_WITHDRAW_SUCCESS = createAction('[Salary] Update Withdraw Success')
const UPDATE_CONTRIBUTION_STATEMENT = createAction('[Salary] Update Contribution');
const UPDATE_STATEMENT_DURATION =  createAction('[Salary] Update Statement Duration');
const UPDATE_EMP_LOADER_FETCH_STATUS = createAction('[Salary] Update Employee Loader Fetch Status');
const UPDATE_STATEMENT_LOADER_FETCH_STATUS = createAction('[Salary] Update Statement Loader Fetch Status');
const UPDATE_CONTRIBUTION_STATUS = createAction('[Salary] Update Contribution Status');



const GET_EMPLOYEE_ELIGIBILITY_ERROR = createAction('[Salary] Get Employee Eligibility Status Error');
const GET_EMPLOYEE_INFO_ERROR = createAction('[Salary] Get Employee Info Error');
const GET_WITHDRAW_SAVINGS_ERROR = createAction('[Salary] Get Withdraw Savings Error');
const CONTRIBUTION_STATEMENT_ERROR = createAction('[Salary] Contribution Statement Error');
const EDIT_MONTHLY_CONTRIBUTION_ERROR = createAction('[Salary] Edit Monthly Contribution  Error');

const POST_EMPLOYEE_DATA_ERROR = createAction('[Salary] Post Employee Data Error');
const POST_WITHDRAW_DATA_ERROR = createAction('[Salary] Post Withdraw Data Error');



export const getEmployeeEligibilityStatus = () => GET_EMPLOYEE_ELIGIBILITY_STATUS();
export const getEmployeeInfo = () => GET_EMPLOYEE_INFO();
export const getWithDrawSavings = () => GET_WITHDRAW_SAVINGS();
export const postEmployeeData = (data: any) => POST_EMPLOYEE_DATA(data);
export const postWithDrawData = (data: any) => POST_WITHDRAW_DATA(data);

export const updateEmployeeDataSuccess = (data: any) => UPDATE_EMPLOYEE_DATA_SUCCESS(data);
export const updateWithdrawSuccess = (data: any) => UPDATE_WITHDRAW_SUCCESS(data);

export const getStatement = () => GET_CONTRIBUTION_STATEMENT();
export const updateStatementDuration = (data:any) => UPDATE_STATEMENT_DURATION(data);
export const editMonthlyContribution = (formData:any) => EDIT_MONTHLY_CONTRIBUTION(formData);
export const updateContributionStatus = (data: any) => UPDATE_CONTRIBUTION_STATUS(data);

const updateEmployeeElegibilityStatus = (data: any) => UPDATE_EMPLOYEE_ELIGIBILITY_STATUS(data);
const updateEmployeeInfo = (data: any) => UPDATE_EMPLOYEE_INFO(data);
const updateWithdrawSavings = (data: any) => UPDATE_WITHDRAW_SAVINGS(data);
const updateContributionStatement = (data: any) => UPDATE_CONTRIBUTION_STATEMENT(data);
const updateEmpLoaderFetchStatus = (data: any) => UPDATE_EMP_LOADER_FETCH_STATUS(data);
const updateStatementLoaderFetchStatus = (data: any) => UPDATE_STATEMENT_LOADER_FETCH_STATUS(data);



interface IReducerSalaryState {
    employeeInfo : any,
    isEmpLoader: boolean
    isStatementLoader: boolean
    withdrawSavings: any
    withdrawSucess: boolean
}

class Salary extends ReducerClass<IReducerSalaryState> {
    initialState = {
        employeeInfo: {},
        statementData: {},
        statementDuration: 30,
        isEmpLoader: true,
        isStatementLoader: true,
        withdrawSavings: {},
        withdrawSucess: false
    };

    static getStatement = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_CONTRIBUTION_STATEMENT().type) {
            const {global, salary, join} = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/employee/save-from-salary/transactions?duration=${salary.statementDuration}`,
                    null,
                    CONTRIBUTION_FETCH_SUCCESS().type,
                    CONTRIBUTION_STATEMENT_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json',
                        }}
                ));
            }
        }
    }

    static getEmployeeEligibilityStatus = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_EMPLOYEE_ELIGIBILITY_STATUS().type) {
            // store.dispatch(updateEmpLoaderFetchStatus(true));
            const { global, join } = store.getState();
            if (join.tokens && join.refreshToken) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/save-from-salary/status`,
                    null,
                    GET_EMPLOYEE_ELIGIBILITY_SUCCESS().type,
                    GET_EMPLOYEE_ELIGIBILITY_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json'
                        }}
                ));
        }
        }
    }
    static getEmployeeInfo = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_EMPLOYEE_INFO().type) {
            const { global, join } = store.getState();
            if (join.tokens && join.refreshToken) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/employee/save-from-salary`,
                    null,
                    GET_EMPLOYEE_INFO_SUCCESS().type,
                    GET_EMPLOYEE_INFO_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json'
                        }
                    }
                ));
            }
        }
    }

    static getWithDrawSavings = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_WITHDRAW_SAVINGS().type) {
            const { global, join } = store.getState();
            if (join.tokens && join.refreshToken) {
                store.dispatch(apiRequest(
                    'GET',
                    global.API_PROD_END_POINT + `/rest/employee/save-from-salary/withdraw`,
                    null,
                    GET_WITHDRAW_SAVINGS_SUCCESS().type,
                    GET_WITHDRAW_SAVINGS_ERROR().type,
                    {
                        headers: {
                            'Authorization': join.tokens.access_token,
                            'Content-Type': 'application/json'
                        }
                    }
                ));
            }
        }
    }
    
    static handlepostEmployeeData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === POST_EMPLOYEE_DATA().type) {
            const { global, join, details } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "POST",
                global.API_PROD_END_POINT + '/rest/employee/save-from-salary',
                action.payload,
                POST_EMPLOYEE_DATA_SUCCESS().type,
                POST_EMPLOYEE_DATA_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }}
                    
            ))
            }
        }
    }

    static handlepostWithdrawData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === POST_WITHDRAW_DATA().type) {
            const { global, join } = store.getState();
            if(join.tokens &&  join.tokens.access_token) {
            store.dispatch(apiRequest(
                "POST",
                global.API_PROD_END_POINT + '/rest/employee/save-from-salary/withdraw',
                action.payload,
                POST_WITHDRAW_DATA_SUCCESS().type,
                POST_WITHDRAW_DATA_SUCCESS().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json',
                    }}
                    
            ))
            }
        }
    }


    static editMonthlyContribution = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === EDIT_MONTHLY_CONTRIBUTION().type) {
            const { global, join } = store.getState();
            if(join.tokens && join.tokens.access_token){
            store.dispatch(apiRequest(
                "PUT",
                global.API_PROD_END_POINT + '/rest/employee/save-from-salary',
                action.payload,
                EDIT_MONTHLY_CONTRIBUTION_SUCCESS().type,
                EDIT_MONTHLY_CONTRIBUTION_ERROR().type,
                {
                    headers: {
                        'Authorization': join.tokens.access_token,
                        'Content-Type': 'application/json'
                    }}
            ))
            }
        }
    }

    static processMonthlyContribution = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === EDIT_MONTHLY_CONTRIBUTION_SUCCESS().type) {
            // store.dispatch(getEmployeeInfo());
            store.dispatch(updateContributionStatus(true))
        }
        if (action.type === EDIT_MONTHLY_CONTRIBUTION_ERROR().type) {
            store.dispatch(updateContributionStatus(false))
        }
    }


    static processEmployeeEligibilityStatus = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_EMPLOYEE_ELIGIBILITY_SUCCESS().type) {
            store.dispatch(updateEmpLoaderFetchStatus(false));
             store.dispatch(updateEmployeeElegibilityStatus(action.payload));
        }
        if (action.type === GET_EMPLOYEE_ELIGIBILITY_ERROR().type) {
            store.dispatch(updateEmpLoaderFetchStatus(false));
          console.log("self service failed", action.payload)
     }
    }

    static processEmployeeInfoUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_EMPLOYEE_INFO_SUCCESS().type) {
            store.dispatch(updateStatementLoaderFetchStatus(false));
             store.dispatch(updateEmployeeInfo(action.payload));
        }
        if (action.type === GET_EMPLOYEE_INFO_ERROR().type) {
            store.dispatch(updateStatementLoaderFetchStatus(false));
          console.log("self service failed", action.payload)
     }
    }

    static processWithdrawSavingsUpdate = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === GET_WITHDRAW_SAVINGS_SUCCESS().type) {
             store.dispatch(updateWithdrawSavings(action.payload));
        }
        if (action.type === GET_WITHDRAW_SAVINGS_ERROR().type) {
        //   console.log("self service failed", action.payload)
     }
    }

    static processEmployeeData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === POST_EMPLOYEE_DATA_SUCCESS().type) {
            store.dispatch(updateEmployeeDataSuccess(true));
        }
    }

    static processWithdrawData = (store: any) => (next: any) => (action: any) => {
        next(action);
        if (action.type === POST_WITHDRAW_DATA_SUCCESS().type) {
            store.dispatch(updateWithdrawSuccess(true));
        }
    }

    static processSaveFromSalary = (store: any) => (next: any) => (action: any) => {
        next(action);
        // get sso object from localStorage
        let {global} = store.getState();
        let ssoData = global.SSO_USER;
        let isSsoUser = ssoData?.enabled;
        let companyShortName = ssoData?.companyShortName;
        if (action.type === CONTRIBUTION_FETCH_SUCCESS().type) {
            const sfsData = action.payload;
            store.dispatch(updateContributionStatement(sfsData));
        }
        if (action.type === CONTRIBUTION_STATEMENT_ERROR().type) {
            if (isSsoUser) {
                store.dispatch(getExchangeToken({ companyShortName }));
            } else {
                store.dispatch(getExchangeToken({}));
            }
        }
    }


    @Action(UPDATE_EMP_LOADER_FETCH_STATUS())
    updateEmpLoaderFetchStatus(state: IReducerSalaryState, action: any) {
        return {
            ...state,
            isEmpLoader: action.payload,
        };
    }

    @Action(UPDATE_STATEMENT_LOADER_FETCH_STATUS())
    updateStatementLoaderFetchStatus(state: IReducerSalaryState, action: any) {
        return {
            ...state,
            isStatementLoader: action.payload,
        };
    }

    @Action(UPDATE_CONTRIBUTION_STATUS())
    updateContributionStatus(state: IReducerSalaryState, action: any) {
        return {
            ...state,
            isContributionUpdated: action.payload,
        };
    }
    

    @Action(UPDATE_EMPLOYEE_ELIGIBILITY_STATUS())
    updateEmployeeElegibilityStatus(state: IReducerSalaryState, action: any) {
         return {
             ...state,
             employeeStatus: action.payload
         }
     }

     @Action(UPDATE_EMPLOYEE_INFO())
     updateEmployeeInfo(state: IReducerSalaryState, action: any) {
         return {
             ...state,
             employeeInfo: action.payload
         }
     }

     @Action(UPDATE_WITHDRAW_SAVINGS())
     updateWithdrawSavings(state: IReducerSalaryState, action: any) {
         return {
             ...state,
             withdrawSavings: action.payload
         }
     }

     @Action(UPDATE_EMPLOYEE_DATA_SUCCESS())
     updateEmployeeDataSuccess(state: IReducerSalaryState, action: any) {
         return {
             ...state,
             employeeSignedUp: action.payload,
         }
     }

     @Action(UPDATE_WITHDRAW_SUCCESS())
     updateWithdrawSuccess(state: IReducerSalaryState, action: any) {
         return {
             ...state,
             withdrawSucess: action.payload,
         }
     }

     @Action(UPDATE_CONTRIBUTION_STATEMENT())
     updateContributionStatement(state: IReducerSalaryState, action: any) {
         return {
             ...state,
             statementData: action.payload,
         };
     }

     @Action(UPDATE_STATEMENT_DURATION())
     updateStatementDuration(state: IReducerSalaryState, action: any) {
         return {
             ...state,
             statementDuration: action.payload,
         };
     }
   
}

export default Salary.create();

export const SalaryMdl = [Salary.getEmployeeEligibilityStatus, Salary.getEmployeeInfo, Salary.getWithDrawSavings, Salary.getStatement, Salary.editMonthlyContribution, Salary.handlepostEmployeeData, Salary.handlepostWithdrawData, Salary.processEmployeeData, Salary.processWithdrawData, Salary.processSaveFromSalary, Salary.processEmployeeEligibilityStatus, Salary.processEmployeeInfoUpdate, Salary.processMonthlyContribution, Salary.processWithdrawSavingsUpdate];
